import axios from "axios";

export const getCountries = () => {
  return axios.get("roombuilderconfig/country/index").then(
    (response) =>
      new Promise((resolve, reject) => {
        if (Array.isArray(response.data)) {
          resolve(
            response.data.map((item) => ({
              ...item,
              key: `country-code-${item.code}`,
              value: item.code,
            }))
          );
        }
        reject(response);
      })
  );
};

export const WARNINGS_COUNTRY = {
  NON_US_COUNTRY: {
    key: "NON_US_COUNTRY",
    content: {
      title: <>INTERNATIONAL GROWERS</>,
      text: (
        <>
          <p>
            FloraFlex<sup>®</sup> is working everyday to expand our shipping and
            logistic partnerships to ship all our products worldwide. You have
            selected a country where we do not ship a selection of our products.
          </p>
          <p>
            We still welcome you to use our Room Builder to build your ideal
            grow. If a product you intend for your grow does not ship
            internationally we invite you to reach out to Customer Service, at
            (855) 855-1053, for a referral of where to source those parts in
            your country.
          </p>
        </>
      ),
    },
  },
  OTHER_COUNTRY: {
    key: "OTHER_COUNTRY",
    content: {
      title: <>INTERNATIONAL GROWERS</>,
      text: (
        <>
          <p>
            FloraFlex<sup>®</sup> is working everyday to expand our shipping and
            logistic partnerships to ship all our products worldwide. You have
            selected a country where we do not ship at present.
          </p>
          <p>
            We still welcome you to use our Room Builder to build your ideal
            grow. After you complete your build we invite you to reach out to
            Customer Service, at (855) 855-1053, for a referral of where to find
            our products in your country.
          </p>
        </>
      ),
    },
  },
  ALASKA_AND_HAWAII: {
    key: "ALASKA_AND_HAWAII",
    content: {
      title: <>ALASKA AND HAWAII</>,
      text: (
        <>
          <p>
            FloraFlex<sup>®</sup> is working everyday to expand our shipping and
            logistic partnerships to ship all our products worldwide. You have
            selected a location where we do not ship products without a custom
            shipping quote.
          </p>
          <p>
            We still welcome you to use our Room Builder to build your ideal
            grow. After you complete your build we invite you to reach out to
            Customer Service, at (855) 855-1053, for a quote for shipping.
          </p>
        </>
      ),
    },
  },
  PUERTO_RICO: {
    key: "PUERTO_RICO",
    content: {
      title: <>PUERTO RICO CUSTOMERS</>,
      text: (
        <>
          <p>
            FloraFlex<sup>®</sup> is working everyday to expand our shipping and
            logistic partnerships to ship all our products worldwide. You have
            selected a location where we do not ship a selection of our
            products.
          </p>
          <p>
            We still welcome you to use our Room Builder to build your ideal
            grow. If a product you intend for your grow does not ship
            internationally we invite you to reach out to Customer Service, at
            (855) 855-1053, for a referral of where to source those parts in
            your area.
          </p>
        </>
      ),
    },
  },
};

export const COUNTRY_OR_STATE_CODES = {
  UNITED_STATES: "US",
  PUERTO_RICO: "PR",
  HAWAII: "HI",
  ALASKA: "AK"
};