import React, { Component, useState } from "react";
import { Stage, Layer, Rect, Group, Line } from "react-konva";

import * as roomFunctions from "../KitRoom/KitRoomFunctions";

import { withRouter } from "react-router-dom";

import {
  CELL_WIDTH,
  STROKE_WIDTH,
  howManyCellFitW,
  howManyCellFitH,
  gridMiddleX,
  gridMiddleY,
  scaleBy,
} from "./Constants";

import RoomModel from "./Models/Room";
import Room from "./Components/Room";
import RoomReadOnly from "./Components/RoomReadOnly";
import { generateGridCoord } from "./Helpers";

import PropTypes from "prop-types";

export class CanvasForExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      layerKey: Date.now() + "layer",
    };
    this.canvasRef = React.createRef();
    this.layerRef = React.createRef();
    this.roomProps = this.props.roomProps;
  }

  componentDidMount = () => {
    this.timeout = setTimeout(() => {
      let stage = this.canvasRef.current;
      if (stage) {
        const imgCanvas = stage.toDataURL();
        this.props.captureCanvas(imgCanvas);
      }
      this.timeout = setTimeout(() => {
        this.props.close();
      }, 1000);
    }, 1000);
  };
  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  render() {
    const roomProps = this.roomProps;
    return (
      <div
        style={{
          width: "0",
          height: "0",
          overflow: "hidden",
        }}
        id={"canvas-for-screenshot"}
      >
        <div className="absolute-top left">
          <Stage
            ref={this.canvasRef}
            width={this.props.canvasSize.width}
            height={this.props.canvasSize.height}
          >
            <Layer
              ref={this.layerRef}
              width={this.props.canvasSize.width}
              height={this.props.canvasSize.height}
              key={this.state.layerKey}
            >
              <Rect
                width={this.state.gridBg.width}
                height={this.state.gridBg.width}
                x={this.state.gridBg.x}
                y={this.state.gridBg.y}
                fill={this.state.gridBg.fill}
                strokeEnabled={false}
                strokeScaleEnabled={false}
              />
              {this.state.gridState.map((item, index) => (
                <Line
                  key={`-${item.start.x}-${item.start.y}-${item.end.x}-${item.end.y}${index}`}
                  points={[item.start.x, item.start.y, item.end.x, item.end.y]}
                  stroke="#E5E5E5"
                  strokeWidth={0.1}
                  tension={0.5}
                  lineCap="round"
                  globalCompositeOperation={"source-over"}
                />
              ))}
              <RoomReadOnly {...roomProps} forScreenShot={true} />
              {/* <URLImage
                width={this.props.canvasSize.width}
                height={this.props.canvasSize.width/8.4}
                src={watermarkTop}
                x={0}
                y={0}
              /> */}
            </Layer>
          </Stage>
        </div>
      </div>
    );
  }
}

export class Room2dCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseEvent: {},
      ...generateGridCoord(this.props.readOnly),
      currentOptimalScale: 1,
      middleCoord: {
        x: gridMiddleX,
        y: gridMiddleY,
      },
      layerKey: Date.now() + "layer",
    };
    this.canvasRef = React.createRef();
    this.layerRef = React.createRef();
  }

  zoom = 1;
  newScale = undefined;

  componentDidMount = () => {
    let stage = this.canvasRef.current;
    if (stage) {
      stage.on("wheel", (e) => {
        let stage = this.canvasRef.current;
        if (!this.canvasRef.current) {
          return;
        }
        e.evt.preventDefault();
        let oldScale = stage.scaleX();
        let pointer = stage.getPointerPosition();
        if (!pointer) {
          return;
        }

        let deltaY;
        let mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };
        if (e.evt?.detail?.manual) {
          deltaY = e.evt.detail.deltaY;
        } else {
          deltaY = e.evt.deltaY;
        }

        let newScale = deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

        if (this.scaleNotOk(oldScale, newScale)) {
          return;
        }
        stage.scale({ x: newScale, y: newScale });

        let newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };

        this.newScale = newScale;

        stage.position(newPos);
        stage.batchDraw();
      });
    }
    window.scaleToFit = this.scaleToFit;

    window._info = () => {
      console.log(this.layerRef.current, {
        newScale: this.newScale,
      });
    };
    this.scaleToFit(this.state.layerKey);
    this.scaleLimits = {
      up: 4.5, //1,
      down: 0.04,
    };
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.zoomKey !== this.props.zoomKey) {
      switch (this.props.zoomType) {
        case "IN":
          this.zoomIn();
          break;
        case "OUT":
          this.zoomOut();
          break;
        case "CENTER":
          this.zoomCenter();
          break;
        case "EXPORT":
          this.exportCanvas();
          break;
      }
    }
    if (prevProps.roomSizeKey !== this.props.roomSizeKey) {
      this.roomSizeHasChanged();
    }
  };
  exportCanvas = () => {
    const roomModel = new RoomModel({ forScreenShot: true });
    const {
      roomWidthForExport,
      roomHeightForExport,
    } = roomModel.generateRoomStart({
      ...this.generateRoomProps(),
    });

    this.setState({
      useExport: true,
      roomWidthForExport,
      roomHeightForExport,
      exportRoomKey: Date.now() + "-Export-Room",
    });
  };

  scaleNotOk = (oldScale, newScale) => {
    return newScale > this.scaleLimits.up || newScale < this.scaleLimits.down; // 0.7415786499999997
  };
  triggerDetlaWheel = (detail) => {
    let stage = this.canvasRef.current;
    const event = new CustomEvent("wheel", {
      detail: {
        manual: true,
        ...detail,
      },
    });
    stage.dispatchEvent(event);
  };
  zoom = (deltaY) => {
    let stage = this.canvasRef.current;
    if (!this.canvasRef.current) {
      return;
    }
    let oldScale = stage.scaleX();
    let pointer = stage.getPointerPosition();
    if (!pointer) {
      return;
    }
    let mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    let newScale = deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (this.scaleNotOk(oldScale, newScale)) {
      return;
    }
    stage.scale({ x: newScale, y: newScale });

    let newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    this.newScale = newScale;

    stage.position(newPos);
    stage.batchDraw();
  };
  zoomIn = () => {
    this.zoom(-100);
  };

  zoomOut = () => {
    this.zoom(100);
  };

  zoomCenter = () => {
    this.scaleToFit();
    // let stage = this.canvasRef.current;
    // if (!this.canvasRef.current) {
    //   return;
    // }
    // if (this.layerRef.current) {
    //   let newScale = 1;
    //   stage.scale({ x: newScale, y: newScale });
    //   stage.batchDraw();
    //   stage.position({ x: 0, y: 0 });

    //   this.newScale = newScale;

    //   let layer = this.layerRef.current;
    //   layer.position({ x: 0, y: 0 });
    //   layer.draw();
    //   stage.batchDraw();
    // }
  };

  setTrayItem = (itemUpdated, index) => {
    const newTrays = [];
    for (let i = 0; i < this.props.trays.length; i++) {
      const _index = i;
      const _item = this.props.trays[i];
      if (index === _index) {
        newTrays.push({
          ...itemUpdated,
          key: `${Date.now()}-item-key-${_index}`,
        });
      } else {
        newTrays.push({
          ..._item,
        });
      }
    }
    this.props.setTrays(newTrays);
  };

  updateZoneItem = (itemUpdated, index) => {
    const newZones = [];
    for (let i = 0; i < this.props.zones.length; i++) {
      const _index = i;
      const _item = this.props.zones[i];
      if (index === _index) {
        newZones.push({
          ...itemUpdated,
          key: `${Date.now()}-item-key-${_index}`,
        });
      } else {
        newZones.push({
          ..._item,
        });
      }
    }
    this.props.updateZones(newZones);
  };

  roomSizeHasChanged = () => {
    /* this is not used */
    const roomWidth = this.props.canvasRoom.roomWidth;
    const roomLength = this.props.canvasRoom.roomLength;
    let currentOptimalScale = 1;
    if (roomWidth > howManyCellFitW || roomLength > howManyCellFitH) {
      currentOptimalScale = 0.3;
    }
    if (currentOptimalScale >= 0.3) {
      this.setState({ currentOptimalScale });
    }
  };

  generateRoomProps = () => {
    const trayItemProps = {};
    let otherProps = {};
    const conversionFunction =
    this.props.measureSystem === "M"
      ? (value) => {
          return roomFunctions.ftToM(value).toPrecision(2);
        }
      : (value) => value;
    if (this.props.readOnly) {
      trayItemProps.src = this.props.traySrc;
      otherProps = {
        roomWidth: 50,
        roomLength: 50,
      }
    } else {
      otherProps = {
        growAreaId: this.props.growAreaId,
        /**
         * room platform - start
         */
        updateZoneItem: this.updateZoneItem,
        zones: Array.isArray(this.props.zones)
          ? this.props.zones.map((item) => ({
              ...item,
              ...trayItemProps,
              bodyWidth: item.isRotated
                ? Number(item.lengthVal * CELL_WIDTH) || CELL_WIDTH
                : Number(item.widthVal * CELL_WIDTH) || CELL_WIDTH,
              bodyHeight: item.isRotated
                ? Number(item.widthVal * CELL_WIDTH) || CELL_WIDTH
                : Number(item.lengthVal * CELL_WIDTH) || CELL_WIDTH,
              subLabel: `${conversionFunction(
                item.widthVal
              )} x ${conversionFunction(item.lengthVal)} ${
                this.props.measureSystem
              }`,
              widthVal_RBased: item.isRotated ? item.lengthVal : item.widthVal,
              lengthVal_RBased: item.isRotated ? item.widthVal : item.lengthVal,
            }))
          : [],
        platformDrainageDirection: this.props.platformData
          .platformDrainageDirection,
        platformXcoord: this.props.platformData.platformXcoord,
        platformYcoord: this.props.platformData.platformYcoord,
        platformNumRows: this.props.platformData.platformNumRows,
        platformPlatformsPerRow: this.props.platformData
          .platformPlatformsPerRow,
        platformLinkLength: this.props.platformData.platformLinkLength,
        platformWalkwayWidth: this.props.platformData.platformWalkwayWidth,
        platformSubzoneWidth: this.props.platformData.platformSubzoneWidth,
        updateZones: this.props.updateZones,
        /**
         * room platform - end
         */
      };
    }



    const roomProps = {
      pathName: this.props.pathName,
      roomWidth: this.props.canvasRoom.roomWidth,
      roomLength: this.props.canvasRoom.roomLength,
      roomWidthLabel: `${(
        Number(this.props.canvasRoom.roomWidthVal) || 0
      ).toLocaleString("en", { maximumFractionDigits: 2 })} ${
        this.props.measureSystem
      }`,
      roomLengthLabel: `${(
        Number(this.props.canvasRoom.roomLengthVal) || 0
      ).toLocaleString("en", { maximumFractionDigits: 2 })} ${
        this.props.measureSystem
      }`,
      trays: this.props.trays.map((item) => ({
        ...item,
        ...trayItemProps,
        bodyWidth: item.isRotated
          ? Number(item.lengthVal * CELL_WIDTH) || CELL_WIDTH
          : Number(item.widthVal * CELL_WIDTH) || CELL_WIDTH,
        bodyHeight: item.isRotated
          ? Number(item.widthVal * CELL_WIDTH) || CELL_WIDTH
          : Number(item.lengthVal * CELL_WIDTH) || CELL_WIDTH,
        subLabel: `${conversionFunction(item.widthVal)} x ${conversionFunction(
          item.lengthVal
        )} ${this.props.measureSystem}`,
        widthVal_RBased: item.isRotated ? item.lengthVal : item.widthVal,
        lengthVal_RBased: item.isRotated ? item.widthVal : item.lengthVal,
      })),
      setTrayItem: this.setTrayItem,

      openPopup: this.props.openPopup,
      ...otherProps,
    };

    return roomProps;
  };

  newScalePointer_x = 0;
  newScalePointer_y = 0;
  scaleToFit = (key) => {
    this.setState(
      {
        layerKey: key || Date.now() + "layer",
      },
      () => {
        let roomWidth = this.props.canvasRoom.roomWidth;
        let roomLength = this.props.canvasRoom.roomLength;
        if (!Number(roomWidth) && !Number(roomLength)) {
          roomLength = 20;
          roomWidth = 20;
        }
        /**
         * canvasHeight === cellWidth * roomLength * scale;
         scale = canvasHeigh/cellWidtht * roomLength;
         */

        const scaleForHeight =
          this.props.canvasSize.height / (CELL_WIDTH * (roomLength + 10));
        const scaleForWidth =
          this.props.canvasSize.width / (CELL_WIDTH * (roomWidth + 10));
        let scale =
          scaleForHeight < scaleForWidth ? scaleForHeight : scaleForWidth;

        /**
         *
         */
        let stage = this.canvasRef.current;
        if (!this.canvasRef.current) {
          return;
        }
        let oldScale = stage.scaleX();
        let pointer = stage.getPointerPosition();
        // if (!pointer) {

        //   return;
        // }
        // let mousePointTo = {
        //   x: (pointer.x - stage.x()) / oldScale,
        //   y: (pointer.y - stage.y()) / oldScale,
        // };

        let newScale = scale;

        if (this.scaleNotOk(oldScale, newScale)) {
          if (newScale >= this.scaleLimits.up) {
            newScale = this.scaleLimits.up;
          } else {
            newScale = this.scaleLimits.down;
          }
          // return;
        }
        stage.scale({ x: newScale, y: newScale });

        // let newPos = {
        //   x: pointer.x - mousePointTo.x * newScale,
        //   y: pointer.y - mousePointTo.y * newScale,
        // };

        this.newScale = newScale;

        /**
         * GET ROOM START X AND Y CALC HOW FAR_X AND FAR_Y (makes sure room is at margin 0, 0 before padding apply)
         */
        const roomProps = this.generateRoomProps();
        const roomModel = new RoomModel();
        const roomInfo = roomModel.generateRoomStart(roomProps);
        const roomStartX = roomInfo.startX;
        const roomStartY = roomInfo.startY;

        let countX = 0;
        let countY = 0;
        if (roomStartX < 0) {
          countX = Math.abs(roomStartX / CELL_WIDTH);
        } else {
          countX = -Math.abs(roomStartX / CELL_WIDTH);
        }
        if (roomStartY < 0) {
          countY = Math.abs(roomStartY / CELL_WIDTH);
        } else {
          countY = -Math.abs(roomStartY / CELL_WIDTH);
        }
        /**
         * GET PADDING FOR CENTERING THE ROOM
         */
        const widthAtScale = CELL_WIDTH * newScale;
        const maxPerWidth = this.props.canvasSize.width / widthAtScale;
        const maxPerHeight = this.props.canvasSize.height / widthAtScale;
        const roomCountWidth = roomWidth;
        const roomCountHeight = roomLength;

        const paddingX = (maxPerWidth - roomCountWidth) / 2;
        const paddingY = (maxPerHeight - roomCountHeight) / 2;
        /**
         * APPLY PADDING
         */
        countX += paddingX;
        countY += paddingY;

        this.newScalePointer_x =
          (stage.offsetX() + CELL_WIDTH * countX) * newScale;
        this.newScalePointer_y =
          (stage.offsetY() + CELL_WIDTH * countY) * newScale;

        stage.position({
          x: this.newScalePointer_x,
          y: this.newScalePointer_y,
        });

        // stage.position({
        //   x: 0,
        //   y: 0,
        // });
        stage.batchDraw();
      }
    );
  };

  render() {
    const roomProps = this.generateRoomProps();
    return (
      <div
        style={{
          width: this.props.canvasSize.width + "px",
          height: this.props.canvasSize.height + "px",
        }}
        className={
          this.props.customClass != null
            ? "" + this.props.customClass
            : `position-relative`
        }
      >
        <div className="absolute-top left" id={this.props.parentId || ""}>
          <Stage
            ref={this.canvasRef}
            width={this.props.canvasSize.width}
            height={this.props.canvasSize.height}
          >
            <Layer
              ref={this.layerRef}
              draggable
              width={this.props.canvasSize.width}
              height={this.props.canvasSize.height}
              key={this.state.layerKey}
            >
              <Rect
                width={this.state.gridBg.width}
                height={this.state.gridBg.width}
                x={this.state.gridBg.x}
                y={this.state.gridBg.y}
                fill={this.state.gridBg.fill}
                strokeEnabled={false}
                strokeScaleEnabled={false}
              />
              {!this.props.readOnly &&
                this.state.gridState.map((item, index) => (
                  <Line
                    key={`-${item.start.x}-${item.start.y}-${item.end.x}-${item.end.y}${index}`}
                    points={[
                      item.start.x,
                      item.start.y,
                      item.end.x,
                      item.end.y,
                    ]}
                    stroke="#E5E5E5"
                    strokeWidth={STROKE_WIDTH}
                    tension={0.1}
                    lineCap="round"
                    globalCompositeOperation={"source-over"}
                  />
                ))}
              {!this.props.readOnly && <Room {...roomProps} />}
              {this.props.readOnly && (
                <RoomReadOnly
                  {...roomProps}
                  room2dImage={this.props.room2dImage}
                  /**
                   * makes a distiction between ReadOnly for screenshot and plain ReadOnly
                   */
                  forReadyOnly={true}
                />
              )}
              {/* <Rect
                width={CELL_WIDTH}
                height={CELL_WIDTH}
                x={this.newScalePointer_x}
                y={this.newScalePointer_y}
                fill="#fff"
                strokeEnabled={true}
                strokeScaleEnabled={true}
                strokeWidth={STROKE_WIDTH}
              />
              <Rect
                width={CELL_WIDTH}
                height={CELL_WIDTH}
                x={0}
                y={0}
                fill="rgba(252, 186, 3, 1.01)"
                strokeEnabled={true}
                strokeScaleEnabled={true}
                strokeWidth={STROKE_WIDTH}
              />
              <Rect
                width={CELL_WIDTH}
                height={CELL_WIDTH}
                x={gridMiddleX}
                y={gridMiddleY}
                fill="rgba(0, 0, 0, 1.01)"
                strokeEnabled={true}
                strokeScaleEnabled={true}
                strokeWidth={STROKE_WIDTH}
              /> */}
            </Layer>
          </Stage>
        </div>
        {this.state.useExport && (
          <CanvasForExport
            {...this.props}
            roomProps={roomProps}
            state={this.state}
            canvasSize={{
              ...this.props.canvasSize,
              width: this.state.roomWidthForExport,
              height: this.state.roomHeightForExport,
            }}
            close={() => {
              this.setState({ useExport: false });
            }}
            captureCanvas={this.props.captureCanvas}
            key={this.state.exportRoomKey}
          />
        )}
      </div>
    );
  }
}

export default Room2dCanvas;
