export const purchaseErrors = {
  NO_POT_SELECTED: {
    message: {
      title: "",
      text: "Please select a pot to continue.",
    },
    error_code: "NO_POT_SELECTED",
  },
  GENERIC_ERROR: {
    message: {
      title: "",
      text: "Ups! Something went wrong. Please try again!",
    },
    error_code: "GENERIC_ERROR",
  }
};
