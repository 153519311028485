// @flow
import * as React from "react";

import CartBuildLine from "./../../../components/CartBuildLine/CartBuildLine";

import { onChangeWithLimits } from "./../../../utils/utilFunctions";

/**
 * @param {object} props Component props
 * @param {object} props.cartBuildLines
 * @param {function} props.onChangeAsideItem
 */
export default function AsideComponents(props) {
  return (
    <div className="components-aside">
      <div className="aside-item-title">
        <span className="label-aside">
          Components listed below are predetermined based on previous selections
          you have made about your room size, zone count, pot/cube type, and
          number of plants per zone.
        </span>
      </div>
      <div className="build-lines-container">
        {Object.entries(props.cartBuildLines).map(([key, item]) => (
          <div key={key} className="build-line-wrapper">
            <CartBuildLine
              label={item.label}
              value={item.value}
              price={item.price}
              iconLeft={item.icon}
              onChange={(event) =>
                onChangeWithLimits(event, (value) =>
                  props.onChangeAsideItem(
                    `data.cartBuildLines.${key}.value`,
                    value
                  )
                )
              }
            />
          </div>
        ))}
        <div className="build-lines-borders">
          <div className="border"></div>
          <div className="border"></div>
        </div>
      </div>
    </div>
  );
}
