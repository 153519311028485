// @flow
import * as React from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { layoutActions } from "../../../redux/actionFunctions/layoutActions";
import "./SharePopup.scss";

import { ReactComponent as CloseIcon } from "./../../../../assets/svg/close-2.svg";
import { ReactComponent as CopyIcon } from "./../../../../assets/svg/copy.svg";
import { ReactComponent as SocialFbIcon } from "./../../../../assets/svg/social-fb.svg";
import { ReactComponent as SocialInstaIcon } from "./../../../../assets/svg/social-insta.svg";
import { ReactComponent as SocialTwIcon } from "./../../../../assets/svg/social-tw.svg";

import modalTopBg from "./../../../../assets/image/modalTopBg.png";
import instagramLogo from "./../../../../assets/svg/insta-icon.svg";
import { isEnter } from "./../../../utils/utilFunctions";
import Axios from "axios";

const STEPS = {
  SHARE_MENU: "SHARE_MENU",
  INSTAGRAM_MAILER: "INSTAGRAM_MAILER",
};

const defaultStateJson = JSON.stringify({
  step: STEPS.SHARE_MENU,
  value: "",
  promiseIndex: "",
  loader: {
    key: undefined,
    open: false,
  },
});

const generateDefaultState = () => {
  return {
    ...JSON.parse(defaultStateJson),
    promiseIndex: Date.now(),
  };
};

/**
 * @typedef {Object} IShareRoom
 * @property {String} 2D_Image
 * @property {String} created_at
 * @property {String} customer_id
 * @property {String} id
 * @property {Null} original_owner_firstname
 * @property {Null} original_owner_id
 * @property {Null} original_owner_lastname
 * @property {Object} room_data
 * @property {Object} room_data_ok
 * @property {String} room_description
 * @property {String} room_name
 * @property {String} share_code
 * @property {String} updated_at
 */
/**
 *
 * @param {Object} props
 * @param {IShareRoom} props.room
 */
export default function SharePopup(props) {
  const dispatch = useDispatch();
  const { popup } = useSelector(({ layout }) => ({
    popup: layout.sharePopup,
  }));

  const [state, setState] = React.useState(generateDefaultState);

  function sendMail() {
    if (state.loader.open) {
      const thisPromiseIndex = state.promiseIndex;
      const formData = new FormData();
      formData.append("room_id", props.room.id);
      formData.append("email", state.value);
      // Axios.get(`roombuilderconfig/share/mail?room_id=${props.room.id}`)
      Axios.post(`roombuilderconfig/share/mail`, formData)
        .catch(() => {})
        .finally(() => {
          console.log(thisPromiseIndex, state.promiseIndex);
          if (thisPromiseIndex === state.promiseIndex) {
            setState({
              ...state,
              step: STEPS.SHARE_MENU,
              loader: {},
            });
          }
        });
    } else {
      setState({
        ...state,
        loader: {
          key: Date.now(),
          open: true,
        },
      });
    }
  }

  React.useEffect(() => {
    setState(generateDefaultState);
  }, [popup?.key]);

  React.useEffect(() => {
    if (state.loader.key && state.loader.open) {
      sendMail();
    }
  }, [state.loader.key]);

  if (!popup) {
    return "";
  }

  const shareLink = `${window.BASE_URL}${'s/?s=' + props.room.share_code || ""}`;

  if (state.step === STEPS.INSTAGRAM_MAILER) {
    const emailDisabled = !new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(state.value);

    return (
      <div className={"modal-cstm-share"} step="INSTAGRAM_MAILER">
        <div className="modal-cstm-container">
          <div className="modal-cstm-content">
            <div className="modal-cstm-top">
              <img src={modalTopBg} alt="FloraFlex" />
            </div>
            <div className="modal-cstm-logo">
              <img src={instagramLogo} alt="instagram-logo" />
            </div>
            {/* POPUP TITLE AND TEXT ....................................................*/}
            <div className="modal-cstm-data">
              <div className="modal-cstm-title">SHARE WITH INSTAGRAM</div>
              <div className="modal-cstm-text">
                Enter the email address* associated with your mobile device. You
                will receive an email with your build share link and an image
                for easy sharing with the Instagram app.
              </div>
              <div className="modal-cstm-input-wrapper">
                <input
                  type="text"
                  value={state.value}
                  onKeyUp={(event) => {
                    if (emailDisabled || state.loader.open) {
                      return;
                    }
                    if (isEnter(event)) {
                      sendMail();
                    }
                  }}
                  placeholder="Email"
                  className="generic-box-shadow latto"
                  onChange={(event) => {
                    setState({
                      ...state,
                      value: event.target.value,
                    });
                  }}
                />
              </div>
              <div className="latto modal-cstm-description">
                *FloraFlex® will not share, misuse, or sell this information for
                any purpose.
              </div>
            </div>

            {/* POPUP BUTTON CONTROLS ....................................................*/}
            <div className="modal-buttons">
              <Button
                variant="secondary"
                onClick={() => {
                  setState({
                    ...state,
                    step: STEPS.SHARE_MENU,
                  });
                }}
              >
                Back
              </Button>
              <Button
                disabled={emailDisabled || state.loader.open}
                variant="primary"
                onClick={sendMail}
              >
                {!state.loader.open && <>{"SEND"}</>}
                {state.loader.open && (
                  <div className="flex-all width-100">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={"modal-cstm-share"}>
      <div className="modal-cstm-container-share">
        <div className="modal-cstm-content-share">
          <div className="modal-cstm-top-share">
            <div className="generic-text-shadow latto flex-1 modal-share-title">
              SHARE YOUR BUILD
            </div>
            <div
              className="flex-all cursor-pointer modal-close"
              onClick={() => {
                layoutActions(dispatch).closeShareRoomPopup();
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="modal-cstm-top-bottom">
            <div className="flex-all modal-content-title latto">
              <div>#MYFLORAFLEXBUILD</div>
            </div>
            <div className="modal-content-paragraph">
              Share this FloraFlex<sup>®</sup> Room Builder link with your
              friends and followers on social media so they can see your
              masterpiece and even buy it for themselves.
            </div>
            <div className="modal-share-link-bar cursor-pointer">
              <div
                className="flex-between modal-share-link-body"
                onClick={() => {
                  /* Get the text field */
                  const inputClipboard = document.getElementById(
                    "clipboard-input-node-share"
                  );

                  /* Select the text field */
                  inputClipboard.select();
                  inputClipboard.setSelectionRange(
                    0,
                    99999
                  ); /* For mobile devices */

                  /* Copy the text inside the text field */
                  document.execCommand("copy");
                }}
              >
                <input
                  value={shareLink}
                  id="clipboard-input-node-share"
                  style={{
                    position: "absolute",
                    left: "0",
                    top: 0,
                    opacity: 0,
                  }}
                  readOnly={true}
                />
                <div className="flex-1 latto -line-overflow-elipsis share-link">
                  {shareLink}
                </div>
                <div className="flex-all share-link-copy-icon">
                  <CopyIcon />
                </div>
              </div>
            </div>
            <div className="flex-all">
              <div className="flex-between share-icon-list">

                <div className="flex-all share-icon-item">
                  <a
                    href={`http://www.facebook.com/sharer/sharer.php?u=${shareLink}&hashtag=%23MyFloraFlexBuild`}
                    target="_blank"
                  >
                    <SocialFbIcon />
                  </a>
                </div>
                <div
                  className="flex-all share-icon-item cursor-pointer"
                  onClick={() => {
                    setState({
                      ...state,
                      step: STEPS.INSTAGRAM_MAILER,
                    });
                  }}
                >
                  <SocialInstaIcon />
                </div>
                <div className="flex-all share-icon-item">
                  {/*<a
                    href={`https://twitter.com/intent/tweet?url=${shareLink}&text=My%20FloraFlex%20build&hashtags=FloraFlex,ffRoomBuilder,Growers,MyFloraFlexBuild,FloraFlexer,FloraFlexing,FloraFlexNutrients,HowDoYouFlex`}
                    target="_blank"
                  >
                    <SocialTwIcon />
                  </a>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
