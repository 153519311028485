import React, { useEffect } from "react";
import Button from "../../../components/Button/Button";
import axios from "axios";

import * as roomFunctions from "./KitRoomFunctions";
import Switch from "../../../components/Switch/Switch";

import { parseHtmlAndOther } from "../../../utils/utilFunctions";
import objectPath from "object-path";

export default function KitRecommended(props) {
  const [state, setState] = React.useState({
    listPlantsPerArea: [],
    listPlumbingTrays: [],
    listMainlinePipe: [],
    listDripperStyle: [],
    listDripperPerPlant: [],
  });

  useEffect(() => {
    let list_plants_per_area = [];
    let list_plumbing_trays = [];
    let list_mainline_pipe = [];
    let list_dripper_style = [];
    let list_dripper_per_plant = [];

    let promise;
    if (
      window.kitBuilderMatrix &&
      Array.isArray(window.kitBuilderMatrix.grow_areas)
    ) {
      promise = new Promise((resolve) => {
        resolve(
          JSON.parse(
            JSON.stringify({ data: window.kitBuilderMatrix, status: 200 })
          )
        );
      });
    } else {
      promise = axios(`${window.BASE_URL}rbapi/getMatrix.php`);
    }

    promise
      .then((response) => {
        let response_density = [];
        let response_plumbing = [];
        let response_pipe_diameter = [];
        let response_dripper_style = [];
        let response_dripper_per_plant = [];

        /**
         * safely get the array of object
         */

        let pathToIrrigation = "";
        if (props.irrigationStyle.type === "generic-containers") {
          const indexGenContainer = roomFunctions.getGenContainerIndex(
            props,
            response
          );

          /** get selected irrigation */
          let irrigationIndex =
            Number(props.growArea.id) === 1 ? 0 : props.irrigationStyle.index;
          if (Number(props.growArea.id) !== 1) {
            /** get selected irrigation using the label... */
            const irrigationArray = objectPath.get(
              response,
              `data.grow_areas.${props.growArea.id}.generic_containers.${indexGenContainer}.irrigation`
            );
            if (Array.isArray(irrigationArray)) {
              let indexAux = irrigationArray.findIndex(
                (item) =>
                  roomFunctions.safeToUpperCase(props.irrigationStyle.label) ===
                  roomFunctions.safeToUpperCase(item.value)
              );
              if (indexAux > -1) {
                irrigationIndex = indexAux;
              }
            }
          }

          pathToIrrigation = `data.grow_areas.${props.growArea.id}.generic_containers.${indexGenContainer}.irrigation.${irrigationIndex}`;
        } else {
          const irrigationIndex =
            Number(props.growArea.id) === 1 ? 0 : props.irrigationStyle.id;
          pathToIrrigation = `data.grow_areas.${props.growArea.id}.containers.${props.chosenPot.id}.irrigation.${irrigationIndex}`;
        }
        const responseIrrigation = objectPath.get(response, pathToIrrigation);

        //test
        window.devRespIrrigation = {
          pathToIrrigation,
          responseIrrigation,
        };

        if (responseIrrigation && typeof responseIrrigation === "object") {
          response_density = Array.isArray(responseIrrigation.density)
            ? responseIrrigation.density
            : [];
          response_plumbing = Array.isArray(responseIrrigation.plumbing)
            ? responseIrrigation.plumbing
            : [];
          response_pipe_diameter = Array.isArray(
            responseIrrigation.pipe_diameter
          )
            ? responseIrrigation.pipe_diameter
            : [];
          response_dripper_style = Array.isArray(
            responseIrrigation.dripper_style
          )
            ? responseIrrigation.dripper_style
            : [];
          response_dripper_per_plant = Array.isArray(
            responseIrrigation.dripper_per_plant
          )
            ? responseIrrigation.dripper_per_plant
            : [];
        }

        /**
         * TODO: just go rid of this with the api
         * don't use this for PLATO PLATFROM
         */
        Number(props.growAreaId) !== 1 &&
          response_density.forEach(function (data, index) {
            list_plants_per_area.push({
              id: index,
              label: data.name,
              labelVisible: parseHtmlAndOther(data.label || data.name), //labelVisible - is the one we see in this page, but label is the one passed to other pages along the value
              image: data.image,
              value: Number(data.value) || 0,
            });
          });

        response_plumbing.forEach(function (data, index) {
          list_plumbing_trays.push({
            id: index,
            label: data.name,
            labelVisible: parseHtmlAndOther(data.label || data.name), //labelVisible - is the one we see in this page, but label is the one passed to other pages along the value
            image: data.image,
            value: data.value,
          });
        });

        /**
         * TODO: just go rid of this with the api
         * don't use this for PLATO PLATFROM
         */
        Number(props.growAreaId) !== 1 &&
          response_pipe_diameter.forEach(function (data, index) {
            list_mainline_pipe.push({
              id: index,
              label: data.name,
              labelVisible: parseHtmlAndOther(data.label || data.name), //labelVisible - is the one we see in this page, but label is the one passed to other pages along the value
              image: data.image,
            });
          });

        response_dripper_style.forEach(function (data, index) {
          list_dripper_style.push({
            id: index,
            label: data.name,
            labelVisible: parseHtmlAndOther(data.label || data.name), //labelVisible - is the one we see in this page, but label is the one passed to other pages along the value
            image: data.image,
          });
        });

        response_dripper_per_plant.forEach((data, index) => {
          list_dripper_per_plant.push({
            id: index,
            label: data.name,
            name: data.name,
            labelVisible: parseHtmlAndOther(data.label || data.name), //labelVisible - is the one we see in this page, but label is the one passed to other pages along the value
            image: data.image,
          });
        });

        let newDripperPerPlant = props.dripperPerPlant;
        let newMainLinePipe = props.mainLinePipe;

        if (
          !props.mainLinePipe.label ||
          !list_mainline_pipe.some(
            (item) => item.label === props.mainLinePipe.label
          )
        ) {
          if (list_mainline_pipe?.[0]) {
            newMainLinePipe = {
              newMainLinePipe,
              ...{
                id: list_mainline_pipe[0].id,
                label: list_mainline_pipe[0].label,
                labelVisible: list_mainline_pipe[0].labelVisible,
              },
            };
          }
        }
        /**
         * here we used name to match it with label
         */
        if (
          !props.dripperPerPlant.label ||
          !list_dripper_per_plant.some(
            (item) => item.name === props.dripperPerPlant.label
          )
        ) {
          if (list_dripper_per_plant?.[0]) {
            newDripperPerPlant = {
              newDripperPerPlant,
              ...{
                id: list_dripper_per_plant[0].id,
                label: list_dripper_per_plant[0].label,
                labelVisible: list_dripper_per_plant[0].labelVisible,
              },
            };
          }
        }

        const kitBuilderStateLoad = {};

        /** if the number of 'platform per rows' has changed (after going back), re-evaluate dripper style */
        const newDripperStyle = roomFunctions.getResetDripperStyle(props)(
          props.dripperStyle,
          props.dripperPerPlant.label
        );
        if(newDripperStyle) {
          kitBuilderStateLoad.dripperStyle = newDripperStyle
        }

        /**
         * this should be updated at once (we can do the setState than another setState right away - in KitBuilder - which setElement would do)
         */
        props.setState({
          mainLinePipe: newMainLinePipe,
          dripperPerPlant: newDripperPerPlant,
          ...kitBuilderStateLoad
        });

        setState({
          ...state,
          listPlantsPerArea: list_plants_per_area,
          listPlumbingTrays: list_plumbing_trays,
          listMainlinePipe: list_mainline_pipe,
          listDripperStyle: list_dripper_style,
          listDripperPerPlant: list_dripper_per_plant,
        });
      })
      .catch(() => {});
  }, []);

  function saveRoom() {
    props.saveRoom();
  }

  function selectElement(element, id, label = "", item) {
    if (element == "plants") {
      props.setCalculatorState({
        ...props.calculatorState,
        plant: item.value,
        plantLabel: label,
        plantItemId: id,
        plantItemImage: item.image,
      });
    } else if (element == "plumbing") {
      props.setPlumbing({
        ...props.plumbing,
        ...{ id: id, label: label, value: item.value },
      });
    } else if (element === "pipe") {
      props.setMainLinePipe({
        ...props.mainLinePipe,
        ...{ id: id, label: label, labelVisible: item.labelVisible },
      });
    } else if (element === "dripperStyle") {
      props.setDripperStyle({
        ...props.dripperStyle,
        ...{ id: id, label: label, labelVisible: item.labelVisible },
      });
    } else if (element === "dripperPerPlant") {
      props.setDripperPerPlant(
        {
          ...props.dripperPerPlant,
          ...{ id: id, label: label, labelVisible: item.labelVisible },
        },
        roomFunctions.getResetDripperStyle(props)(props.dripperStyle, label)
      );
    }
  }

  const errorToShow = props.getStepError(
    state.listPlantsPerArea,
    state.listPlumbingTrays,
    state.listMainlinePipe,
    state.listDripperStyle
  );
  const continueDisabled = errorToShow != null;

  return (
    <div className="step-content kit-room-content kit-recommended-builds">
      {/* <h3 className="step-subtitle">
                You selected the {props.plantKit} plant kit.
            </h3> */}
      <h1 className="step-title">
        WHAT BUILD WOULD
        <br />
        YOU LIKE TO USE?
      </h1>
      <div className="kit-recommended-builds-wrapper">
        {/* PLANTS PER 4X4 AREA */}
        {state.listPlantsPerArea.length > 0 && (
          <>
            <h2 className="recommended-builds-subtitle">PLANTS PER 4X4 AREA</h2>
            <div className="list-column plants-area">
              {state.listPlantsPerArea.map((item, index) => {
                return (
                  <div
                    key={`${index}--pallet-area-item-plants-per-item`}
                    className={`list-element${
                      item.id === props.calculatorState.plantItemId
                        ? ` selected`
                        : ``
                    }`}
                    onClick={() =>
                      selectElement("plants", item.id, item.label, item)
                    }
                  >
                    <img src={item.image} alt={item.image} />
                    <div className="list-element-label">
                      {item.labelVisible}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {/* PLUMBING FOR LARGER TRAYS */}
        {state.listPlumbingTrays.length > 0 && (
          <>
            <h2 className="recommended-builds-subtitle">
              PLUMBING FOR LARGER TRAYS
            </h2>
            <div className="list-column plumbing-trays">
              {state.listPlumbingTrays.map((item, index) => {
                return (
                  <div
                    key={`${index}PLUMBING FOR 4X8 OR--pallet-area-item-LARGER TRAYSplants-per-item`}
                    className={`list-element${
                      item.id == props.plumbing.id ? ` selected` : ``
                    }`}
                    onClick={() =>
                      selectElement("plumbing", item.id, item.label, item)
                    }
                  >
                    <img src={item.image} alt={item.image} />
                    <div className="list-element-label">
                      {item.labelVisible}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {/* DRIPPER PER PLANT */}
        {state.listDripperPerPlant.length >= 2 && (
          <>
            <h2 className="recommended-builds-subtitle">DRIPPER PER PLANT</h2>
            <div className="list-column pipe-diameter flex-all">
              {state.listDripperPerPlant.length >= 2 && (
                <Switch
                  listValues={[
                    {
                      value: state.listDripperPerPlant[0].label,
                      label: state.listDripperPerPlant[0].labelVisible,
                    },
                    {
                      value: state.listDripperPerPlant[1].label,
                      label: state.listDripperPerPlant[1].labelVisible,
                    },
                  ]}
                  value={props.dripperPerPlant.label}
                  onSwitch={(selectedValue, index) => {
                    selectElement(
                      "dripperPerPlant",
                      state.listDripperPerPlant[index].id,
                      state.listDripperPerPlant[index].label,
                      state.listDripperPerPlant[index],
                      state.listDripperPerPlant[index]
                    );
                  }}
                />
              )}
            </div>
          </>
        )}

        {/* DRIPPER STYLE */}
        {state.listDripperStyle.length > 0 && (
          <>
            <h2 className="recommended-builds-subtitle">DRIPPER STYLE</h2>
            <div className="list-column dripper-style-trays">
              {state.listDripperStyle.map((item, index) => {
                if (
                 roomFunctions.restrictDripperStyleItem(props)(item, props.dripperPerPlant.label)
                ) {
                  return;
                }

                return (
                  <div
                    key={`${index}DRIPPER STYLE`}
                    className={`list-element${
                      item.id == props.dripperStyle.id ? ` selected` : ``
                    }`}
                    onClick={() =>
                      selectElement("dripperStyle", item.id, item.label, item)
                    }
                  >
                    <img src={item.image} alt={item.image} />
                    <div className="list-element-label">
                      {item.labelVisible}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {/* MAINLINE PIPE DIAMETER */}
        {state.listMainlinePipe.length > 0 && (
          <>
            <h2 className="recommended-builds-subtitle">
              MAINLINE PIPE DIAMETER
            </h2>
            <div className="list-column pipe-diameter flex-all">
              {state.listMainlinePipe.length >= 2 && (
                <Switch
                  listValues={[
                    {
                      value: state.listMainlinePipe[0].label,
                      label: "1IN",
                    },
                    {
                      value: state.listMainlinePipe[1].label,
                      label: state.listMainlinePipe[1].labelVisible,
                    },
                  ]}
                  value={props.mainLinePipe.label}
                  onSwitch={(selectedValue, index) => {
                    selectElement(
                      "pipe",
                      state.listMainlinePipe[index].id,
                      state.listMainlinePipe[index].label,
                      state.listMainlinePipe[index],
                      state.listMainlinePipe[index]
                    );
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="kit-room-control">
        <Button
          text="Back"
          variant="secondary"
          onClick={() => {
            if (!props.growArea.id) {
              props.history.replace({
                pathname: "/roombuilder/irrigation-style",
                search: props.history.location.search,
              });
            } else {
              props.history.replace({
                pathname: "/roombuilder/zoning-style",
                search: props.history.location.search,
              });
            }
          }}
        />
        <Button
          text="Build SYSTEM"
          variant="primary-new"
          className={continueDisabled ? "disabled" : ""}
          onClick={() => {

            if (errorToShow) {
              props.openPopup({
                content: errorToShow.message,
              });
              return;
            }
            if (continueDisabled) {
              return;
            }

            const trayWarning = props.getOneOfTheTrayWarnings();
            if (trayWarning) {
              props.openSpacingWarning(() => {
                saveRoom();
              });
            } else {
              saveRoom();
            }
            window.screenShotAfter3DRender = true;
          }}
        />
      </div>
    </div>
  );
}
