import { gql } from "@apollo/client";
import Axios from "axios";
import { triggerCustomEvent } from "../../../utils/utilFunctions";

export default function cartFunctions(client) {
  const removePreviousRoomProducts = (client_cart_id) => {
    return new Promise((resolve) => {
      window.$.ajax({
        url:
          window.BASE_URL +
          `rbapi/removeRoomItems.php?cart_id=${client_cart_id}`,
        type: "GET",
        success: function (data) {
          resolve(data);
        },
        error: function (errData) {
          resolve(errData);
        },
      });
    });
  };

  const createCookieUrl = (selectedRoom, cartItemsClient, items) => {
    let dataLink = `${selectedRoom.id}____${selectedRoom.room_name}____`;
    cartItemsClient.forEach((productItem, _index) => {
      if (
        items.some(
          (roomBuilderProduct) =>
            productItem.product.sku === roomBuilderProduct.sku
        )
      ) {
        dataLink += `${productItem?.product?.sku}${
          _index < cartItemsClient.length - 1 ? "_" : ""
        }`;
      }
    });
    var newDataLink = dataLink.replace("#", "%23");
    newDataLink = newDataLink.replace(" ", "%20");

    window.savedCartCookie = newDataLink;

    return new Promise((resolve) => {
      resolve();
    });
  };

  const apiNutrientCalculatorUpdate = (selectedRoom, client_cart_id) => {
    return new Promise((resolve) => {
      window.$.ajax({
        url:
          window.BASE_URL +
          "nutrientcalculatorconfig/cart/update?room_id=" +
          selectedRoom.id +
          "&cart_id=" +
          client_cart_id,
        type: "GET",
        success: function (data) {
          resolve();
        },
        error: function (errData) {
          resolve();
        },
      });
    });
  };

  return {
    getClientCart: (token) => {
      return client
        .query({
          query: gql`
            {
              customerCart {
                id
                items {
                  id
                  product {
                    name
                    sku
                  }
                  quantity
                }
              }
            }
          `,
        })
        .catch((errorWhileGettingCart) => {
          console.log({ errorWhileGettingCart });
          return new Promise((resolve, reject) =>
            reject(errorWhileGettingCart)
          );
        });
    },

    addProductsToCart: (items, client_cart_id, selectedRoom) => {
      const roomId = selectedRoom.id ? selectedRoom.id : null;
      const roomName = selectedRoom.room_name
        ? `"${selectedRoom.room_name}"`
        : null;

      let cartItemsClient = [];

      return removePreviousRoomProducts(client_cart_id)
        .then(() => {
          return client.mutate({
            mutation: gql`
              mutation {
                addProductsToCart(
                  cartId: "${client_cart_id}"
                  cartItems: [
                    ${items.reduce(
                      (final, current) =>
                        final +
                        " " +
                        `
                            {
                              quantity: ${current.quantity}
                              sku: "${current.sku}"
                              room_id: ${roomId}
                              room_name: ${roomName}
                            }`,
                      ""
                    )}
                  ]
                ) {
                  cart {
                    items {
                      id
                      product {
                        name
                        sku
                      }
                      quantity
                    }
                  }
                }
              }
              `,
          });
        })
        .then((result) => {
          cartItemsClient = result?.data?.addProductsToCart?.cart?.items || [];
          return createCookieUrl(selectedRoom, cartItemsClient, items);
        })
        .then(() => {
          return apiNutrientCalculatorUpdate(selectedRoom, client_cart_id);
        })
        .then(() => {
          return new Promise((resolve) => {
            resolve(cartItemsClient);
          });
        })
        .catch((errorWhileAddingToCart) => {
          console.log({ errorWhileAddingToCart });
          return new Promise((resolve, reject) =>
            reject(errorWhileAddingToCart)
          );
        });
    },
    saveRoom: (
      {
        trays,
        chosenPot,
        irrigationStyle,
        density,
        plumbing,
        mainLinePipe,
        dripperStyle,
        roomSize,
        cart_id,
        room_description,
        room_name,
        nutrients,
        build_components,
        thirdPartyParts,
        room_id,
        _reactObjs,
        irrigationImage,
        densityImage,
        numOfPlants,
        platformDrainageDirection,
        platformXcoord,
        platformYcoord,
        platformNumRows,
        platformPlatformsPerRow,
        platformLinkLength,
        platformWalkwayWidth,
        platformSubzoneWidth,
        original_owner_id,
        clientId,
      },
      paramGET = ""
    ) => {
      //dev
      window.devResolveSaveRoom = null;

      let roomData = {};
      const onResolve = (result) => {
        //dev
        window.devResolveSaveRoom = result;

        if (result.data && result.data.customer && result.data.customer.id) {
          // Trays
          if (trays && trays.length > 0) {
            roomData.trays = trays.reduce((final, currentTray) => {
              final.push({
                tray_name: currentTray.label,
                tray_width: currentTray.widthVal,
                tray_length: currentTray.lengthVal,
                pos_x: currentTray.offsetX,
                pos_y: currentTray.offsetY,
                rotation: currentTray.isRotated ? 1 : 0,
              });
              return final;
            }, []);
          }

          // Room size & UOM
          if (roomSize && roomSize.roomLength && roomSize.roomWidth) {
            roomData.room_size = {
              width: roomSize.roomWidth,
              length: roomSize.roomLength,
            };

            if (roomSize.measureSystem) {
              roomData.uom = roomSize.measureSystem == "FT" ? "feet" : "meters";
            }
          }

          // Container
          if (chosenPot && chosenPot.label) {
            roomData.container = chosenPot.label;
          }
          roomData.chosenPot = chosenPot;

          // Irrigation
          if (irrigationStyle && irrigationStyle.label) {
            roomData.irrigation = irrigationStyle.label;
          }
          roomData.irrigationStyle = irrigationStyle;

          // Density
          if (density) {
            roomData.density = density;
          }

          // Plumbing
          if (plumbing && plumbing.label) {
            roomData.plumbing = plumbing.label;
          }

          // Pipe diameter
          if (mainLinePipe && mainLinePipe.labelVisible) {
            roomData.pipe_diameter = mainLinePipe.labelVisible;
          }

          // Dripper Style
          if (dripperStyle && dripperStyle.labelVisible) {
            roomData.dripperStyle = dripperStyle.labelVisible;
          }

          // Nutrients
          roomData.nutrients = nutrients;

          // Build components
          roomData.build_components = build_components;

          // Third Party Parts
          roomData.thirdPartyParts = thirdPartyParts;

          // Customer ID
          roomData.customer_id = result.data.customer.id;

          // Cart ID
          roomData.cart_id = cart_id;

          // Room name
          roomData.room_name = room_name;

          //Room description
          roomData.room_description = room_description;

          //Room description
          roomData.room_id = room_id;

          //For ROOM EDITS
          roomData._reactObjs = _reactObjs;

          roomData.irrigationImage = irrigationImage;
          roomData.densityImage = densityImage;
          roomData.numOfPlants = numOfPlants;

          // platform data
          roomData.platformDrainageDirection = platformDrainageDirection;
          roomData.platformXcoord = platformXcoord;
          roomData.platformYcoord = platformYcoord;
          roomData.platformNumRows = platformNumRows;
          roomData.platformPlatformsPerRow = platformPlatformsPerRow;
          roomData.platformLinkLength = platformLinkLength;
          roomData.platformWalkwayWidth = platformWalkwayWidth;
          roomData.platformSubzoneWidth = platformSubzoneWidth;

          //shared room
          if (original_owner_id) {
            roomData.original_owner_id = original_owner_id;
          }

          if (Object.keys(roomData).length > 0) {
            return Axios.post(
              `${window.BASE_URL}rbapi/saveRoom.php${paramGET}`,
              roomData
            )
              .then((roomDataResult) => {
                /**  */
                window.lastSavedRoom = roomDataResult.data?.[0];
                const room_data = window.lastSavedRoom?.room_data;
                if (typeof room_data === "string") {
                  try {
                    window.lastSavedRoom.room_data = JSON.parse(room_data);
                  } catch (err) {}
                }
                triggerCustomEvent(
                  "room-was-save-successfully",
                  window.lastSavedRoom
                );

                return new Promise((resolve, reject) =>
                  resolve(roomDataResult)
                );
              })
              .catch((error) => {
                return new Promise((resolve, reject) => reject(error));
              });
          }
        }
        return new Promise((resolve, reject) => reject({}));
      };
      // Customer ID
      return client
        .query({
          query: gql`
            {
              customer {
                id
                firstname
                middlename
                lastname
                email
              }
            }
          `,
        })
        .then(onResolve)
        .catch((error) => {
          if (/^(http:\/\/localhost)/.test(window.location.href)) {
            return onResolve({ data: { customer: { id: 4 } } });
          }
          /**
           * in case graphql fails use the client id from redux
           */
          if (clientId) {
            return onResolve({ data: { customer: { id: clientId } } });
          }
          return new Promise((resolve, reject) => reject(error));
        });
    },
    getClient: () => {
      return client
        .query({
          query: gql`
            {
              customer {
                id
                firstname
                middlename
                lastname
                email
              }
            }
          `,
        })
        .then((result) => {
          if (result.data && result.data.customer && result.data.customer.id) {
            return new Promise((resolve, reject) =>
              resolve(result.data.customer)
            );
          }
          return new Promise((resolve, reject) => reject({}));
        })
        .catch((error) => {
          console.log(error);
          return new Promise((resolve, reject) => reject(error));
        });
    },
    getConfig: () => {
      return fetch(`${window.BASE_URL}nutrientcalculatorconfig/index`)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          }
          return new Promise((resolve, reject) => reject(resp));
        })
        .then((json) => {
          const configData = JSON.parse(json);
          return new Promise((resolve, reject) => resolve(configData));
        })
        .catch((errorGet) => {
          return new Promise((resolve, reject) => reject(errorGet));
        });
    },
  };
}
