import React, { useEffect } from "react";
import Button from "../../../components/Button/Button";
import RoomLayout from "./KitRoomLayout";
import axios from "axios";

import * as rooomFunctions from "./KitRoomFunctions";

import parse from "html-react-parser";
import { parseHtmlAndOther } from "../../../utils/utilFunctions";
import { Spinner } from "react-bootstrap";
import customBuildImage from "../../../../assets/image/custom-build.png";
import { GROW_ARE_IDS } from "../../../utils/constants";

export default function KitGrowArea(props) {
  const [state, setState] = React.useState({
    measureSystem: "FT",
    selectedContainer: props.growArea.id,
    growAreaTypeList: [],
  });

  /**
   * used for triggering an action after loader was rendered (to avoid setLoader off render trigger before setLoader on in cas request is faster than render )
   */
  const [loaderState, setLoaderState] = React.useState({
    key: undefined,
    action: undefined,
    callback: undefined,
  });

  const [showControls, setShowControls] = React.useState();

  function getData() {
    if (loaderState.open && loaderState.action === "getData") {
      return;
    }
    setLoaderState({
      key: Date.now(),
      open: true,
      action: "getData",
      callback: () => {
        let grow_areas = [];

        let promise;
        if (
          window.kitBuilderMatrix &&
          Array.isArray(window.kitBuilderMatrix.grow_areas)
        ) {
          promise = new Promise((resolve) => {
            resolve(
              JSON.parse(
                JSON.stringify({ data: window.kitBuilderMatrix, status: 200 })
              )
            );
          });
        } else {
          promise = axios(`${window.BASE_URL}rbapi/getMatrix.php`);
        }

        promise
          .then((response) => {
            if (
              response.status == 200 &&
              response.data.grow_areas &&
              props.roomSize.roomLength &&
              props.roomSize.roomWidth
            ) {
              response.data.grow_areas.forEach(function (data, index) {
                grow_areas.push({
                  id: index,
                  label: data.name,
                  labelVisible: parseHtmlAndOther(data.name),
                  image: data.image,
                  description: "",
                });
              });
            }

            setState({
              ...state,
              growAreaTypeList: grow_areas,
            });
          })
          .finally(() => {
            setLoaderState({
              open: false,
            });
          });
      },
    });
  }

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (
      loaderState.action &&
      loaderState.key &&
      typeof loaderState.callback === "function"
    ) {
      loaderState.callback();
    }
  }, [loaderState.key]);

  function selectItem(value, label) {
    props.setGrowArea({
      ...props.growArea,
      id: value,
      label: label,
    });
    setState({ ...state, selectedContainer: value });
  }

  

  const errorToShow = props.getStepError();
  const continueDisabled = errorToShow != null;

  return (
    <div id="grow-area" className="step-content kit-room-content">
      <h1 className="step-title">
        WHAT TYPE OF <br />
        GROW AREA <br />
        ARE YOU BUILDING?
      </h1>
      <div className="inside-left-content">
        <div className="step-tray-type-container">
          {state.growAreaTypeList.map((item, index) => {
            return (
              <div
                key={`grow-area-listing-elements----kit-room---${index}`}
                className={`item-tray-type ${
                  item.id === props.growArea.id ? `selected` : ``
                }`}
                onClick={() => selectItem(item.id, item.label)}
              >
                <img src={item.image} alt="potpro" />
                <div className="title-tray-type">
                  {item.labelVisible}
                  {item.label === "POTPRO™ PLATFORM" && (
                    <div className={"grow-area-tooltip"}>
                      <div className="tooltip-control-question-button">
                        <Button
                          text="?"
                          variant="control question-button"
                          onMouseOver={() => setShowControls(true)}
                          onMouseOut={() => setShowControls(false)}
                        />
                      </div>
                      {showControls && (
                        <div className="abs-tooltip-text">
                          * The new PotPro&trade; Platforms allow for
                          <br />
                          placement of each plant on its own elevated
                          <br />
                          platform. All your Platforms will be
                          <br />
                          connected together for ideal drainage.
                        </div>
                      )}
                    </div>
                  )}
                  <span className="description-tray-type">
                    {item.description}
                  </span>
                </div>
              </div>
            );
          })}
          {loaderState.open && loaderState.action === "getData" && (
            <div
              className="flex-all width-100 page-spinner loader-grow-area"
              style={{ flex: 1 }}
            >
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}

          <div className="item-tray-type">
            <img src={customBuildImage} alt="potpro" />
            <div className="title-tray-type">
              CUSTOM BUILD
              <br />
              <b>COMING SOON!</b>
            </div>
          </div>
        </div>
      </div>
      <div className="kit-room-control">
        <Button
          text="Back"
          variant="secondary"
          onClick={() =>
            props.history.replace({
              pathname: "/roombuilder/room-dimensions",
              search: props.history.location.search,
            })
          }
        />
        <Button
          text="Continue"
          className={continueDisabled ? "disabled" : ""}
          variant="primary-new"
          onClick={() => {
            if (errorToShow) {
              props.openPopup({
                content: errorToShow.message,
              });
              return;
            }
            if (continueDisabled) {
              return;
            }

            const executeThis = () => {
              if (state.selectedContainer == 0) {
                props.history.push({
                  pathname: "/roombuilder/tray-size",
                  search: props.history.location.search,
                });
              } else {
                props.history.push({
                  pathname: "/roombuilder/container-or-medium",
                  search: props.history.location.search,
                });
              }
            };
            
            const trayWarning = props.getOneOfTheTrayWarnings();
            if (trayWarning) {
              props.openSpacingWarning(
                () => {
                  executeThis();
                });
            } else {
              executeThis();
            }
          }}
        />
      </div>
    </div>
  );
}
