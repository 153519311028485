import React, { Component } from "react";
import TrayModel from "../Models/Tray";
import { Group, Rect, Text } from "react-konva";

import PropTypes from "prop-types";

export default class DrainageCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.model = new TrayModel();
    this.groupRef = React.createRef();
  }

  timer = 0;

  onMouseOver = () => {
    clearTimeout(this.timer);
    if (!this.state.showBtn) {
      this.setState({
        showBtn: true,
      });
    }
  };

  onMouseOut = () => {
    clearTimeout(this.timer);
    if (this.state.showBtn) {
      this.timer = setTimeout(() => {
        this.setState({
          showBtn: false,
        });
      }, 200);
    }
  };
  componentDidMount = () => {
    window._info2 = () => {
      console.log(this.props, this.groupRef);
    };
  };

  getTextRotation = () => {
    if (this.props.platformDrainageDirection === "right") {
      return "90";
    }

    if (this.props.platformDrainageDirection === "left") {
      return "-90";
    }
  };

  getTextWidth = () => {
    if (
      this.props.platformDrainageDirection === "right" ||
      this.props.platformDrainageDirection === "left"
    ) {
      return this.props.bodyHeight;
    }

    return this.props.bodyWidth;
  };

  getTextHeight = () => {
    if (
      this.props.platformDrainageDirection === "right" ||
      this.props.platformDrainageDirection === "left"
    ) {
      return this.props.bodyWidth;
    }

    return this.props.bodyHeight;
  };

  getTextX = () => {
    if (this.props.platformDrainageDirection === "right") {
      return this.props.x + 40;
    }

    if (this.props.platformDrainageDirection === "left") {
      return this.props.x + 20;
    }

    return this.props.x;
  };

  getTextY = () => {
    if (this.props.platformDrainageDirection === "right") {
      return this.props.y;
    }

    if (this.props.platformDrainageDirection === "left") {
      return this.props.y + this.props.bodyHeight;
    }

    return this.props.y + 20;
  };

  render() {

    const {
      btnWidth,
      btnHeight,
      textY,
      btnY,
      btnX,
      trayY,
    } = this.model.getTrayData(this.props);

    return (
      <Group ref={this.groupRef}>
        <Group>
          <Rect
            width={this.props.bodyWidth}
            height={this.props.bodyHeight}
            x={this.props.x}
            y={this.props.y}
            fill="white"
            strokeEnabled={true}
            strokeScaleEnabled={true}
            stroke="#707070"
            strokeWidth={1}
            id={this.props.id}
          />
          <Text
            x={this.getTextX()}
            y={this.getTextY()}
            width={this.getTextWidth()}
            height={this.getTextHeight()}
            fill="#black"
            text={this.props.label}
            fontSize={21}
            fontFamily="Lato Bold"
            align="center"
            verticalAlign="center"
            rotation={this.getTextRotation()}
          />
        </Group>
      </Group>
    );
  }
}

// DrainageCanvas.propTypes = {
//   platformDrainageDirection: PropTypes.string,
//   bodyHeight: PropTypes.number,
//   bodyWidth: PropTypes.number,
//   bodyWidth: PropTypes.number,
//   y: PropTypes.number,
//   bodyWidth: PropTypes.number,
//   x: PropTypes.number,
//   id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   label: PropTypes.string,
// };
