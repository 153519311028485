import { Component } from "react";
import SaveRoomForm from "./SaveRoomForm";

class SaveRoomWarning extends Component {
        constructor(props) {
          super(props);
          this.state = {
            /**
             * @type {object}
             * @property {string} roomDetails.plantPerAreaLabel
             * @property {string} roomDetails.plantPerAreaImage
             * @property {string} roomDetails.irrigationLabel
             * @property {string} roomDetails.irrigationImage
             * @property {string} roomDetails.potLabel
             * @property {string} roomDetails.potImage
             */
            roomDetails: this.props.roomDetails,
            room_name: "",
          };
        }

        onCancel = () => {
          this.props.closePopup();
        };
      
        /**
         * this gets trigger after the room is saved
         */
        goFromForm = () => {
            this.props.redirectAfterRoomWarning();
        };

        onSkipSave = () => {
            this.props.redirectAfterRoomWarning();
        }
      
        render() {
          return (
            <div id="save-room-popup-rm-your-build" className="build-steps-popup">
                <SaveRoomForm
                  {...this.props}
                  defaultRoomName={this.props.defaultRoomName || ""}
                  goNext={this.goFromForm}
                  onCancel={this.onCancel}
                  onSkipSave={this.onSkipSave}
                  isExitBuildWarning={true}
                />
            </div>
          );
        }
      }
 
export default SaveRoomWarning;