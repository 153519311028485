import * as React from "react";
import "./IndicatorText.scss";

import { ReactComponent as ExclamationIcon } from "./../../../assets/svg/exclamation.svg";

export default function IndicatorText(props) {
  return (
    <div className="text-indicator">
      <div className="indicator-icon">
        <ExclamationIcon />
      </div>
      <div className="text-label">{props.label}</div>
    </div>
  );
}
