export const listPlantsPerArea = [
  { id: 11, label: "8 PLANT PER 4X4 AREA", value: 8 },
  { id: 1, label: "12 PLANT PER 4X4 AREA", value: 12 },
  { id: 2, label: "16 PLANT PER 4X4 AREA", value: 16 },
];

// Development
//export const APP_URL = "http://localhost:3000/";
//export const BASE_URL = "https://ffstaging.outofdust.co/";

// Testing
// export const APP_URL = "http://rbdev.floraflex.com/";
// export const BASE_URL = "https://ffm2.outofdust.co/";

// Staging
//export const APP_URL = "http://rbstaging.floraflex.com/";
//export const BASE_URL = "https://ffstaging.outofdust.co/";

// Production
//export const APP_URL = "https://roombuilder.floraflex.com/";
// export const BASE_URL = "https://mcstaging.floraflex.com/";

export const APP_URL = process.env.REACT_APP_APP_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

window.APP_URL = APP_URL;

export const CANVAS_TRIGGER_TYPES = {
  ZOOM_IN: "ZOOM_IN",
  ZOOM_OUT: "ZOOM_OUT",
  FULLSCREEN: "FULLSCREEN",
  FRAME_ZOOM: "FRAME_ZOOM",
};
export const canvasDim = {
  width: 746,
  height: 467,
};
/**
 * give access in public directory for 3d scripts
 */
window.CANVAS_TRIGGER_TYPES = CANVAS_TRIGGER_TYPES;

export const productTypes = {
  vf: "vf",
  v1: "v1",
  v2: "v2",
  bf: "bf",
  b1: "b1",
  b2: "b2",
  ft: "ft",
};

/** REDIRECT FROM BUILD FLOW */
export const REDIRECT_FROM_BUILD_ACTIONS = {
  GO_TO_REMOTE: "GO_TO_REMOTE",
  //default
  GO_TO_PATHNAME: "GO_TO_PATHNAME"
}

export const EVENTS = {
  REDIRECT_FROM_BUILD_FLOW: "request-redirect-from-kitbuilder-flow",
  LOADER_3D_CANVAS_OPEN: "open-loader-3d-canvas"
}

export const PLUMBING_POSITIONS = {
  RIGHT: 0, //this is must be compared in a casted context (this equals undefined, "", null)
  BOTTOM: 1,
  LEFT: 2,
  TOP: 3
};

export const PLUMBING_POSITIONS_STRING = {
  0: "RIGHT", //this is must be compared in a casted context (this equals undefined, "", null)
  1: "BOTTOM",
  2: "LEFT",
  3: "TOP"
};

export const PLUMBING_THICKNESS = 1;

export const DEFAULT_GROW_AREA_ID = 0;

export const MAX_SQ_FT = (25 * 25);

export const GROW_ARE_IDS = {
  STANDARD_TRAY: 0,
  PLATFORM_PROTO: 1
}

export const STEP_VALUES = {
    ROOM_DIMENSIONS: "Room Dimensions",
    GROW_AREA_STYLE: "Grow Area Style",
    TRAY_SIZE: "Tray Size",
    CONTAINER_OR_MEDIUM: "Container or Medium",
    PLATFORM_LAYOUT: "Platform Layout",
    ZONING_STYLE: "Zoning Style",
    IRRIGATION_STYLE: "Irrigation Style",
    RECOMMENDED_BUILDS: "Recommended Builds",
    CART_SUMMARY: "Cart Summary",
    NUTRIENT_CALCULATOR: "Nutrient Calculator",
}

/**
 * JDOC
 */
/**
 * @typedef {Object} IPlatformData
 * @property {String} platformDrainageDirection
 * @property {Number} platformLinkLength
 * @property {Number} platformNumRows
 * @property {Number} platformPlatformsPerRow
 * @property {Number} platformSubzoneWidth
 * @property {Number} platformWalkwayWidth
 * @property {Number} platformXcoord
 * @property {Number} platformYcoord
 */

 /**
  * @typedef {Object} IGrowAre
 * @property {Number} id
 * @property {String} label
 */

/**
 * @typedef {Object} IChosenPot
 * @property {Boolean} fromGenericContainer
 * @property {Number} id
 * @property {String} image
 * @property {Number} index
 * @property {String} key
 * @property {String} label
 * @property {Number} value
 * @property {Number} valueNumber
 */

/**
 * @typedef {Object} IPlumbing
 * @property {Number} id
 * @property {String} label
 * @property {String} value
 */

/**
 * @typedef {Object} ICalculatorState
 * @property {Number} addCycles
 * @property {String} flush
 * @property {Number} plant
 * @property {Number} plantItemId
 * @property {String} plantItemImage
 * @property {String} plantLabel
 * @property {Number} volume
 * @property {Number} week
 */

/**
 * @typedef {Object} IRoomSize
 * @property {String} measureSystem
 * @property {Number} roomLength
 * @property {String} roomLengthVal
 * @property {String} roomLengthVall
 * @property {Number} roomWidth
 * @property {String} roomWidthVal
 * @property {String} roomWidthVall
 */

 /**
 * @typedef {Object} IIrigationStyle
 * @property {Number} id
 * @property {String} image
 * @property {String} label
 */

/**
 * @typedef {Object} IKitBuilderState
 * @property {ICalculatorState} calculatorState
 * @property {IChosenPot} chosenPot
 * @property {Object} dripperPerPlant
 * @property {Object} dripperStyle
 * @property {IGrowAre} growArea
 * @property {Object} ignoreWarning
 * @property {IIrigationStyle} irrigationStyle
 * @property {Object} mainLinePipe
 * @property {IPlatformData} platformData
 * @property {IPlumbing} plumbing
 * @property {IRoomSize} roomSize
 * @property {Number} roomSizeKey
 * @property {Array} trays
 * @property {Object} wateredZone
 * @property {Array} zones
 */
