import React from "react";
import { Component } from "react";
import axios from "axios";
import { Button, Spinner } from "react-bootstrap";
import ButtonReact from "./../../components/Button/Button";

const POPUP_STEPS = {
  CONFIRMATION: "CONFIRMATION",
  SAVE_POPUP: "SAVE_POPUP",
};

class WelcomePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomName: "Copy of " + this.props.roomName,
      roomDescription: this.props.roomDescription,
      room: this.props.room,
      clientID: this.props.clientID,
      errorName: false,
      loadingNutrients: false,

      isFetchingSave: false,
    };
  }

  onChangeRoomName = (event) => {
    this.setState({
      roomName: event.target.value,
    });
  };

  onChangeRoomDescription = (event) => {
    this.setState({
      roomDescription: event.target.value,
    });
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <div className="modal-cstm-data" type-content="duplicate-save">
        <div className="modal-cstm-title">SAVE YOUR BUILD</div>
        <div className="modal-cstm-text">
          Name and save your build to your account to allow you to access your
          build details in the future in “My Builds”.
        </div>
        <div className="modal-cstm-fields">
          <div className="input-pop-cstm">
            <input
              name="roomName"
              type="text"
              value={this.state.roomName}
              onChange={this.onChangeRoomName}
              placeholder="Enter room name.."
              error={this.state.errorName ? "yes" : "no"}
            />
          </div>
          <div className="input-pop-cstm">
            <textarea
              name="roomDescription"
              type="text"
              value={
                this.state.roomDescription !== null
                  ? this.state.roomDescription
                  : ""
              }
              onChange={this.onChangeRoomDescription}
              placeholder="Add a build description.."
            />
          </div>
        </div>
        <div className="modal-buttons">
          <ButtonReact
            variant="primary"
            onClick={() => {
              this.onClose();
            }}
            text="OKAY"
            loading={this.state.isFetchingSave}
          />
        </div>
      </div>
    );
  }
}

class DuplicateConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: `Welcome to RoomBuilder!`,
    };
  }

  render() {
    return (
      <div className="modal-cstm-data" type-content="duplicate-popup-confirm">
        <div className="modal-cstm-title">{this.state.title}</div>
        <div className="modal-cstm-text">
          <p>
            Thank you for trying our new RoomBuilder. We’re still hard at work making this the best we can.
          </p>
          <p>
            Performance may not be ideal on all devices, but we are constantly working to make things run smoother for everyone.
          </p>
          <p>
            If you have any issues or feedback please use look for the “? Feedback” option on the bottom right of your screen.
          </p>
        </div>
        <div className="modal-buttons">
          <Button
            variant="primary"
            onClick={() => {
              this.props.onConfirm();
            }}
          >
            {" "}
            LET'S GO
          </Button>
        </div>
      </div>
    );
  }
}

class DuplicateRoomFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: POPUP_STEPS.CONFIRMATION,
    };
  }

  onConfirm = () => {
    this.props.onClose();
  };

  render() {
    if (this.state.step === POPUP_STEPS.CONFIRMATION) {
      return (
        <DuplicateConfirm
          roomName={this.props.roomName}
          onConfirm={this.onConfirm}
        />
      );
    }

    return <WelcomePopup {...this.props} />;
  }
}

export default DuplicateRoomFlow;
