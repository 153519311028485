// @flow
import * as React from "react";

import loadingGif from "./../../../../assets/image/modalLogo.gif";
import modalLogo from "./../../../../assets/image/modalLogo.png";

let timeout = 0;
export function CustomPopupLogo() {
  const [showSpinner, setShowSpinner] = React.useState(true);

  React.useEffect(() => {
    timeout = setTimeout(() => {
      setShowSpinner(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="modal-cstm-logo">
      {showSpinner && (
        <img className="logo-loader" src={loadingGif} alt="FloraFlex-loading" />
      )}
      {!showSpinner && (
        <img id="popup-logo-idle" src={modalLogo} alt="FloraFlex" />
      )}
    </div>
  );
}
