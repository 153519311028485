// @flow
import React, { useEffect } from "react";
import { Component } from "react";
import { Button, Spinner } from "react-bootstrap";
import { gql } from "@apollo/client";
import axios from "axios";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      verificationCode: "",
      captchaImage: "",
      emailError: "",
      successForm: false
    };
    this.props.editPopup({
      nodeAttributes: { step: "FORGOT_PASSWORD_POPUP", forgotpassword: "true" },
      // logo: popupImage,
    });

    // this.getCaptcha();
  }

  onCancel = () => {
    this.props.closePopup();
  };

  getCaptcha = () => {
    axios.get(`${window.BASE_URL}rbapi/testCaptcha.php`).then((res) => {
      const data = res.data;
      this.setState({ captchaImage: data });
    });
  };

  submitOnEnterForm = (event) => {
    if (event.charCode == 13) {
      this.submitForm();
    }
  };

  submitForm = () => {
    if (
      this.state.email !== "" &&
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        this.state.email
      )
    ) {
      this.setState({ emailError: "" });

      this.props.client
        .mutate({
          mutation: gql`
              mutation {
                  requestPasswordResetEmail(
                      email: "${this.state.email}"
                  )
              }
              `,
        })
        .then((result) => {
          const formSuccess = result?.data?.requestPasswordResetEmail;
          if(formSuccess && formSuccess === true)
          {
            document.getElementById("forgot-password-popup").style.display = "none";
            this.setState({successForm: true});
          }
        })
        .catch((error) => {
          document.getElementById("forgot-password-popup").style.display = "none";
          this.setState({successForm: true});
          console.log("ERROR FORGOT PASSWORD", { error });
        });
    } else {
      if (this.state.email !== "") {
        if (
          !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
            this.state.email
          )
        ) {
          this.setState({
            emailError:
              "Please enter a valid email address (Ex: johndoe@domain.com).",
          });
        } else {
          this.setState({ emailError: "" });
        }
      } else {
        this.setState({ emailError: "This is a required field." });
      }
    }
  };
  submitFormComplete = () => {
    if (
      this.state.email !== "" &&
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        this.state.email
      ) &&
      this.state.verificationCode != ""
    ) {
      axios
        .post(`${window.BASE_URL}rbapi/checkCaptcha.php`, {
          captcha_code: this.state.verificationCode,
        })
        .then((result) => {
          console.log(result);
          if (result.data === "SUCCESS") {
            this.props.client
              .mutate({
                mutation: gql`
                mutation {
                    requestPasswordResetEmail(
                        email: "${this.state.email}"
                    )
                }
                `,
              })
              .then((result) => {
                
              })
              .catch((error) => {
                console.log("ERROR FORGOT PASSWORD", { error });
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("All fields required");
    }
  };

  render() {
    if(this.state.successForm === true) {
      return (
        <div id="forgot-password-popup-success">
          <div className="modal-cstm-data">
            <div className="modal-cstm-title">EMAIL SENT</div>
            <div className="modal-cstm-text">
            If there is an account associated with {this.state.email !== "" ? this.state.email : "this email"} you will receive an email with a link to reset your password.
            </div>
            
          </div>
          <div className="modal-buttons">
            <Button variant="primary" onClick={this.props.closePopup}>
              OKAY
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div id="forgot-password-popup">
          <div className="modal-cstm-data">
            <div className="modal-cstm-title">FORGOT YOUR PASSWORD?</div>
            <div className="modal-cstm-text">
              Please enter your email address below to
              <br />
              receive a password reset link.
            </div>
            <div className="form-forgot-password">
              <form>
                <div className="input--label">
                  <div className="input-title">Email</div>
                  <input
                    type="text"
                    name="email"
                    placeholder=""
                    onKeyPress={(e) => this.submitOnEnterForm(e)}
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                      })
                    }
                    value={this.state.email}
                  />
                  {this.state.emailError !== "" && (
                    <span className="error-input-label">
                      {this.state.emailError}
                    </span>
                  )}
                </div>
                {/* <div className="input--label">
                  <div className="input-title">
                    Please type the letters and numbers below
                  </div>
                  <input
                    type="text"
                    name="verification-code"
                    placeholder=""
                    onKeyPress={(e) => this.submitOnEnterForm(e)}
                    onChange={(e) =>
                      this.setState({
                        verificationCode: e.target.value,
                      })
                    }
                    value={this.state.verificationCode}
                  />
                </div>
                <div className="captcha-cstm">
                  <img src={this.state.captchaImage} alt="" />
                  <div
                    className="reload-captcha-cstm"
                    onClick={() => this.getCaptcha()}
                  >
                    Reload captcha
                  </div>
                </div> */}
              </form>
            </div>
          </div>
          <div className="modal-buttons">
            <Button variant="secondary" onClick={this.props.closePopup}>
              CANCEL
            </Button>
            <Button variant="primary" onClick={() => this.submitForm()}>
              OKAY
            </Button>
          </div>
        </div>
      );
    }
    // return (
    //   <div id="forgot-password-popup">
    //     <div className="modal-cstm-data">
    //       <div className="modal-cstm-title">FORGOT YOUR PASSWORD?</div>
    //       <div className="modal-cstm-text">
    //         Please enter your email address below to
    //         <br />
    //         receive a password reset link.
    //       </div>
    //       <div className="form-forgot-password">
    //         <form>
    //           <div className="input--label">
    //             <div className="input-title">Email</div>
    //             <input
    //               type="text"
    //               name="email"
    //               placeholder=""
    //               onKeyPress={(e) => this.submitOnEnterForm(e)}
    //               onChange={(e) =>
    //                 this.setState({
    //                   email: e.target.value,
    //                 })
    //               }
    //               value={this.state.email}
    //             />
    //             {this.state.emailError !== "" && (
    //               <span className="error-input-label">
    //                 {this.state.emailError}
    //               </span>
    //             )}
    //           </div>
    //           {/* <div className="input--label">
    //             <div className="input-title">
    //               Please type the letters and numbers below
    //             </div>
    //             <input
    //               type="text"
    //               name="verification-code"
    //               placeholder=""
    //               onKeyPress={(e) => this.submitOnEnterForm(e)}
    //               onChange={(e) =>
    //                 this.setState({
    //                   verificationCode: e.target.value,
    //                 })
    //               }
    //               value={this.state.verificationCode}
    //             />
    //           </div>
    //           <div className="captcha-cstm">
    //             <img src={this.state.captchaImage} alt="" />
    //             <div
    //               className="reload-captcha-cstm"
    //               onClick={() => this.getCaptcha()}
    //             >
    //               Reload captcha
    //             </div>
    //           </div> */}
    //         </form>
    //       </div>
    //     </div>
    //     <div className="modal-buttons">
    //       <Button variant="secondary" onClick={this.props.closePopup}>
    //         CANCEL
    //       </Button>
    //       <Button variant="primary" onClick={() => this.submitForm()}>
    //         OKAY
    //       </Button>
    //     </div>
    //   </div>
    // );
  }
}
export default ForgotPassword;
