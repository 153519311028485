// @flow
import Axios from "axios";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ExtraKits from "./ExtraKits";
import PreMadeKits from "./PreMadeKits";
import { layoutActions } from "../../redux/actionFunctions/layoutActions";
import { gql, useApolloClient } from "@apollo/client";
import { purchaseErrors } from "./purchaseKitFunctions";
import { paramsRouter } from "../../utils/utilFunctions";

export default function PurchaseKits(props) {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    plantNumber: "",
    growPot: undefined,
    allPlantItems: {},
    upsells: [],
    allNumbers: [],
  });

  const [clientData, setClientData] = React.useState({
    client_cart_id: "",
    key: undefined, //is important to be undefined
    cartItemsClient: [],
  });

  /**
   * used for triggering an action after loader was rendered (to avoid setLoader off render trigger before setLoader on in cas request is faster than render )
   */
  const [loaderState, setLoaderState] = React.useState({
    key: undefined,
    action: undefined,
    callback: undefined,
  });

  function getData() {
    if (loaderState.open && loaderState.action === "getData") {
      return;
    }
    setLoaderState({
      key: Date.now(),
      open: true,
      action: "getData",
      callback: () => {
        Axios.get("/rbapi/getKits.php")
          .then(({ data }) => {
            const computeArray = (item, index) => {
              const imagePath = item.image || item.img;
              return {
                ...item,
                label: item.name,
                key: `${item.name}-${index}-${Date.now()}`,
                image: `${process.env.REACT_APP_BASE_URL}${imagePath}`,
                quantity: item.quantity || item.qty,
              };
            };
            const allPlantItems =
              data?.num_plants && typeof data?.num_plants === "object"
                ? Object.entries(data.num_plants).reduce(
                    (final, [number, value]) => {
                      if (Array.isArray(value)) {
                        return {
                          ...final,
                          [number]: value.map(computeArray),
                        };
                      }
                      return final;
                    },
                    {}
                  )
                : {};
            const upsells = Array.isArray(data?.upsells)
              ? data.upsells.map(computeArray)
              : [];

            let plantNumber = "";
            if (Object.entries(allPlantItems).length) {
              plantNumber =
                Object.keys(allPlantItems).find((number) => number === "6") ||
                Object.keys(allPlantItems)[0];
            }
            setState({
              ...state,
              plantNumber,
              upsells,
              allPlantItems,
              allNumbers: Object.entries(allPlantItems).map(
                ([number, items]) => ({
                  label: number,
                  value: number,
                })
              ),
            });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoaderState({
              open: false,
            });
          });
      },
    });
  }
  function openPopup(payload) {
    let key;
    if (payload.error_code) {
      key = payload.error_code;
    } else {
      key = Date.now();
    }
    layoutActions(dispatch).pushPopupStack({
      key,
      title: "",
      buttonOkText: "Ok",
      callbackNo: (closePopup) => {
        closePopup();
      },
      ...payload,
    });
  }

  function cleanGetParamsFromHashRouter() {
    /** remove cart id from GET - to avoid problems when user navigates */
    props.history.replace({
      pathname: props.history.location.pathname,
      search: "",
    });
  }

  function getEmptyCart() {
    const cart_id = paramsRouter(props).get("cart_id");
    if (cart_id) {
      cleanGetParamsFromHashRouter();

      return setClientData({
        client_cart_id: cart_id,
        key: Date.now(),
      });
    }

    /** create empty cart mutation - then assign cart id to magento client */
    client
      .mutate({
        mutation: gql`
          mutation {
            createEmptyCart
          }
        `,
      })
      .then((result) => {
        const cart_id = result?.data?.createEmptyCart;

        /** this link will get appended with synced cart id (in the hash part, not the GET itself) */
        const redirect_back = encodeURIComponent(
          `${window.location.origin}/${window.location.hash}`
        );
        window.location.href = `${window.BASE_URL}roombuilderconfig/customerdata/customercart?redirect_back=${redirect_back}&cart_id=${cart_id}`;
      })
      .catch((error) =>
        console.log("ERROR WHILE CREATING EMPTY CART", { error })
      );
  }

  function addProductsToCart(items) {
    return client
      .mutate({
        mutation: gql`
        mutation {
          addProductsToCart(
            cartId: "${clientData.client_cart_id}"
            cartItems: [
              ${items.reduce(
                (final, current) =>
                  final +
                  " " +
                  `
                      {
                        quantity: ${current.quantity}
                        sku: "${current.sku}"
                        room_name: ""
                        room_id: 79
                      }`,
                ""
              )}
            ]
          ) {
            cart {
              items {
                id
                product {
                  name
                  sku
                }
                quantity
              }
            }
          }
        }
        `,
      })
      .then((result) => {
        const cartItemsClient =
          result?.data?.addProductsToCart?.cart?.items || [];
        if (Array.isArray(result?.errors) && result?.errors.length > 0) {
          openPopup({
            content: purchaseErrors.GENERIC_ERROR.message,
          });
          return new Promise((resolve, reject) => reject(result));
        }
        setClientData({
          ...clientData,
          cartItemsClient: Array.isArray(cartItemsClient)
            ? cartItemsClient
            : clientData.cartItemsClient,
        });
        return new Promise((resolve, reject) => resolve(cartItemsClient));
      })
      .catch(() => {});
  }

  function getClientCart() {
    return client
      .query({
        query: gql`
        {
          cart(cart_id: "${clientData.client_cart_id}") {
            items {
              id
              product {
                name
                sku
              }
              quantity
            }
          }
        }
      `,
      })
      .then((result) => {
        const cartItemsClient = result?.data?.cart?.items || [];
        setClientData({
          ...clientData,
          cartItemsClient,
        });
        return new Promise((resolve, reject) => resolve(cartItemsClient));
      })
      .catch((response) => {
        /** avoid problems when the cart id was put as a dummy data in url or has expired in the background, and we need to create another and send that to magento in order to sync cart id */
        if (
          Array.isArray(response?.errors) &&
          response.errors.length &&
          response.errors.some(
            (item) => item?.extensions?.category === "graphql-no-such-entity"
          )
        ) {
          cleanGetParamsFromHashRouter();
          window.location.reload();
        }
      });
  }

  React.useLayoutEffect(() => {
    getData();
    getEmptyCart();
    window.devSetEmptyCart = (value) => {
      setClientData({
        ...clientData,
        client_cart_id: value,
        key: Date.now(),
      });
    };
  }, []);

  React.useEffect(() => {
    if (clientData.key) {
      getClientCart();
    }
  }, [clientData.key]);

  React.useEffect(() => {
    if (
      loaderState.action &&
      loaderState.key &&
      typeof loaderState.callback === "function"
    ) {
      loaderState.callback();
    }
  }, [loaderState.key]);

  React.useEffect(() => {}, []);

  return (
    <Switch>
      <Route
        path="/purchase-kits/plant"
        render={(routerProps) => (
          <PreMadeKits
            {...routerProps}
            setPurchaseKit={({ plantNumber, growPot }) => {
              setState({ ...state, plantNumber, growPot });
            }}
            plantNumber={state.plantNumber}
            growPot={state.growPot}
            growItems={state.allPlantItems[state.plantNumber] || []}
            allNumbers={state.allNumbers}
            loading={loaderState.open && loaderState.action === "getData"}
            openPopup={openPopup}
          />
        )}
      />
      {state.growPot && (
        <Route
          path="/purchase-kits/extra"
          render={(routerProps) => (
            <ExtraKits
              {...routerProps}
              loading={loaderState.open && loaderState.action === "getData"}
              upsells={state.upsells}
              openPopup={openPopup}
              growPot={state.growPot}
              growItems={state.allPlantItems[state.plantNumber] || []}
              addProductsToCart={addProductsToCart}
              cartItemsClient={clientData.cartItemsClient}
            />
          )}
        />
      )}
      <Route
        path="/purchase-kits"
        render={() => <Redirect to="/purchase-kits/plant" />}
      />
    </Switch>
  );
}
