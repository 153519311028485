import React, { Component } from "react";
import TrayModel from "../Models/Tray";
import { Group, Rect, Text } from "react-konva";
import URLImage from "./URLImage";
import rotateSvg from "../../../../../assets/svg/rotate-white.svg";

export default class TrayCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.model = new TrayModel();
    this.groupRef = React.createRef();
  }
  timer = 0;

  onMouseOver = () => {
    clearTimeout(this.timer);
    if (!this.state.showBtn) {
      this.setState({
        showBtn: true,
      });
    }
  };

  onMouseOut = () => {
    clearTimeout(this.timer);
    if (this.state.showBtn) {
      this.timer = setTimeout(() => {
        this.setState({
          showBtn: false,
        });
      }, 200);
    }
  };
  componentDidMount = () => {
    window._info2 = () => {
      console.log(this.props, this.groupRef);
    };
  };
  printfun = (params) => {};
  onDragStart = (params) => {
    // console.log(`
    // "START" \n event.xy:${params.evt.x}, ${params.evt.y}
    // event.offsetXY:${params.evt.offsetX}, ${params.evt.offsetY}
    // event.layerxy:${params.evt.layerX}, ${params.evt.layerY}
    // `);
  };
  onDragEnd = (params) => {
    this.lastX = params.evt.offsetX;
    this.lastX2 = params.target.attrs.x;
    this.props.onDragEnd(params);
  };
  render() {
    const {
      btnWidth,
      btnHeight,
      textY,
      btnY,
      btnX,
      trayY,
    } = this.model.getTrayData(this.props);

    return (
      <Group
        draggable
        onDragStart={this.onDragStart}
        onDragMove={this.onDragMove}
        onDragEnd={(event) => this.onDragEnd(event)}
        ref={this.groupRef}
      >
        <Group onMouseOut={this.onMouseOut} onMouseOver={this.onMouseOver}>
          
          <Rect
            width={this.props.bodyWidth}
            height={this.props.bodyHeight}
            x={this.props.x}
            y={trayY}
            fill="white"
            strokeEnabled={true}
            strokeScaleEnabled={true}
            stroke="#707070"
            strokeWidth={1}
          />
          <Text
            x={this.props.x}
            y={textY}
            width={this.props.bodyWidth}
            height={this.props.bodyHeight}
            fill="black"
            text={this.props.label}
            fontSize={21}
            lineHeight={1}
            fontFamily="Lato Bold"
            align="center"
            verticalAlign="center"
          />
          <Text
            x={this.props.x}
            y={textY + 27}
            width={this.props.bodyWidth}
            height={this.props.bodyHeight}
            fill="#54565A"
            text={this.props.subLabel}
            fontSize={16}
            lineHeight={1}
            fontFamily="Lato"
            align="center"
            verticalAlign="center"
          />
          {!this.props.plumbingRectancle ? (
            <></>
          ) : (
            <>
              <Rect
                width={this.props.plumbingRectancle.width}
                height={this.props.plumbingRectancle.height}
                x={this.props.plumbingRectancle.x}
                y={this.props.plumbingRectancle.y}
                fill={this.props.plumbingRectancle.fill}
                strokeEnabled={this.props.plumbingRectancle.strokeEnabled}
                strokeScaleEnabled={
                  this.props.plumbingRectancle.strokeScaleEnabled
                }
                stroke={this.props.plumbingRectancle.stroke}
                strokeWidth={this.props.plumbingRectancle.strokeWidth}
              />
            </>
          )}
        </Group>

        {!this.props.plumbingRectancle ? (
            <></>
          ) : (
            <>
              <Text
                {...this.props.plumbingRectancle.textProps}
                
              />
            </>
          )}
        {this.state.showBtn && (
          <Group
            onMouseOut={this.onMouseOut}
            onMouseOver={this.onMouseOver}
            onClick={this.props.rotate ? this.props.rotate : () => {}}
          >
            <Rect
              width={btnWidth}
              height={btnHeight}
              x={btnX}
              y={btnY}
              fill="#54565A"
              strokeEnabled={true}
              strokeScaleEnabled={true}
              stroke="#707070"
              strokeWidth={1}
            />
            <URLImage src={rotateSvg} x={btnX + 11} y={btnY + 11} />
          </Group>
        )}
      </Group>
    );
  }
}
