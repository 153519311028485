import React from "react";
// reusable components import
import Button from "../../../components/Button/Button";

import oneSizeImg from "./../../../../assets/image/water-zone/1Way.png";
import bySubImg from "./../../../../assets/image/water-zone/2Way.png";

import {
  generalErrors
} from "./KitRoomFunctions";

const zones = [
  {
    id: 1,
    label: "One Zone",
    value: "One Zone",
    description: `All your plants will be watered together using one nylon valve`,
    image: oneSizeImg,
  },
  {
    id: 2,
    label: "By Sub-Zone",
    value: "By Sub-Zone",
    description: `Each of your sub-zones can be watered separately with their own nylon valve`,
    image: bySubImg,
  },
];

export default function KitRoomFormStepTwo(props) {

  const errorToShow = !props.wateredZone.id ? generalErrors.NO_WATER_ZONE : null;
  const continueDisabled = errorToShow != null;

  return (
    <div className="step-content kit-room-content zone-wrapper">
      <h1 className="step-title">
        Would you like to water your plants as one zone or by sub-zone?
      </h1>
      <div className="flex-all container-or-medium-wrapper">
        {zones.map((item) => {
          return (
            <React.Fragment key={item.id + "-water"}>
              <div
                className={`zone-selection-square cursor-pointer ${
                  item.id == props.wateredZone.id ? ` selected` : ``
                }`}
                onClick={() => props.setWateredZone(item)}
              >
                <img alt={item.label} src={item.image} />
              </div>
              <div
                onClick={() => props.setWateredZone(item)}
                className="zone-label cursor-pointer generic-text-shadow"
              >
                {item.label}
                <span>{item.description}</span>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="kit-room-control">
        <Button
          text="Back"
          variant="secondary"
          onClick={() => {
            props.history.replace({
              pathname: "/roombuilder/platform-layout",
              search: props.history.location.search,
            });
          }}
        />
        <Button
          text="Continue"
          className={continueDisabled ? "disabled" : ""}
          variant="primary-new"
          onClick={() => {
            if (errorToShow) {
              props.openPopup({
                ...errorToShow,
                content: errorToShow.message,
              });
              return;
            }
            if (continueDisabled) {
              return;
            }
            props.history.push({
              pathname: "/roombuilder/recommended-builds",
              search: props.history.location.search,
            });

          }}
        />
      </div>
    </div>
  );
}
