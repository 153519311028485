import { PLUMBING_POSITIONS } from "../../../../utils/constants";
import { number2Digits } from "../../../../utils/utilFunctions";
import { CELL_WIDTH, gridMiddleX, gridMiddleY } from "../Constants";
import { generateGenericGrid } from "../Helpers";

/**
 * @typedef {Object} ITray
 * @property {Number} bodyHeight
 * @property {Number} bodyWidth
 * @property {Boolean} boxStatus
 * @property {Number} id
 * @property {Boolean} isRotated
 * @property {String} key
 * @property {String} label
 * @property {Number} lengthVal
 * @property {Number} lengthVal_RBased
 * @property {Number} offsetX
 * @property {Number} offsetY
 * @property {String} subLabel
 * @property {Number} widthVal
 * @property {Number} widthVal_RBased
 * @property {Boolean} xPosition
 * @property {number} plumbingPosition - 0,1,2,3 (right, bottom, left, top - in this order)
 */

export default class RoomModel {
  constructor(props) {
    if (props) {
      this.forScreenShot = props.forScreenShot;
    }
  }

  /**
   *
   * @param {Object} roomState
   * @param {ITray} tray
   * @returns
   */
  createPlumbingRectangle = (roomState, tray, xTray, yTray) => {
    let x, y;
    let xText, yText;
    let width, height, rotationText;
    let strokeWidth = 1;

    const { plumbingPosition } = tray;

    const textPaddingX = CELL_WIDTH / 2 + 7;
    const textPaddingY = tray.bodyHeight / 2 - 50;

    /**
     * to the right side of the tray
     */
    switch (plumbingPosition) {
      case PLUMBING_POSITIONS.TOP:
        x = xTray;
        y = yTray - CELL_WIDTH;
        width = tray.bodyWidth;
        height = CELL_WIDTH;

        //text coords
        xText = x + tray.bodyWidth / 2 - 50;
        yText = y + CELL_WIDTH / 2 - 7;
        rotationText = 0;
        break;
      case PLUMBING_POSITIONS.LEFT:
        x = xTray - CELL_WIDTH;
        y = yTray;
        width = CELL_WIDTH;
        height = tray.bodyHeight;

        //text coords
        xText = x + textPaddingX;
        yText = y + textPaddingY;
        rotationText = 90;
        break;
      case PLUMBING_POSITIONS.BOTTOM:
        x = xTray;
        y = yTray + tray.bodyHeight;
        width = tray.bodyWidth;
        height = CELL_WIDTH;

        //text coords
        xText = x + tray.bodyWidth / 2 - 50;
        yText = y + CELL_WIDTH / 2 - 7;
        rotationText = 0;
        break;

      //PLUMBING_POSITIONS.RIGHT:
      default:
        x = xTray + tray.bodyWidth;
        y = yTray;
        width = CELL_WIDTH;
        height = tray.bodyHeight;

        //text coords
        xText = x + textPaddingX;
        yText = y + textPaddingY;
        rotationText = 90;
    }

    return {
      width,
      height,
      x,
      y,
      strokeEnabled: true,
      strokeScaleEnabled: true,
      stroke: "#707070",
      strokeWidth: 1,
      fill: "rgba(84, 86, 90, 0.44)",
      textProps: {
        x: xText,
        y: yText,
        text: "PLUMBING",
        fill: "white",
        fontSize: 20,
        lineHeight: 1,
        fontFamily: "Lato Bold",
        align: "center",
        verticalAlign: "center",
        rotation: rotationText,
      },
    };
  };

  generateRoomStart = (props) => {
    let bodyHeight = props.roomLength * CELL_WIDTH;
    let bodyWidth = props.roomWidth * CELL_WIDTH;

    let startX = gridMiddleX - CELL_WIDTH * Math.floor(props.roomWidth / 2);
    let startY = gridMiddleY - CELL_WIDTH * Math.floor(props.roomLength / 2);

    let roomWidthForExport;
    let roomHeightForExport;
    if (this.forScreenShot) {
      const padding = 1;

      roomWidthForExport = props.roomWidth * CELL_WIDTH;
      roomHeightForExport = props.roomLength * CELL_WIDTH;

      startX = padding * CELL_WIDTH;
      startY = padding * CELL_WIDTH;

      if (roomWidthForExport / roomHeightForExport !== 1) {
        if (roomWidthForExport > roomHeightForExport) {
          startY = (roomWidthForExport - roomHeightForExport) / 2;
          roomHeightForExport = roomWidthForExport;
        } else {
          startX = (roomHeightForExport - roomWidthForExport) / 2;
          roomWidthForExport = roomHeightForExport;
        }
      }
      roomWidthForExport += padding * CELL_WIDTH * 2;
      roomHeightForExport += padding * CELL_WIDTH * 2;
    }
    const roomGrid = props.forReadyOnly
      ? generateGenericGrid({
          roomStartX: startX,
          roomStartY: startY,
          cellCountX: props.roomWidth,
          cellCountY: props.roomLength,
        })
      : [];

    return {
      startX,
      startY,
      bodyHeight,
      bodyWidth,
      roomWidthForExport,
      roomHeightForExport,
      roomGrid,
    };
  };

  generateAux = (props, state) => {
    let topLine;
    let rightLine;
    const rectLineThick = 3;

    const textWidth = CELL_WIDTH * 2;

    if (Number(props.roomWidth)) {
      const topLineHalfWidth = Math.floor((state.bodyWidth - textWidth) / 2);

      const topLineY = state.startY - CELL_WIDTH * 1;

      topLine = {
        half1: {
          width: topLineHalfWidth,
          height: rectLineThick,
          x: state.startX,
          y: topLineY,
        },
        half2: {
          width: topLineHalfWidth,
          height: rectLineThick,
          x: state.startX + topLineHalfWidth + textWidth,
          y: topLineY,
        },
        text: {
          width: textWidth,
          height: CELL_WIDTH,
          x: state.startX + topLineHalfWidth,
          y: topLineY,
          label: `${props.roomWidthLabel}`,
          lineHeight: 0.2,
        },
        shadowBlur: 6,
        shadowOpacity: 0.16,
        shadowOffsetX: 0,
        shadowOffsetY: 3,
      };
    }
    if (Number(props.roomLength)) {
      const rightLineHeight = Math.floor((state.bodyHeight - textWidth) / 2);

      const rightLineX = state.startX + state.bodyWidth + CELL_WIDTH * 1;

      rightLine = {
        half1: {
          width: rectLineThick,
          height: rightLineHeight,
          x: rightLineX,
          y: state.startY,
        },
        half2: {
          width: rectLineThick,
          height: rightLineHeight,
          x: rightLineX,
          y: state.startY + rightLineHeight + textWidth,
        },
        text: {
          width: textWidth,
          height: textWidth,
          x: rightLineX - 50,
          y: state.startY + rightLineHeight + 50,
          label: `${props.roomLengthLabel}`,
          lineHeight: 0.2,
        },
      };
    }
    return {
      topLine,
      rightLine,
    };
  };

  canvasType = (pathName, platformDrainageDirection, growAreaId) => {
    let allowTrays = Number(growAreaId) !== 1;
    const allowPlatform =
      !allowTrays &&
      platformDrainageDirection &&
      platformDrainageDirection !== "none";
    //&&
    // /(\/roombuilder\/platform-layout)|(\/roombuilder\/zoning-style)/.test(
    //   pathName
    // );
    const platFormDraggable = allowPlatform;
    //&& /\/roombuilder\/platform-layout/.test(pathName);

    return {
      allowTrays,
      allowPlatform,
      platFormDraggable,
    };
  };

  /**
   * 'trim' postion of tray to fit the room
   * @param {object} roomProps
   * @param {object} item
   * @param {number} offsetX
   * @param {number} offsetY
   * @returns {object} {offsetX, offsetY}
   */
  validatedTrayOffsets = (roomProps, item, offsetX, offsetY) => {
    //disable plumbing validation for now
    const PADDING_PLUMBING = 0;

    //check if can fit on X and Y axes
    if (offsetX < 0) {
      offsetX = 0;
    }
    if (offsetY < 0) {
      offsetY = 0;
    }

    if (item.isRotated) {
      /**
       * if tray goes outside from the right side
       * NOTE: includes the plumbing rectable
       */
      if (
        offsetX >
        roomProps.roomWidth - item.widthVal_RBased - PADDING_PLUMBING
      ) {
        offsetX = roomProps.roomWidth - item.widthVal_RBased - PADDING_PLUMBING;
      }

      if (offsetY > roomProps.roomLength - item.lengthVal_RBased) {
        offsetY = roomProps.roomLength - 0 - item.lengthVal_RBased;
      }
    } else {
      if (offsetX > roomProps.roomWidth - item.widthVal_RBased) {
        offsetX = roomProps.roomWidth - 0 - item.widthVal_RBased;
      }

      /**
       * if tray goes outside from the bottom side
       * NOTE: includes the plumbing rectable
       */
      if (
        offsetY >
        roomProps.roomLength - item.lengthVal_RBased - PADDING_PLUMBING
      ) {
        offsetY =
          roomProps.roomLength - item.lengthVal_RBased - PADDING_PLUMBING;
      }
    }

    return {
      offsetX,
      offsetY,
    };
  };

  /**
   *
   * @param {ITray} tray
   * @returns {number}
   */
  rotatePlumbing = (tray) => {
    let currentPlumbing = Number(tray.plumbingPosition) || 0;

    if (currentPlumbing < 0) {
      currentPlumbing = 0;
    } else {
      if (currentPlumbing + 1 > 3) {
        currentPlumbing = 0;
      } else {
        currentPlumbing = currentPlumbing + 1;
      }
    }

    return currentPlumbing;
  };
}
