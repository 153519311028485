import * as React from "react";
import "./Switch.scss";

export default function Switch(props) {
  return (
    <div
      onClick={typeof props.onClick === "function" ? props.onClick : () => {}}
      className={`custom-switch-toggle${
        props.className ? ` ${props.className}` : ""
      }`}
      value={props.value}
    >
      {props.listValues.map((item, index) => {
        return (
          <div
            key={`${index}---custom-switch-toggle`}
            className={`switch-item ${
              item.value == props.value ? `selected` : ``
            }`}
            onClick={(event) => {
              event.stopPropagation();
              props.onSwitch(item.value, index);
            }}
          >
            <div className={`switch-item-label`}>{item.label}</div>
          </div>
        );
      })}
    </div>
  );
}
