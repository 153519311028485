// @flow
import * as React from "react";

import layout_logo_new from "./../../../../../assets/image/RoomBuilderLogo_Small_Glow_NEW.png";
// import controls3d from "./../../../../../assets/image/three-d/controls-3d.png";
import controls2d from "./../../../../../assets/image/tooltips/tooltip2D-complex.jpg";
import controls3d from "./../../../../../assets/image/tooltips/tooltip3D-simple.png";

import cameraSvg from "./../../../../../assets/svg/camera.svg";
import Switch from "./../../../../components/Switch/Switch";
import Button from "../../../../components/Button/Button";
import {
  copyToClipBoard,
  triggerCustomEvent,
} from "../../../../utils/utilFunctions";
import axios from "axios";
import imgPlaceholder from "./../../../../../assets/image/imgPlaceholder-3d.jpg";
import Room2dCanvas from "../../Canvas/Room2dCanvas";
import plusSvg from "../../../../../assets/svg/plus.svg";
import minusSvg from "../../../../../assets/svg/minus.svg";
import shareSvg from "../../../../../assets/svg/share.svg";
import * as constants from "./../../../../utils/constants";

import * as roomFunctions from "./../KitRoomFunctions";

import { useRef } from "react";
import XeoGLStats from '../../../../components/XeoGLStats';

/**
 * xeoglRenderObj - renderjs functions
 * window.xeoglRenderObj = {
	initXeoglSetup,
	buildRoom,
	diff,
	getPotCoordiantes,
	i2m,
	m2i,
	buildRoom
}
 *
*/

const maxTries = 20;
const adjustTries = 2;
let timeout_ = 0;
let wait2dTimeout = 0;
let ss3DTimeout = 0;

/**
 *
 * @param {array} trays
 * @param {object} roomSize
 * @param {number|string} roomSize.roomLength
 * @param {number|string} roomSize.roomWidth
 * @param {string} growarea
 * @param {string} container
 * @param {string} irrigation
 * @param {string} density
 */
export default function Kit3DCanvas(props) {
  const [state, setState] = React.useState({ use3D: false });
  const [imgUrl, _setImgUrl] = React.useState({
    tries: 0,
    img: "",
    img2: "",
  });

  const [canvasConfig, _setCanvasConfig] = React.useState({
    width: window.innerWidth - 397, // 395px from left side bar
    height: window.innerHeight - 55, // 119px for header + navbar + 43px for the canvas header
    zoomDir: 1,
    zoomKey: "",
    key: Date.now() + "canvas",
  });
  const [showControls, setShowControls] = React.useState();

  const containerRef = useRef();

  const [screenShotState, setScreenShotState] = React.useState({
    triggerKey: undefined,
    isFetching: false,
  });

  /** helps trigger re-render if that's wanted after a ref value change - accommodates async needs for loaderPdfOpenRef */
  const [triggerRender, setTriggerRender] = React.useState();
  const loaderPdfOpenRef = React.useRef({
    imageExportDone: true,
    image2DExportDone: true,
  });

  /** manages situation when 3d was loaded but the user has not saved the room, and room_id is null */
  const dataRef = React.useRef({
    dataURL_3D: "",
    dataURL_2D: "",
    waitForSaveRoom_3D: false,
    waitForSaveRoom_2D: false,
  });

  const stateRef = React.useRef(canvasConfig);
  function setCanvasConfig(_config) {
    stateRef.current = _config;
    _setCanvasConfig(_config);
  }

  function switchDisplays(_use3D) {
    if (state.loaded3D === true) {
      setState({
        ...state,
        use3D: _use3D,
      });
    }
  }

  function init() {
    window.KitBuilder3D_loaded = false;

    const growarea =
      roomFunctions.safeToUpperCase(props.growarea) || "STANDARD TRAY";
    const container = props.container || "8IN POTPRO POT";
    const irrigation = props.irrigation || "BUBBLER & FLORA CAP";
    const density = props.density || "8 PLANT PER 4X4 AREA";
    const trayType = props.plumbing.value || "";

    // Set up some varibles to be used by platforms
    let setDripperStyleLabel;

    if (!props.dripperStyle) {
      setDripperStyleLabel = "none";
    } else {
      setDripperStyleLabel = props.dripperStyle.label;
    }

    let dripperPerPlantLabel;
    if (!props.dripperPerPlant) {
      dripperPerPlantLabel = "none";
    } else {
      dripperPerPlantLabel = props.dripperPerPlant.label;
    }

    const dripperStyle = setDripperStyleLabel || "1 WAY";
    const dripperPerPlant = dripperPerPlantLabel || "2 DRIPPER PER PLANT";

    const room_width = props.roomSize.roomWidth;
    const room_length = props.roomSize.roomLength;
    const trays = props.trays.reduce((final, currentTray) => {
      final.push({
        tray_name: currentTray.label,
        tray_width: currentTray.widthVal,
        tray_length: currentTray.lengthVal,
        pos_x: currentTray.offsetX,
        pos_y: currentTray.offsetY,
        rotation: currentTray.isRotated ? "1" : "0", //for 2d we used the opposite
        plumbing_position: roomFunctions.plumbingPositionToString(
          currentTray.plumbingPosition
        ),
      });
      return final;
    }, []);
    const canvasName = "3D_Canvas";
    const image2D = "2Dimage";

    window.start3DTimestamp = Date.now();

    if (/^(http:\/\/localhost)/.test(window.location.href) && 0) {
      trigger3DLoaded();
    } else {
      if (typeof window.xeoglRenderObj?.initXeoglSetup === "function") {
        // try {
        window.KitBuilder3D_loaded = false;
        triggerCustomEvent(constants.EVENTS.LOADER_3D_CANVAS_OPEN);

        if (Number(props.growAreId) !== 1) {
          const payload = {
            growarea_: growarea,
            container_: container,
            irrigation_: irrigation,
            density_: density,
            room_width_: room_width,
            room_length_: room_length,
            trays_: trays,
            canvasName_: canvasName,
            image2D_: image2D,
            totalPots_: props.calculateNumOfPlants() || 8,
            trayType_: trayType,
            dripperStyle_: dripperStyle,
            dripperPerPlant_: dripperPerPlant,
          };
          window.init3dDev = payload;
          window.xeoglRenderObj.initXeoglSetup(payload);
        } else {
          const payload = {
            growarea_: growarea,
            container_: container,
            irrigation_: irrigation,
            density_: density,
            room_width_: room_width,
            room_length_: room_length,
            trays_: trays,
            canvasName_: canvasName,
            image2D_: image2D,
            totalPots_: props.calculateNumOfPlants() || 8,
            trayType_: trayType,
            dripperStyle_: dripperStyle,
            dripperPerPlant_: dripperPerPlant,
            //new - start
            platformXcoord_: props.platformData.platformXcoord,
            platformYcoord_: props.platformData.platformYcoord,
            platformNumRows_: props.platformData.platformNumRows,
            platformPlatformsPerRow_:
              props.platformData.platformPlatformsPerRow,
            platformLinkLength_: props.platformData.platformLinkLength,
            platformWalkwayWidth_: props.platformData.platformWalkwayWidth,
            platformSubzoneWidth_: props.platformData.platformSubzoneWidth,
            platformDrainageDirection_:
              props.platformData.platformDrainageDirection,
            //new - end
          };
          window.xeoglRenderObj.initXeoglSetupSystem5(payload);
          window.init3dDev = payload;
        }

        // } catch (err) {
        //   console.log("ERROR FROM xeogl SCRIPT - 3D NOT LOADED", err);
        //   trigger3DLoaded();
        // }
      } else {
        trigger3DLoaded();
      }
    }

    /**
     * get image from 2d canvas
     */
    // wait2dTimeout = setTimeout(() => {
    //   triggerCanvas2DExport();
    // }, 1000);
  }
  const imgRef = React.useRef(imgUrl);
  function setImgUrl(imgSt) {
    _setImgUrl(imgSt);
    imgRef.current = imgSt;
  }

  function take3dScreenShot() {
    if (!window.screenShotAfter3DRender) {
      setState({
        ...state,
        use3D: false,
        loaded3D: true
      });
      trigger3DLoaded();
    } else {
      window.screenShotAfter3DRender = false;

      /* start loader at next render */
      loaderPdfOpenRef.current = {
        ...loaderPdfOpenRef.current,
        imageExportDone: false,
        image2DExportDone: false,
      };

      console.log("here1");
      ss3DTimeout = setTimeout(() => {
        const canvasNode = document.getElementById("3D_Canvas");
        if (canvasNode) {
          const dataURL = canvasNode.toDataURL();

          /**
           * make sure the room was saved before any actions involving saving images
           * (as there is no room id to use)
           */
          console.log("here2");
          if (props?.selectedRoom?.id) {
            export3DFile(dataURL);
          } else {
            console.log("here3", window.lastSavedRoom);
            /** the system being in a lot of stress, is possible that  props?.selectedRoom?.id to undefined and the save room event to be triggered already, so we use this as a fallback */
            if (window.lastSavedRoom?.id) {
              export3DFile(dataURL, window.lastSavedRoom);
            } else {
              /** flag that marks the save room event listener to export images when is triggered or to ignore */
              dataRef.current.waitForSaveRoom_3D = true;
              dataRef.current.dataURL_3D = dataURL;
            }
          }

          setState({
            ...state,
            use3D: false,
            loaded3D: true,
          });
          trigger3DLoaded();
        } else {
          console.error("3D_Canvas node not found");
        }
      }, 1000);
    }
  }

  /**
   * first thing called when 3d was completely rendered
   */
  function takeSnapShot() {
    const canvasNode = document.getElementById("3D_Canvas");
    if (
      imgRef.current.tries <= maxTries &&
      canvasNode &&
      canvasNode.toDataURL
    ) {
      if (typeof window.xeoglRenderObj.zoom_camera === "function") {
        window.xeoglRenderObj.zoom_camera(-9);
      }
      const dataURL = canvasNode.toDataURL();
      setImgUrl({
        img: dataURL,
        img2: "",
        tries: imgRef.current.tries + 1,
      });
      if (
        imgRef.current.tries >= maxTries &&
        typeof window.scrollIntoView_ === "function"
      ) {
        clearTimeout(timeout_);
        timeout_ = setTimeout(() => {
          window.xeoglRenderObj?.scrollIntoView_();
          take3dScreenShot();
        }, 1000);
      }
    }
  }

  function trigger3DLoaded() {
    window.KitBuilder3D_loaded = true;
    triggerCustomEvent("kit-builder-c-e-3d-loaded", {});
  }

  function export2DFile(encodedFile, selectedRoom) {
    if (!selectedRoom) {
      selectedRoom = props.selectedRoom;
    }

    dataRef.current.waitForSaveRoom_2D = false;
    dataRef.current.dataURL_2D = ""; //free up memory

    //SAVE IMG ON SERVER
    let room_id = selectedRoom?.id;
    let room_width = selectedRoom?.room_data?.room_size?.width;
    let room_length = selectedRoom?.room_data?.room_size?.length;

    if (room_id) {
      axios
        .post(`${window.BASE_URL}rbapi/save2DFileWatermark.php`, {
          roomID: room_id,
          encodedFile: encodedFile,
          room_width: room_width,
          room_length: room_length,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if(!loaderPdfOpenRef.current.exportDone) {
            /* start loader at next render */
            loaderPdfOpenRef.current.image2DExportDone = true;
            loaderPdfOpenRef.current.exportDone = true;
            onChangePdfRef();

            setTriggerRender(Date.now());
          }
        });
    }
  }

  function export3DFile(dataURL, selectedRoom) {
    if (!selectedRoom) {
      selectedRoom = props.selectedRoom;
    }

    dataRef.current.waitForSaveRoom_3D = false;
    dataRef.current.dataURL_3D = ""; //free up memory

    //SAVE IMG ON SERVER
    let room_id = selectedRoom?.id;
    let room_width = selectedRoom?.room_data?.room_size?.width;
    let room_length = selectedRoom?.room_data?.room_size?.length;

    if (room_id && room_width && room_length) {
      room_width = parseInt(room_width) * 16.8;
      room_length = parseInt(room_length) * 16.8;

      const promises = [];
      promises.push(
        axios.post(`${window.BASE_URL}rbapi/save3DFile.php`, {
          roomID: room_id,
          room_width: room_width,
          room_length: room_length,
          encodedFile: dataURL,
        })
      );

      promises.push(
        axios.post(`${window.BASE_URL}rbapi/save3DFileWatermark.php`, {
          roomID: room_id,
          room_width: room_width,
          room_length: room_length,
          encodedFile: dataURL,
        })
      );

      // We don't need to generate this one anymore, instagram square one will be used for all sharing
      promises.push(
        axios.post(`${window.BASE_URL}rbapi/save3DFileWatermarkShare.php`, {
          roomID: room_id,
          room_width: room_width,
          room_length: room_length,
          encodedFile: dataURL,
        })
      );

      promises.push(
        axios.post(
          `${window.BASE_URL}rbapi/save3DFileWatermarkShareInstagram.php`,
          {
            roomID: room_id,
            room_width: room_width,
            room_length: room_length,
            encodedFile: dataURL,
          }
        )
      );

      Promise.allSettled(promises).then(() => {
        loaderPdfOpenRef.current.imageExportDone = true;
        onChangePdfRef();

        setTriggerRender(Date.now());
      });
    }
  }

  /**
   * 2D CANVAS FUNCTIONS
   */
  function resetZoom() {
    if (window.canvasView == 1) {
      window.xeoglRenderObj.switchCanvasTo2D();
    } else {
      window.xeoglRenderObj.setInitial3Dview();
    }
  }

  function zoomIn() {
    setCanvasConfig({
      ...canvasConfig,
      zoomKey: Date.now(),
      zoomType: "IN",
    });
    window.scene.camera.project.scale = window.scene.camera.project.scale - 2;
  }

  function zoomOut() {
    setCanvasConfig({
      ...canvasConfig,
      zoomKey: Date.now(),
      zoomType: "OUT",
    });
    window.scene.camera.project.scale = window.scene.camera.project.scale + 2;
  }

  function triggerCanvas2DExport() {
    setCanvasConfig({
      ...canvasConfig,
      zoomKey: Date.now(),
      zoomType: "EXPORT",
    });
  }

  function onClickCamera(skipLoader) {
    if (!skipLoader && !screenShotState.isFetching) {
      setScreenShotState({
        ...screenShotState,
        isFetching: true,
        triggerKey: Date.now(),
      });
    } else {
      let dataURL = "";

      const createUrl = () => {
        let canvasNode = document.getElementById("3D_Canvas");

        /**
         * these are intended to fix 3d snapshot being behind or getting a blank image
         */
        canvasNode.click();
        if (state.use3D) {
          try {
            window._camera.eye = window._camera.eye;
          } catch (err) {
            console.log(err);
          }
        }
        canvasNode.dispatchEvent(new Event("resize"));

        try {
          dataURL = canvasNode.toDataURL();
        } catch (err) {
          alert("image cross domain error: ".err?.message);
          return;
        }
      };

      createUrl();

      const formData = new FormData();
      formData.append("encodedFile", dataURL);
      formData.append("roomId", props?.selectedRoom?.id);
      axios({
        method: "post",
        url: `${window.BASE_URL}rbapi/saveScreenshot.php`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(({ data }) => {
          let cartAnchorNode = null;

          if (data?.success === 1) {
            cartAnchorNode = document.getElementById(
              "camera-screenshot-canvas"
            );

            if (!cartAnchorNode) {
              cartAnchorNode = document.createElement("a");
              cartAnchorNode.style.display = "none";
              cartAnchorNode.download = `${props?.selectedRoom?.room_name}.png`;
              cartAnchorNode.setAttribute("target", "_blank");
              document.body.appendChild(cartAnchorNode);
            }

            cartAnchorNode.setAttribute("href", `${data.image}`);

            cartAnchorNode.click();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setScreenShotState({
            ...screenShotState,
            isFetching: false,
          });
        });
    }
  }

  function onChangePdfRef() {
    if (
      loaderPdfOpenRef.current.imageExportDone &&
      loaderPdfOpenRef.current.image2DExportDone &&
      loaderPdfOpenRef.current.pdfClicked
    ) {
      pdfClick();
    }
  }

  /**
   * if room was not saved before 3D finishes rendering
   * @param {object} event
   * @param {object} event.detail -> selectedRoom
   */
  function onRoomSaveEvent(event) {
    dataRef.current.waitForSaveRoom_2D &&
      export2DFile(dataRef.current.dataURL_2D, event.detail);
    dataRef.current.waitForSaveRoom_3D &&
      export3DFile(dataRef.current.dataURL_3D, event.detail);
  }

  function onResize(event) {
    // const _newConfig = {
    //   ...stateRef.current,
    //   width: containerRef.current.clientWidth,
    //   height: containerRef.current.clientHeight,
    // };
    // setCanvasConfig(_newConfig);
  }

  React.useEffect(() => {
    window.addEventListener("resize", onResize);
    window.addEventListener("xeogl-3d-loaded-room-builder", takeSnapShot);
    window.devTrigger3DLoaded = trigger3DLoaded;
    init();

    window.addEventListener("room-was-save-successfully", onRoomSaveEvent);

    return () => {
      window.removeEventListener("xeogl-3d-loaded-room-builder", takeSnapShot);
      if (typeof window.xeoglRenderObj?.clearScene === "function") {
        window.xeoglRenderObj.clearScene();
      }
      window.KitBuilder3D_loaded = false;
      clearTimeout(timeout_);
      clearTimeout(wait2dTimeout);
      clearTimeout(ss3DTimeout);
      window.removeEventListener("resize", onResize);
      window.removeEventListener("room-was-save-successfully", onRoomSaveEvent);
    };
  }, []);

  function switchCanvasTo2D() {
    if (imgRef.current.img2) {
      return;
    }
    window.xeoglRenderObj.switchCanvasTo2D();
    setTimeout(() => {
      const canvasNode = document.getElementById("3D_Canvas");
      if (canvasNode) {
        const dataURL = canvasNode.toDataURL();

        /**
         * make sure the room was saved before any actions involving saving images
         * (as there is no room id to use)
         */
        if (props?.selectedRoom?.id) {
          export2DFile(dataURL);
        } else {
          /** is possible that  props?.selectedRoom?.id to undefined and the save room event to be triggered already, so we use this as a fallback */
          if (window.lastSavedRoom?.id) {
            export2DFile(dataURL, window.lastSavedRoom);
          } else {
            /** flag that marks the save room event listener to export images when is triggered or to ignore */
            dataRef.current.waitForSaveRoom_2D = true;
            dataRef.current.dataURL_2D = dataURL;
          }
        }
      }
    }, 200);
  }

  function switchCanvasTo3D() {
    try {
      window.xeoglRenderObj.switchCanvasTo3D();
    } catch (err) {
      console.log("Error while 3d switching", err);
    }
  }

  function getPdfBuildRenderStats() {
    const pdfIsBuilding =
      !loaderPdfOpenRef.current.image2DExportDone ||
      !loaderPdfOpenRef.current.imageExportDone;

    const pdfLoading = loaderPdfOpenRef.current.pdfClicked && pdfIsBuilding;

    return {
      pdfIsBuilding,
      pdfIsBuilding,
    };
  }

  function pdfClick() {
    if (containerRef.current) {
      const nodeAnchor = containerRef.current.querySelector("#pdf-anchor");
      if (nodeAnchor) {
        nodeAnchor.click();
      }
    }

    loaderPdfOpenRef.current.pdfClicked = false;
  }

  React.useEffect(() => {
    if (state.loaded3D === true) {
      if (state.use3D) {
        switchCanvasTo3D();
      } else {
        switchCanvasTo2D();
      }
    }
  }, [state.use3D, state.loaded3D]);

  React.useEffect(() => {
    if (screenShotState.triggerKey > Date.now() - 3000) {
      onClickCamera(true);
    }
  }, [screenShotState.triggerKey]);

  let tmpData = JSON.parse(window.localStorage.getItem("persist:room-build-v1"));
  let newTmpData = JSON.parse(tmpData.user);
  let theUserEmail = newTmpData.email;

  const renderDebugStats = () => {
    if (process.env.REACT_APP_GL_STATS) {
      return <XeoGLStats/>;
    }

    return null;
  };

  return (
    <>
      <div className="canvas-3d-rm-">
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <canvas
            id="3D_Canvas"
            style={{
              width: "100%",
              height: "100%",
              minWidth: "300px",
              backgroundColor: "white",
            }}
          ></canvas>
        </div>
      </div>
      <div className="position-absolute --left-pos --top-pos width-100 flex-all canvas-3d-r-c-switch">
        <Switch
          listValues={[
            { value: "2d", label: "2D" },
            { value: "3d", label: "3D" },
          ]}
          value={state.use3D ? "3d" : "2d"}
          onSwitch={(value, index) => {
            switchDisplays(value === "3d");
          }}
        />{" "}
      </div>
      {/*Show WebGL stats if enabled*/}
      { renderDebugStats() }
      <div
        className="layout-control canvas-3d-layout-control"
        ref={containerRef}
      >
        <div className="kit-layout-control">
          {!state.use3D ? (
            <>
              <Button
                text={<img src={plusSvg} />}
                variant="control control-sign"
                onClick={() => zoomIn()}
              />
              <Button
                text={<img src={minusSvg} />}
                variant="control control-sign"
                onClick={() => zoomOut()}
              />
            </>
          ) : (
            <></>
          )}
          <Button
            text="CENTER"
            id="center-canvas-btn-id"
            variant="control control-text"
            onClick={resetZoom}
          />

          <a
            href={`${window.BASE_URL}roombuilder_pdf/index.php?room_id=${props?.selectedRoom?.id}&email=${theUserEmail}&regenerate_pdf=${props?.selectedRoom?.regenerate_pdf}`}
            target="_blank"
            id="pdf-anchor"
            style={{ display: "none" }}
          ></a>
          <Button
            loading={loaderPdfOpenRef.current.pdfClicked}
            onClick={() => {
              const { pdfIsBuilding } = getPdfBuildRenderStats();

              if (pdfIsBuilding) {
                loaderPdfOpenRef.current.pdfClicked = true;

                /* trigger re-render */
                setTriggerRender(Date.now());
              } else {
                pdfClick();
              }
              /** active 'pdfClicked' flag */
            }}
            text="EXPORT"
            variant="control control-text"
          />

          <Button
            text={<img src={cameraSvg} />}
            variant="control control-camera"
            onClick={() => onClickCamera()}
            loading={screenShotState.isFetching}
          />

          <div id="tooltip-control-question-button">
            <Button
              text="?"
              variant="control control-question"
              onMouseOver={() => setShowControls(true)}
              onMouseOut={() => setShowControls(false)}
            />
            {showControls && (
              <div
                className="abs-tooltip-image"
                use-three-d={String(Boolean(state.use3D))}
              >
                <img src={state.use3D ? controls3d : controls2d} />
              </div>
            )}
          </div>
          <Button
            text={<img src={shareSvg} />}
            variant="control control-sign"
            className="flex-all control-3d-share-btn"
            onClick={() => props.shareRoom()}
          />
        </div>
        <div className="kit-layout-logo">
          <img src={layout_logo_new} alt="layout-logo"></img>
        </div>
        {/* {showControls && (
                    <div className="position-absolute --right-pos kit-layout-control-img">
                        <img src={controls3d} />
                    </div>
                )} */}
      </div>
    </>
  );
}
