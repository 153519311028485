import { actions } from "../reducers/layoutReducer";

export function layoutActions(dispatch) {
  return {
    openLoader: (loaderKey, payload) => {
      dispatch(
        actions.setLoader({
          loaderKey,
          loader: {
            ...(payload || {}),
            open: true,
          },
        })
      );
    },
    closeLoader: (loaderKey, payload) => {
      dispatch(
        actions.setLoader({
          loaderKey,
          loader: null,
        })
      );
    },
    /**
     *
     * @param {object} payload
     * @param {string} payload.key
     */
    pushPopupStack: (payload) => {

      // Wait .3 of a second and set focus on the primary modal button
      setTimeout(function(){
        let mainButton = document.querySelector(".btn.btn-primary");
        if(mainButton !== null) {
          mainButton.focus();
        }
      }, 300);

      dispatch(
        actions.pushPopupStack({
          popup: {
            ...payload,
          },
        })
      );
    },

    pushMultiplePopups: (popups) => {
      dispatch(
        actions.pushMultiplePopups({
          popups,
        })
      );
    },

    popPopupStack: (payload) => {
      dispatch(
        actions.popPopupStack({
          key: payload.key,
        })
      );
    },
    popPopupStackMultiple: (keys) => {
      dispatch(
        actions.popPopupStackMultiple({
          keys
        })
      );
    },
    editPopupStack: (payload) => {
      dispatch(
        actions.editPopupStack({
          ...payload,
        })
      );
    },
    getCurrentPopup: (popupStack) => {
      const entries = Object.entries(popupStack);
      let result = null;
      for (let i = 0; i < entries.length; i++) {
        const [key, content] = entries[i];
        if (
          content &&
          (!result || (content.priority && content.priority > result.priority))
        ) {
          result = content;
        }
      }
      return result;
    },
    openShareRoomPopup: (room) => {
      dispatch(
        actions.openSharePopup({
          popup: {
            key: Date.now(),
            room: JSON.parse(room),
          },
        })
      );
    },
    closeShareRoomPopup: () => {
      dispatch(
        actions.closeSharePopup({
          popup: {},
        })
      );
    },
    resetPopupState: () => {
      dispatch(actions.resetPopupState());
    },

    setKitBuilderTrigger: (kitBuilderTrigger) => {
      dispatch(
        actions.setKitBuilderTrigger({
          kitBuilderTrigger,
        })
      );
    },
    resetKitBuilderTrigger: () => {
      dispatch(actions.resetKitBuilderTrigger());
    },
  };
}
