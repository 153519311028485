// @flow
import * as React from "react";

import "./PairSelctor.scss";

/**
 * @param {object} props Component props
 * @param {number} props.selected - index
 * @param {Array} props.items
 * @param {string} props.items[].label
 * @param {function} props.onChange
 */
export default function PairSelctor(props) {
  return (
    <div className="pair-selection cursor-pointer">
      <div
        className={"pair-half" + (props.selected === 0 ? " item-selected" : "")}
        onClick={() => props.onChange(0)}
      >
        <span>{props.items?.[0]?.label}</span>
      </div>
      <div
        className={"pair-half" + (props.selected === 1 ? " item-selected" : "")}
        onClick={() => props.onChange(1)}
      >
        <span>{props.items?.[1]?.label}</span>
      </div>
    </div>
  );
}
