// @flow
import * as React from "react";

import "./NavigationMenu.scss";

export function NavigationMenu(props) {
  return (
    <div className="nav-sub-menu" grow-id={`${props.growId}`}>
      {props.steps.map((item, index) => {
        let _class = "";
        let _filledClass = "";
        if (index === props.selectedIndex) {
          _class += " is-selected";
        }
        if (index < props.selectedIndex) {
          _filledClass += " icon-filled";
        }

        return (
          <div
            key={item.key}
            className={`nav-item${_class} cursor-pointer${
              item.className || ""
            } ${props.selectedIndex > index ? " is-completed--" : ""}`}
            onClick={() => props.goToPage(item.path)}
          >
            <div className="nav-sub-item">
              <div
                className={`nav-front-icon${
                  !item.completed ? `` : ` step-completed`
                }`}
              >
                {item.icon}
              </div>
              <div className="nav-sub">
                {/* <div className="nav-step">Step {index + 1}</div> */}
                <div
                  className={`nav-label ${item.completed ? ` text-small` : ``}`}
                >
                  {item.label}
                </div>
                <div
                  className={`nav-info-label ${
                    item.completed ? ` text-small` : ``
                  }`}
                >
                  {item.info}
                  {item.extra != "" && item.extra > 0 && (
                    <sup className="info-sup">{item.extra}</sup>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
