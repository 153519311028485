export const asideKeys = {
    ROOM_AND_SYSTEM_STYLE: "ROOM_AND_SYSTEM_STYLE--CART",
    COMPONENTS: "COMPONENTS--CART",
    NUTRIENTS: "NUTRIENTS-CART",
  };
  export const iconKeys = {
    ROOM: "ROOM",
    COMPONENTS: "COMPONENTS",
    NUTRIENTS: "NUTRIENTS",
    POTASIUM: "POTASIUM",
    CALCIUM: "CALCIUM",
    BARIUM: "BARIUM"
  };