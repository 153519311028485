import React from "react";
import { Component } from "react";
import axios from "axios";
import { Button, Spinner } from "react-bootstrap";
import ButtonReact from "./../../components/Button/Button";

const POPUP_STEPS = {
  CONFIRMATION: "CONFIRMATION",
  SAVE_POPUP: "SAVE_POPUP",
};

class DuplicatePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomName: "Copy of " + this.props.roomName,
      roomDescription: this.props.roomDescription,
      room: this.props.room,
      clientID: this.props.clientID,
      errorName: false,
      loadingNutrients: false,

      isFetchingSave: false,
    };
  }

  onChangeRoomName = (event) => {
    this.setState({
      roomName: event.target.value,
    });
  };

  onChangeRoomDescription = (event) => {
    this.setState({
      roomDescription: event.target.value,
    });
  };

  onSave = () => {
    if (this.state.roomName !== "") {
      this.setState(
        {
          isFetchingSave: true,
          errorName: false,
        },
        () => {
          const newRoomData = this.state.room.room_data;
          newRoomData.room_name = this.state.roomName;

          //makes sure to duplicate
          newRoomData.room_id = "";
          newRoomData.id = "";

          newRoomData.room_description = this.state.roomDescription;

          /** injects verbose in the first record fetched back */
          const optionalParams = "&debug=true"; //return a debug object to see verbose, (inject in the first record)

          return axios
            .post(`rbapi/saveRoom.php?duplicated_from_id=${this.state.room.id}${optionalParams}`, newRoomData)
            .then((res) => {
                document.location.reload();
            })
            .catch((error) => {
              console.log(error);
              this.props.onCancel();
            });
        }
      );
    } else {
      this.setState({
        errorName: true,
      });
    }
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <div className="modal-cstm-data" type-content="duplicate-save">
        <div className="modal-cstm-title">SAVE YOUR BUILD</div>
        <div className="modal-cstm-text">
          Name and save your build to your account to allow you to access your
          build details in the future in “My Builds”.
        </div>
        <div className="modal-cstm-fields">
          <div className="input-pop-cstm">
            <input
              name="roomName"
              type="text"
              value={this.state.roomName}
              onChange={this.onChangeRoomName}
              placeholder="Enter room name.."
              error={this.state.errorName ? "yes" : "no"}
            />
          </div>
          <div className="input-pop-cstm">
            <textarea
              name="roomDescription"
              type="text"
              value={
                this.state.roomDescription !== null
                  ? this.state.roomDescription
                  : ""
              }
              onChange={this.onChangeRoomDescription}
              placeholder="Add a build description.."
            />
          </div>
        </div>
        <div className="modal-buttons">
          <Button
            variant="secondary"
            onClick={() => {
              this.props.onCancel();
            }}
          >
            CANCEL
          </Button>
          <ButtonReact
            variant="primary"
            onClick={() => {
              this.onSave();
            }}
            text="SAVE"
            loading={this.state.isFetchingSave}
          />
        </div>
      </div>
    );
  }
}

class DuplicateConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: `Duplicating this build will create an editable copy of "${this.props.roomName}"`,
    };
  }

  render() {
    return (
      <div className="modal-cstm-data" type-content="duplicate-popup-confirm">
        <div className="modal-cstm-title">{this.state.title}</div>
        <div className="modal-cstm-text">
          After duplicating you can rename or make changes to modify your build.
        </div>
        <div className="modal-buttons">
          <Button
            variant="secondary"
            onClick={() => {
              this.props.onDeny();
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              this.props.onConfirm();
            }}
          >
            {" "}
            DUPLICATE
          </Button>
        </div>
      </div>
    );
  }
}

class DuplicateRoomFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: POPUP_STEPS.CONFIRMATION,
    };
  }

  onConfirm = () => {
    this.setState({
      step: POPUP_STEPS.SAVE_POPUP,
    });
  };

  onDeny = () => {
    this.props.onClose();
  };

  render() {
    if (this.state.step === POPUP_STEPS.CONFIRMATION) {
      return (
        <DuplicateConfirm
          roomName={this.props.roomName}
          onConfirm={this.onConfirm}
          onDeny={this.onDeny}
        />
      );
    }

    return <DuplicatePopup {...this.props} onCancel={this.onDeny} />;
  }
}

export default DuplicateRoomFlow;
