// @flow
import * as React from "react";
import { Component } from "react";
import { Button } from "react-bootstrap";

import "./SaveRoomBuildPopup.scss";

import irrigationStyleImage from "./../../../../assets/image/irrigations/bubbler-flora-cap.jpg";
import buildComponentsImage from "./../../../../assets/image/builds-var-2.png";
import nutrientsImage from "./../../../../assets/image/nutrients_logo_var2.png";
import thirdPartyImage from "./../../../../assets/image/third_Party_Image.png";
import modalDefaultImage from "./../../../../assets/image/modalLogo.png";

import SaveRoomForm from "./SaveRoomForm";

import SaveRoomWarning from "./SaveRoomWarning";

const steps = {
  FORM: "FORM",
  BUILD_SAVED_STEP: "BUILD_SAVED_STEP",
  BUILD_COMPONENTS_STEP: "BUILD_COMPONENTS_STEP ",
  NUTRIENTS_STEP: "NUTRIENTS_STEP",
  THIRD_PARTY_STEP: "THIRD_PARTY_STEP",
  READY_TO_GO_STEP: "READY_TO_GO_STEP",
};

function FakeNavition(props) {
  return (
    <div className="fake-navigation">
      <div className="items-fake-navigation">
        {[0, 1, 2].map((item) => (
          <div
            key={`fake-nav-${item}`}
            className={`item-fn cursor-pointer ${
              props.value === item ? " active" : ""
            }`}
            onClick={() => props.onChangeNavigation(item)}
          ></div>
        ))}
      </div>
    </div>
  );
}

class SaveRoomBuildPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: steps.FORM,
      /**
       * @type {object}
       * @property {string} roomDetails.plantPerAreaLabel
       * @property {string} roomDetails.plantPerAreaImage
       * @property {string} roomDetails.irrigationLabel
       * @property {string} roomDetails.irrigationImage
       * @property {string} roomDetails.potLabel
       * @property {string} roomDetails.potImage
       */
      roomDetails: this.props.roomDetails,
      room_name: this.props.defaultRoomName || ""
    };

    this.isSaveWarning = this.props.isSaveWarning;
  }
  onCancel = () => {
    if (this.props.multiStep) {
      this.props.stop3DHidden();
    }
    this.props.closePopup();
  };

  goFromForm = (room_name) => {
    if (this.props.multiStep) {
      this.props.editPopup({
        nodeAttributes: { step: steps.BUILD_SAVED_STEP },
      });

      this.setState({
        step: steps.BUILD_SAVED_STEP,
        room_name,
      });
    } else {
      this.props.closePopup();
    }
  };

  goToBuildComponents = () => {
    this.props.editPopup({
      nodeAttributes: { step: "MULTI_STEP", buildcomponent: "true" },
      logo: buildComponentsImage,
    });

    this.setState({
      step: steps.BUILD_COMPONENTS_STEP,
    });
  };

  goToNutrients = () => {
    this.props.editPopup({
      nodeAttributes: { step: "MULTI_STEP", nutrient: "true" },
      logo: nutrientsImage,
    });

    this.setState({
      step: steps.NUTRIENTS_STEP,
    });
  };

  goToThirdPartyComponents = () => {
    this.props.editPopup({
      nodeAttributes: { step: "MULTI_STEP", thirdparty: "true" },
      logo: thirdPartyImage,
    });

    this.setState({
      step: steps.THIRD_PARTY_STEP,
    });
  };

  goToFinalStep = () => {
    this.props.editPopup({
      nodeAttributes: { step: steps.READY_TO_GO_STEP },
      logo: modalDefaultImage,
    });

    this.setState({
      step: steps.READY_TO_GO_STEP,
    });
  };

  goBuildIt = () => {
    this.props.goToNextPage();
    this.props.closePopup();
  };

  onChangeNavigation = (step) => {
    switch (step) {
      case 0:
        this.goToBuildComponents();
        break;
      case 1:
        this.goToNutrients();
        break;
      case 2:
        this.goToThirdPartyComponents();
        break;
    }
  };

  render() {
    return (
      <div id="save-room-popup-rm-your-build" className="build-steps-popup">
        {this.state.step === steps.FORM && (
          <SaveRoomForm
            {...this.props}
            goNext={this.goFromForm}
            onCancel={this.onCancel}
            isSaveWarning={this.isSaveWarning}
          />
        )}
        {this.state.step === steps.BUILD_SAVED_STEP && (
          <>
            <div className="modal-cstm-data">
              <div className="modal-cstm-title">BUILD SAVED</div>
              <div className="modal-cstm-text">
                This build can be accessed in “My Account” section or when you
                first login to the Room Builder.
              </div>
            </div>
            <div className="modal-cstm-build">
              <div className="build-history-items">
                <div className="build-h-item">
                  <div className="left-build-h">
                    <div className="title-dash">
                      {this.state.room_name || ""}
                    </div>
                    <div className="build-h-item-details">
                      <div className="row-build-h">
                        <div className="item-row-build-h">
                          <div className="name-item-row-build">
                            ROOM DIMENSIONS
                          </div>
                          <div
                            style={{
                              maxWidth: "200px",
                              overflow: "hidden",
                            }}
                          >
                            <div className="values-item-row-build">
                              {this.state.roomDetails.roomDimensionLabel}
                            </div>
                          </div>
                        </div>
                        <div className="item-row-build-h">
                          <div className="name-item-row-build">
                            TOTAL PLANTS
                          </div>
                          <div className="values-item-row-build">
                            {this.state.roomDetails.growAreaId === 1
                              ? this.state.roomDetails.totalPlantFlow2
                              : this.state.roomDetails.totalPlants}
                          </div>
                        </div>
                        <div className="item-row-build-h">
                          <div className="name-item-row-build">
                            {this.state.roomDetails.growAreaId === 1
                              ? "ROWS"
                              : "TRAY SIZE(S)"}
                          </div>
                          <div className="values-item-row-build">
                            {this.state.roomDetails.growAreaId !== 1 && (
                              <>
                                {" "}
                                <span className="counter-trays">
                                  {this.state.roomDetails.trayListCount}
                                </span>
                                <div className="value-trays">
                                  {this.state.roomDetails.trayListLabel}
                                </div>
                              </>
                            )}
                            {this.state.roomDetails.growAreaId === 1 && (
                              <>
                                {" "}
                                <div className="value-trays">
                                  {
                                    this.state.roomDetails.platformData
                                      .platformNumRows
                                  }
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row-build-h">
                        <div className="item-row-build-h">
                          <div className="name-item-row-build">POT/MEDIUM</div>
                          <div className="values-item-row-build">
                            {this.state.roomDetails.potLabel}
                          </div>
                          <div className="image-item-row-build">
                            {this.state.roomDetails.potImage && (
                              <img
                                src={this.state.roomDetails.potImage}
                                alt={this.state.roomDetails.potLabel}
                              />
                            )}
                          </div>
                        </div>
                        <div className="item-row-build-h">
                          <div className="name-item-row-build">
                            {this.state.roomDetails.growAreaId === 1
                              ? "POTPRO LINK LENGTH"
                              : "IRRIGATION STYLE"}
                          </div>
                          <div
                            style={{
                              maxWidth: "222px",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              className="values-item-row-build"
                              style={{
                                display: "block",
                              }}
                            >
                              {this.state.roomDetails.growAreaId === 1
                                ? this.state.roomDetails.platformData
                                    .platformLinkLength
                                : this.state.roomDetails.irrigationLabel}
                            </div>
                          </div>
                          <div className="image-item-row-build">
                            {this.props.roomDetails.growAreaId !== 1 && (
                              <img
                                src={
                                  this.state.roomDetails.irrigationImage ||
                                  irrigationStyleImage
                                }
                                alt={this.state.roomDetails.irrigationLabel}
                              />
                            )}
                          </div>
                        </div>
                        <div className="item-row-build-h">
                          <div className="name-item-row-build">
                            {this.state.roomDetails.growAreaId === 1
                              ? "SUB-ZONE WIDTH"
                              : "PLANTS PER 4X4"}
                          </div>
                          <div className="values-item-row-build">
                            {this.state.roomDetails.growAreaId === 1
                              ? this.state.roomDetails.platformData
                                  .platformSubzoneWidth
                              : this.state.roomDetails.plantPerAreaLabel}
                          </div>
                          <div className="image-item-row-build">
                            {this.props.roomDetails.growAreaId !== 1 && (
                              <img
                                src={this.state.roomDetails.plantPerAreaImage}
                                alt={this.state.roomDetails.plantPerAreaLabel}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-buttons-save-room">
              <Button variant="secondary" onClick={this.onCancel}>
                CANCEL
              </Button>
              <Button variant="primary" onClick={this.goToBuildComponents}>
                {"NEXT"}
              </Button>
            </div>
          </>
        )}
        {this.state.step === steps.BUILD_COMPONENTS_STEP && (
          <>
            <div className="modal-cstm-data">
              <div className="modal-cstm-title">BUILD COMPONENTS</div>
              <div className="modal-cstm-text">
                <span>
                  Your Build Components are all the FloraFlex
                  <sup>®</sup> products <br></br> you will need for your
                  tailored build.
                </span>
                <span>
                  When your build is complete you can expand the details tab to
                  view the individual items which will be added to your cart at
                  checkout.
                </span>
              </div>
            </div>
            <FakeNavition
              value={0}
              onChangeNavigation={this.onChangeNavigation}
            />
            <div className="modal-buttons-save-room">
              <Button variant="secondary" onClick={this.props.closePopup}>
                CANCEL
              </Button>
              <Button variant="primary" onClick={this.goToNutrients}>
                {"NEXT"}
              </Button>
            </div>
          </>
        )}
        {this.state.step === steps.NUTRIENTS_STEP && (
          <>
            <div className="modal-cstm-data">
              <div className="modal-cstm-title">
                FLORAFLEX<sup>®</sup> NUTRIENTS
              </div>
              <div className="modal-cstm-text">
                <span>
                  Your FloraFlex<sup>®</sup> Nutrients are calculated using the
                  FullTilt™ Schedule.
                </span>
                <span>
                  When your build is complete you can expand the details tab to
                  view the individual items which will be added to your cart at
                  checkout and download your tailored FullTilt™ Schedule.
                </span>
              </div>
            </div>
            <FakeNavition
              value={1}
              onChangeNavigation={this.onChangeNavigation}
            />
            <div className="modal-buttons-save-room">
              <Button variant="secondary" onClick={this.props.closePopup}>
                CANCEL
              </Button>
              <Button variant="primary" onClick={this.goToThirdPartyComponents}>
                {"NEXT"}
              </Button>
            </div>
          </>
        )}
        {this.state.step === steps.THIRD_PARTY_STEP && (
          <>
            <div className="modal-cstm-data">
              <div className="modal-cstm-title">3RD PARTY PARTS</div>
              <div className="modal-cstm-text">
                <span
                  /**
                   * make 2 rows fit with the current padding and available space
                   */
                  style={{
                    position: "relative",
                    width: "calc(100% + 20px)",
                    right: "10px",
                  }}
                >
                  3rd Party Parts are the final pieces FloraFlex<sup>®</sup>{" "}
                  recommends for a complete build. They are not available for
                  purchase at FloraFlex.com but are crucial to a<br></br> fully
                  automated grow.
                </span>
                <span>
                  Expand the details tab to view these items’ specifications and
                  for an option to be redirected to a 3rd party site for
                  purchase.
                </span>
                <span className="bolded">
                  *Purchase source for these, or similar rated parts, is solely
                  your discretion.
                </span>
              </div>
            </div>
            <FakeNavition
              value={2}
              onChangeNavigation={this.onChangeNavigation}
            />
            <div className="modal-buttons-save-room">
              <Button variant="secondary" onClick={this.props.closePopup}>
                CANCEL
              </Button>
              <Button variant="primary" onClick={this.goToFinalStep}>
                {"NEXT"}
              </Button>
            </div>
          </>
        )}
        {this.state.step === steps.READY_TO_GO_STEP && (
          <>
            <div className="modal-cstm-data">
              <div className="modal-cstm-title">READY TO GO!</div>
              <div className="modal-cstm-text">
                <span>
                  Your Build Components, FloraFlex<sup>®</sup> Nutrients, and
                  recommended 3rd Party Parts have been calculated!
                </span>
                <span>
                  When you are ready to purchase your Build Components and
                  FloraFlex<sup>®</sup> Nutrients select “Checkout” to buy at
                  FloraFlex.com.
                </span>
              </div>
            </div>
            <div className="modal-buttons-save-room">
              <Button variant="primary" onClick={this.goBuildIt}>
                {"BUILD IT!"}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

class SaveRoomManager extends Component {
  constructor(props) {
    super(props);
    this.isExitBuildWarning = this.props.isExitBuildWarning;
  }

  render() {
    return this.isExitBuildWarning ? (
      <SaveRoomWarning {...this.props} />
    ) : (
      <SaveRoomBuildPopup {...this.props} />
    );
  }
}

export default SaveRoomManager;
