// @flow
import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./CardWallet.scss";
/**
 * @param {object} props Component props
 * @param {string} props.label
 * @param {function} props.handleSubmit
 * @param {string} props.logo - src
 * @param {string} props.resultValue
 * @param {Array} props.items
 * @param {string} props.buttonText
 * @param {string | undefined} props.variant - "blue" | undefined
 * @param {boolean | undefined} props.disabled
 * @param {boolean | undefined} props.isFetching
 * @param {ReactElement} props.toolTip
 * @param {string} props.productUrl
 * @param {Array} props.pricePerUnit
 * @param {function} props.onChangeCustomQuantity
 */
export function CardWallet(props) {
  const [thisId, setThisId] = React.useState("");
  React.useEffect(() => {
    setThisId(
      `id-tool-tip-${Date.now() / Math.floor(Math.random() * 1000 + 1)}`
    );
  }, []);
  const pricePerUnit =
    Array.isArray(props.pricePerUnit) && props.pricePerUnit.length === 4
      ? []
      : [0, 0, 0, 0];
  for (let i = pricePerUnit.length; i < 4; i++) {
    pricePerUnit.push(props.pricePerUnit?.[i]?.price || 0);
  }
  for (let i = 0; i < 4; i++) {
    pricePerUnit[i] = pricePerUnit[i].toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  }
  return (
    <div
      className={
        "card-wallet position-relative" +
        (props.variant ? " --card-" + props.variant : "")
      }
    >
      <div className="position-absolute absolute-top left nutrient-card-question-mark">
        <OverlayTrigger
          placement="top"
          delay={{ show: 0, hide: 50 }}
          overlay={(thisprops) => (
            <Tooltip id="button-tooltip-rom-builder-nc" {...thisprops}>
              {props.toolTip}
            </Tooltip>
          )}
        >
          <svg
            id={thisId}
            width="24"
            height="24"
            viewBox="0 0 16 16"
            className="bi bi-question-circle-fill"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"
            />
          </svg>
        </OverlayTrigger>
      </div>
      <div className="card-body">
        <div className="card-main flex-all">
          <div>
            <a
              href={`${window.BASE_URL}${
                typeof props.productUrl === "string"
                  ? props.productUrl.slice(1, props.productUrl.length)
                  : props.productUrl
              }`}
              target="_blank"
            >
              <div className="nc-wallet-img">
                <img
                  className={`card-img-top`}
                  src={props.logo}
                  alt="v1-logo"
                ></img>
              </div>
            </a>
            <div className="flex-all card-value">
              <span className="result-value">
                {(Number(props.resultValue) || 0).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })}
              </span>
              <span className="lbs-needed aligh-left">
                lbs <br></br> needed
              </span>
            </div>
          </div>
          <div className="form-row flex-1">
            <div className="flex-all flex-column">
              {props.items.map((item, index) => {
                const property = `${props.type}_${item.weight}lb`;
                return (
                  <div
                    key={`${item.key}-${index}-card-input`}
                    className="col flex-all nc-side-input-height"
                  >
                    <label htmlFor="1" className="flex-all">
                      <span>
                        {" "}
                        {(Number(item.weight) || 0).toLocaleString(
                          "en"
                        )} lbs{" "}
                      </span>
                    </label>
                    <div className="flex-all form-control">
                      <input
                        value={item.value}
                        onWheel={(event) => event.target.blur()}
                        onBlur={() => {
                          props.onChangeCustomQuantity(
                            property,
                            Number(item.value) || undefined
                          );
                        }}
                        onChange={(event) => {
                          const value = event.target.value;
                          props.onChangeCustomQuantity(property, value);
                        }}
                        type="number"
                      />
                      {/* <span> {Number(item.value) > 0 ? item.value : "0"}</span> */}
                    </div>
                    <div className="flex-all price-unit-nc-wrapper">
                      <div className="price-unit-nc-w-label">PRICE/UNIT</div>
                      <div className="price-unit-nc-w">
                        ${pricePerUnit[index]}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
