import { actions as authActions } from "../reducers/authReducer";
import { gql } from "@apollo/client";
import cartFunctions from "../../pages/KitBuilder/KitRoom/KitCartFunctions";

import {
  getOrigin,
  redirectToLogoutController,
} from "./../../utils/utilFunctions";

const createUser = (clientData) => {
  document.cookie = "useremail=" + clientData.email + "; expires=0; path=/";
  window.isLoggedWithUserNameAndPasswordRb = true;

  return {
    token: clientData.token,
    firstname: clientData.firstname,
    lastname: clientData.lastname,
    email: clientData.email,
    clientId: clientData.clientId,
    clientCartId: '',
    cartItems: [],
  };
};

export function authenticaionActions(dispatch) {
  return {
    /**
     * login to redux -
     * NOTE: without changing to the layout from non-auth to logged in
     *      (this happens in Routes, this.props.isAuth, doesn't get true without a page reload -> this is done in order to update localStorage with the new user, but stay on login page until the redirect to magento then return to dashboard)
     * @param {object} clientData
     */
    login: (clientData) => {
      dispatch(
        authActions.login({
          user: createUser(clientData),
        })
      );

      new Promise((resolve) => {
        setTimeout(() => {
          ((token, email) => {
            /** preserve share code */
            let shareCodeGET = "";
            if (typeof window.getshared === "function" && window.getshared()) {
              shareCodeGET = `?share_code=${window.getshared()}`;
            }

            const redirect = encodeURIComponent(
              `${getOrigin()}/#/dashboard${shareCodeGET}`
            );
            window.location = `${window.BASE_URL}roombuilderconfig/login/index?token=${token}&email=${email}&redirect=${redirect}`;
          })(clientData.token, clientData.email);
        }, 200);
      });
    },

    checkToken: (client, user) => {
      return client
        .query({
          query: gql`
            {
              customer {
                id
                email
              }
            }
          `,
        })
        .catch((error) => {
          if (
            Array.isArray(error?.graphQLErrors) &&
            error.graphQLErrors.length &&
            error.graphQLErrors.some(
              (item) => item?.extensions?.category === "graphql-authorization"
            ) &&
            window.isLoggedWithUserNameAndPasswordRb !== true //avoid a unauthenticated call done too early after login
          ) {
            authenticaionActions(dispatch).logout(client);
          }
        });
    },

    logout: (client) => {
      // return client
      //   .mutate({
      //     mutation: gql`
      //       mutation {
      //         revokeCustomerToken {
      //           result
      //         }
      //       }
      //     `,
      //   })
      //   .catch((error) => {
      //     console.log("Logout error: " + error);
      //   })
      //   .finally(() => {
      //     dispatch(authActions.logout({}));
      //     redirectToLogoutController(localStorage.getItem("clientToken"));
      //     localStorage.setItem("clientToken", "");

      //     return new Promise((resolve) => resolve());
      //   });

      dispatch(authActions.logout({}));
      redirectToLogoutController(localStorage.getItem("clientToken"));
      localStorage.setItem("clientToken", "");

      return new Promise((resolve) => resolve());
    },

    setClientDetails: (clientDetails, client) => {
      dispatch(
        authActions.setClientDetails({
          clientDetails,
          cartItems: null,
        })
      );
    },

    setClientCart: (clientCartId, cartItems) => {
      dispatch(
        authActions.setClientCart({
          clientCartId,
          cartItems,
        })
      );
    },
  };
}

export const getCustomerDetails = (apolloClient) => {
  return apolloClient
    .query({
      query: gql`
        {
          customer {
            id
            firstname
            lastname
            email
          }
        }
      `,
    })
    .then((result) => {
      const customer = result?.data?.customer;
      if (customer && typeof customer === "object") {
        return new Promise((resolve) => resolve(customer));
      }
      throw { customer };
    });
};

export const setClientCartDetails = (dispatch, apolloClient) => {
  return cartFunctions(apolloClient)
    .getClientCart()
    .then((resultCart) => {
      const cartItems = resultCart?.data?.customerCart?.items || [];
      const clientCartId = resultCart?.data?.customerCart?.id || "";

      authenticaionActions(dispatch).setClientCart(clientCartId, cartItems);

      return new Promise((resolve) => resolve());
    });
};

export const logUserWithToken = (dispatch, props) =>
  /**
   * logs user with new token
   * @param {*} token
   * @param {*} currentUser - may be undefined (if no one is logged in), used to skip actions if the new token is own by the current user
   * @param {*} apolloClient
   * @returns
   */
  (authToken, currentUser, originalUrlRequested, apolloClient) => {
    const currentToken = currentUser?.token;
    const currentEmail = currentUser?.email;

    /** refresh token in graphQL */
    localStorage.setItem("clientToken", authToken);
    props.refreshToken();

    return getCustomerDetails(apolloClient).then((customer) => {
      /**
       * REFRESH page if another client was logged (this updates the cart items and does all the usual logic of logging in)
       *
       * don't go forward if ONLY the token needs update (is the same user)
       * To check for that:
       *  - token can be null, so the email and other data may be preserved after a logout (because we don't have to empty those right away (because of the need to redirect to magento without a ui change))
       *  - if token is not null, than we only
       * */
      if (!currentToken || customer.email !== currentEmail) {
        /** update redux then refresh */
        dispatch(
          authActions.login({
            user: createUser({
              ...customer,
              clientId: customer.id,
              token: localStorage.getItem("clientToken"),
            }),
          })
        );

        /** this handles eventual delays of redux updating the localStorage */
        let intervalNumber = 0;
        let tries = 0;
        intervalNumber = setInterval(() => {
          if (tries > 800) {
            console.log("exit interval - reload");
            clearInterval(intervalNumber);
            return;
          }
          tries++;

          let objSaved;
          try {
            objSaved = JSON.parse(
              localStorage.getItem("persist:room-build-v1")
            );

            if (typeof objSaved?.user === "string") {
              objSaved = JSON.parse(objSaved.user);
            } else {
              objSaved = objSaved.user;
            }

            if (objSaved?.token) {
              clearInterval(intervalNumber);

              window.location.replace(originalUrlRequested);
              window.location.reload();
            }
          } catch (err) {
            console.log(err, "json-refresh");
          }
        }, 50);
      } else {
        /** refresh token and client in redux */
        dispatch(
          authActions.loginNoDelay({
            user: createUser({
              ...customer,
              clientId: customer.id,
              token: localStorage.getItem("clientToken"),
            }),
          })
        );
      }
    });
  };
