import React, { Component } from "react";
import RoomModel from "../Models/Room";
import { Group, Line, Rect } from "react-konva";
import {
  CELL_WIDTH,
  gridMiddleX,
  gridMiddleY,
  readOnlyRoomBorderWidth,
  STROKE_WIDTH,
} from "../Constants";

import defaultImage from "./../../../../../assets/image/imgPlaceholder-3d.jpg";
import URLImage from "./URLImage";

export default class RoomReadOnly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startX: 0,
      startY: 0,
      bodyWidth: 0,
      bodyHeight: 0,
      roomGrid: [],
    };
    this.roomModel = new RoomModel({ ...this.props });
  }

  componentDidMount = () => {
    this.onRoomLengthChanged();
  };
  componentDidUpdate = (prevProps) => {
    if (
      this.props.roomWidth !== prevProps.roomWidth ||
      this.props.roomLength !== prevProps.roomLength
    ) {
      this.onRoomLengthChanged();
    }
  };
  onRoomLengthChanged = () => {
    this.setState({
      ...this.roomModel.generateRoomStart(this.props),
    });
  };

  render() {
    return (
      <Group>
        {/* grid only in interior */}
        {/* {this.state.roomGrid.map((item, index) => (
          <Line
            key={`-${item.start.x}-${item.start.y}-${item.end.x}-${item.end.y}${index}`}
            points={[item.start.x, item.start.y, item.end.x, item.end.y]}
            stroke="#CCCCCC"
            strokeWidth={STROKE_WIDTH}
            tension={0.5}
            lineCap="round"
            globalCompositeOperation={"source-over"}
          />
        ))} */}
        {/* BORDER RECT */}

          {/* <Rect
            width={this.state.bodyWidth + readOnlyRoomBorderWidth * 1.5}
            height={this.state.bodyHeight + readOnlyRoomBorderWidth * 1.5}
            x={this.state.startX - readOnlyRoomBorderWidth + 1}
            y={this.state.startY - readOnlyRoomBorderWidth + 1}
            strokeEnabled={true}
            strokeScaleEnabled={true}
            stroke="#54565A"
            strokeWidth={readOnlyRoomBorderWidth}
          />

        <Rect
          width={this.state.bodyWidth}
          height={this.state.bodyHeight}
          x={this.state.startX}
          y={this.state.startY}
          fill="rgba(167, 168, 169, 0.16)"
        /> */}
        {/* {this.props.trays.map((item, index) => {
          const {offsetX, offsetY} = item;
          const x = number2Digits(this.state.startX + offsetX * CELL_WIDTH);
          const y = number2Digits(this.state.startY + offsetY * CELL_WIDTH);

          return (
            <TrayCanvasReadOnly
              key={item.key || `tray-form-canvas-2d---${index}`}
              bodyWidth={item.bodyWidth}
              bodyHeight={item.bodyHeight}
              x={x}
              y={y}
              src={item.src}
              variant={item.bodyWidth > item.bodyHeight ? "right" : ""}
              label={item.label}
              subLabel={item.subLabel}
              rotate={() => this.onTrayRotation(item, index)}
              onDragStart={(event) => this.onDragStart(event, item, index)}
              onDragEnd={(event) => this.onDragEnd(event, item, index, x, y)}
              roomStartX={this.state.startX}
            />
          );
        })} */}

        <URLImage
          key={`tray-img-canvas-2d---`}
          // width={1500}
          // height={1500}
          // width={this.state.bodyWidth * 15}
          // height={this.state.bodyHeight * 15}
          src={this.props.room2dImage || defaultImage}
          x={this.state.startX}
          y={this.state.startY}
          key={this.props.room2dImage || defaultImage}
        />
        <Rect
          width={CELL_WIDTH}
          height={CELL_WIDTH}
          x={gridMiddleX}
          y={gridMiddleY}
          fill="rgba(0, 0, 0, 0.01)"
          strokeEnabled={true}
          strokeScaleEnabled={true}
          strokeWidth={STROKE_WIDTH}
        />
      </Group>
    );
  }
}
