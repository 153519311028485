//style 1
import srcTile1Long from "./../../../../assets/image/tiles/tile-1-long.png";
import srcTile1Short from "./../../../../assets/image/tiles/tile-1-short.png";
import srcFrameType1LeftTile from "./../../../../assets/image/tiles/frame/left-frame-type-1.png";

//style 2
import srcTile2Long from "./../../../../assets/image/tiles/tile-2-long.png";
import srcTile2Short from "./../../../../assets/image/tiles/tile-2-short.png";

//style 3
import srcTile3Long from "./../../../../assets/image/tiles/tile-3-long.png";
import srcTile3Short from "./../../../../assets/image/tiles/tile-3-short.png";

//style 4
import srcTile4Long from "./../../../../assets/image/tiles/tile-4-long.png";
import srcTile4Short from "./../../../../assets/image/tiles/tile-4-short.png";

//style 5
import srcTile5Short from "./../../../../assets/image/tiles/tile-5-short.png";
import srcFrameTubeStructure5 from "./../../../../assets/image/tiles/frame/tube-structure-5.png";
/**
 * 2d room template configuration - 2 rows
 */
//style 1
const s1_plantFirst = [
  {
    image: srcTile1Long,
    className: "tile-1-long",
  },
  {
    image: srcTile1Long,
    className: "tile-1-long -horizontal-flipped -tile-margin-left",
  },
  { image: srcTile1Long, className: "tile-1-long" },
  {
    image: srcTile1Long,
    className: "tile-1-long -horizontal-flipped -tile-margin-left",
  },
  { image: srcTile1Long, className: "tile-1-long" },
  { image: srcTile1Long, className: "tile-1-long -horizontal-flipped" },
];
const s1_plantSecond = [
  {
    image: srcTile1Short,
    className: "tile-1-short",
  },
  {
    image: srcTile1Short,
    className: "tile-1-short  -horizontal-flipped -tile-margin-left",
    linkageClassName: "zone-2d--tile-linkage",
  },
  { image: srcTile1Short, className: "tile-1-short" },
  {
    image: srcTile1Short,
    className: "tile-1-short -horizontal-flipped -tile-margin-left",
    linkageClassName: "zone-2d--tile-linkage",
  },
  { image: srcTile1Short, className: "tile-1-short" },
  {
    image: srcTile1Short,
    className: "tile-1-short -horizontal-flipped",
    linkageClassName: "zone-2d--tile-linkage",
  },
];
const s1_frameType1LeftTile = (
  <>
    <div className="zone-2d-under-tube-left">
      <img src={srcFrameType1LeftTile}></img>
    </div>
    <div className="zone-2d-under-tube-sub"></div>
    <div className="zone-2d-under-tube-right-exceed">
      <div
        className="zone-2d-under-tube-right-exceed-last-zone"
        style={{
          height: "243px",
        }}
      ></div>
    </div>
    <div className="zone-2d-under-tube-right-exceed-sq"></div>
  </>
);
const style1 = {
  plantArrayTemplate: [s1_plantFirst, s1_plantSecond],
  frameConfig: {
    leftTile: s1_frameType1LeftTile,
  },
  roomClassName: "--frame-type-1",
};

//style 2 - almost identical as style 1
const s2_plantFirst = s1_plantFirst.map((_thisItem) => ({
  ..._thisItem,
  image: srcTile2Long,
}));
const s2_plantSecond = s1_plantSecond.map((_thisItem) => ({
  ..._thisItem,
  image: srcTile2Short,
}));

const style2 = {
  plantArrayTemplate: [s2_plantFirst, s2_plantSecond],
  frameConfig: {
    leftTile: s1_frameType1LeftTile,
  },
  roomClassName: "--frame-type-1",
};

//style 3
const s3_plantFirst = [
  {
    image: srcTile3Short,
    className: "tile-3-short",
  },
  {
    image: srcTile3Short,
    className: "tile-3-short  -horizontal-flipped -tile-margin-left",
    linkageClassName: "zone-2d--tile-linkage",
  },
  { image: srcTile3Short, className: "tile-3-short" },
  {
    image: srcTile3Short,
    className: "tile-3-short -horizontal-flipped -tile-margin-left",
    linkageClassName: "zone-2d--tile-linkage",
  },
  { image: srcTile3Short, className: "tile-3-short" },
  {
    image: srcTile3Short,
    className: "tile-3-short -horizontal-flipped",
    linkageClassName: "zone-2d--tile-linkage",
  },
];
const s3_plantSecond = [
  {
    image: srcTile3Long,
    className: "tile-3-long",
  },
  {
    image: srcTile3Long,
    className: "tile-3-long -horizontal-flipped -tile-margin-left",
  },
  { image: srcTile3Long, className: "tile-3-long" },
  {
    image: srcTile3Long,
    className: "tile-3-long -horizontal-flipped -tile-margin-left",
  },
  { image: srcTile3Long, className: "tile-3-long" },
  { image: srcTile3Long, className: "tile-3-long -horizontal-flipped" },
];

const corner = (className = "") => {
  return (
    <div className={"green--corner" + (className ? " " + className : "")}>
      <div className="v-lower-corner">
        <div className="green-100"></div>
      </div>
      <div className="h-lower-corner">
        <div className="green-100"></div>
      </div>
    </div>
  );
};
const phalCorner = (className = "") => {
  return (
    <div className={"green--corner--phal" + (className ? " " + className : "")}>
      <div className="phal-cliped-"><div className="green-100"></div></div>
      <div className="phal-middle-"><div className="phal-phal"></div></div>
      <div className="phal-cliped-"><div className="green-100"></div></div>
    </div>
  );
};

const s3_frameType3LeftTile = (
  <>
    <div className="zone-2d-under-tube-left">
      <div className="right-exceed-last-zone">
        <div className="right-exceed-border"></div>
        <div className="position-relative all-100 top--100 flex-between">
          {corner("absolute-bottom left")}
          {corner("absolute-bottom right")}
          {corner("absolute-top left")}
          {corner("absolute-top right")}
          {phalCorner()}
          {phalCorner("phal-right")}
        </div>
      </div>
    </div>
    <div className="zone-2d-under-tube-sub"></div>
    <div className="zone-2d-under-tube-right-exceed">
      <div
        className="zone-2d-under-tube-right-exceed-last-zone"
        style={{
          height: "214px",
        }}
      ></div>
    </div>
    <div className="zone-2d-under-tube-right-exceed-link"></div>
    <div className="zone-2d-under-tube-right-exceed-sq"></div>
  </>
);

const style3 = {
  plantArrayTemplate: [s3_plantFirst, s3_plantSecond],
  frameConfig: {
    leftTile: s3_frameType3LeftTile,
  },
  roomClassName: "--frame-type-3",
};

//style 4 - almost identical as style 3
const s4_plantFirst = s3_plantFirst.map(item => ({...item, image: srcTile4Short}));
const s4_plantSecond = s3_plantSecond.map(item => ({...item, image: srcTile4Long}));
const s4_frameType3LeftTile = s3_frameType3LeftTile;
const style4 = {
  plantArrayTemplate: [s4_plantFirst, s4_plantSecond],
  frameConfig: {
    leftTile: s4_frameType3LeftTile,
  },
  roomClassName: "--frame-type-3",
};

//style 5 
const s5_plantFirst = [
  {
    image: srcTile5Short,
    className: "tile-5-short",
  },
  {
    image: srcTile5Short,
    className: "tile-5-short  -horizontal-flipped -tile-margin-left",
    linkageClassName: "zone-2d--tile-linkage",
  },
  { image: srcTile5Short, className: "tile-5-short" },
  {
    image: srcTile5Short,
    className: "tile-5-short -horizontal-flipped -tile-margin-left",
    linkageClassName: "zone-2d--tile-linkage",
  },
  { image: srcTile5Short, className: "tile-5-short" },
  {
    image: srcTile5Short,
    className: "tile-5-short -horizontal-flipped",
    
  },
];
const s5_plantSecond = [
  {
    image: srcTile5Short,
    className: "tile-3-long",
  },
  {
    image: srcTile5Short,
    className: "tile-3-long -horizontal-flipped -tile-margin-left",
  },
  { image: srcTile5Short, className: "tile-3-long" },
  {
    image: srcTile5Short,
    className: "tile-3-long -horizontal-flipped -tile-margin-left",
  },
  { image: srcTile5Short, className: "tile-3-long" },
  { image: srcTile5Short, className: "tile-3-long -horizontal-flipped" },
  
];
const s5_frameType5LeftTile = (
  <>
    <div className="zone-2d-under-tube-right-exceed">
      <div
        className="zone-2d-under-tube-right-exceed-last-zone"
        style={{
          height: "203px",
        }}
      ></div>
    </div>
  </>
);
const style5 = {
  plantArrayTemplate: [s5_plantFirst, s5_plantSecond],
  frameConfig: {
    leftTile: s5_frameType5LeftTile,
  },
  squareChild: <div className="frame-5-structure">
    <img src={srcFrameTubeStructure5} />
  </div>,
  roomClassName: "--frame-type-5",
};

export const zoneTypes = {
  style1,
  style2,
  style3,
  style4,
  style5
};
