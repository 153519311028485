// @flow
import * as React from "react";
import { Component } from "react";
import { Button } from "react-bootstrap";
import * as roomFunctions from "../KitRoom/KitRoomFunctions";
import { triggerCustomEvent } from "./../../../utils/utilFunctions";

import { ReactComponent as PotIcon } from "./../../../../assets/svg/pot.svg";

import gifLoader from "./../../../../assets/image/loader-1-pot-1-loop.gif";
import gifLoaderFrame1 from "./../../../../assets/image/loader-1-pot-1-loop-frame-1.gif";

import "./Loading3DPopup.scss";

function PlantRow(props) {
  return (
    <div
      style={props.style || {}}
      id={props.id}
      className={
        "loader-plant-row" + (props.className ? " " + props.className : "")
      }
    >
      <div className="loader-plant-row-content flex-all width-auto">
        {props.items.map((_item, index) => (
          <div
            key={`plant-rom-row-loading-item-${index}-${props.style.width}`}
            className="plant-loader-item"
            style={_item.style || {}}
          >
            <PotIcon />
          </div>
        ))}
      </div>
    </div>
  );
}

function PlantLoader(props) {
  const imgStyle = {
    width: "80.63",
    height: "70.176",
    position: "absolute",
    top: "0",
    right: "27px",
  };

  return (
    <div className="position-relative plant-loader-pot">
      <PlantRow
        key={`plant-rom-row-loading-item`}
        id="plant-percentage-element"
        style={{ width: `${props.percent}%` }}
        items={[{}, {}, {}, { style: { visibility: "hidden" } }]}
        className="position-absolute --left-pos --top-pos width-100"
      />
      <PlantRow
        key={`plant-rom-row-bg`}
        style={{ opacity: "0.5" }}
        items={[{}, {}, {}, {}]}
      />
      {/* makes the 2nd gif moment of appearance unnoticeable  */}
      <img src={gifLoaderFrame1} style={imgStyle} />

      {props.percent > 90 && <img src={gifLoader} style={imgStyle} />}
    </div>
  );
}

const FINISHING_TEXT = (
  <>
    Finalizing your tailored FloraFlex<sup>®</sup> grow{" "}
  </>
);

class Loading3DPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numOfPots: 0,
      totalPots: 0,
      displayText: "",
      percent: 0,
      estimatedTime: "Estimated time remaining: A couple of minutes..",
    };
    this.startTime = window.start3DTimestamp || Date.now();
    this.startTime = this.startTime - 30_000; //more seconds for screenshots and other post render stuff
  }

  calculatePercent = (value, valueTotal) => {
    value = Number(value) || 0;
    valueTotal = Number(valueTotal) || 0;
    let percent = roomFunctions.toTwoDecimals((value * 100) / valueTotal);
    if (percent < 0) {
      percent = 0;
    }
    if (percent > 100) {
      percent = 100;
    }

    return percent;
  };
  componentDidMount = () => {
    if (window.KitBuilder3D_loaded === true) {
      this.props.closePopup();
    } else {
      window.addEventListener(
        "kit-builder-c-e-3d-loaded",
        this.onKitBuilderLoaded
      );
      window.addEventListener(
        "kit-builder-c-e-3d-loading-info",
        this.onKitBuilderInfo
      );
      /**
       * get current values of 3d
       */
      triggerCustomEvent("kit-builder-c-e-3d-loading-info-get", {});
    }

    window.onKitBuilderInfoDirectSignal = this.onKitBuilderInfo;
  };

  componentWillUnmount = () => {
    window.removeEventListener(
      "kit-builder-c-e-3d-loaded",
      this.onKitBuilderLoaded
    );
    window.removeEventListener(
      "kit-builder-c-e-3d-loading-info",
      this.onKitBuilderInfo
    );
  };

  onKitBuilderLoaded = (event) => {
    this.props.closePopup();
  };

  getEstimatedTime = (numOfPots, totalPots) => {
    if (totalPots == 0) {
      return `Estimated time remaining: A couple of minutes..`;
    }

    if (numOfPots === totalPots || this.tarn === true) {
      this.tarn = true;
      return FINISHING_TEXT;
    }

    const now = Date.now();
    const timeLoaded = now - this.startTime;

    const timePerItem = timeLoaded / numOfPots;
    let remaining = timePerItem * (totalPots - numOfPots);
    let remainingSeconds = remaining / 1000;

    let minutes = remainingSeconds / 60;

    if (minutes > 120) {
      minutes = 120;
      remainingSeconds = 120 * 60;
    }

    if (minutes < 1) {
      if (remainingSeconds < 30) {
        const secondsFloor = Math.floor(remainingSeconds);
        if (secondsFloor <= 10) {
          // this.tarn = true;
          return FINISHING_TEXT;
        } else {
          return `Estimated time remaining: Less than ${secondsFloor} seconds..`;
        }
      } else {
        return `Estimated time remaining: Less than one minute..`;
      }
    } else {
      if (minutes > 5) {
        return `Estimated time remaining: A couple of minutes..`;
      } else {
        const minutesFloor = Math.floor(minutes);
        if (minutesFloor <= 1) {
          return `Estimated time remaining: Less than one minute..`;
        } else {
          return `Estimated time remaining: Less than ${minutesFloor} minutes..`;
        }
      }
    }
  };

  onKitBuilderInfo = (event) => {
    let detail = event?.target?.detail || event.detail;

    if (detail && typeof detail === "object") {
      if (detail.numOfPots > detail.totalPots) {
        detail.numOfPots = detail.totalPots;
      }

      this.setState({
        numOfPots: detail.numOfPots,
        totalPots: detail.totalPots,
        percent: this.calculatePercent(detail.numOfPots, detail.totalPots),
        estimatedTime: this.getEstimatedTime(
          detail.numOfPots,
          detail.totalPots
        ),
      });
    }
  };

  render() {
    return (
      <div className="wrapper-loading-3d-content-popup-rm">
        <div className="modal-cstm-data">
          <div className="modal-cstm-title">BUILDING YOUR ROOM…</div>
        </div>
        <div className="popup-room-loading-content">
          <div className="slidecontainer-rm flex-all flex-column">
            <PlantLoader
              key={this.state.percent}
              percent={this.state.percent}
            />
          </div>
          <div className="flex-all flex-column estimation-rm-3d">
            <div className="estimation-rm-3d-item">
              {this.state.estimatedTime}
            </div>
          </div>
          <div className="estimation-rm-3d-item foot-note-">
            NOTE: Browser, format, room size, and technology that you are using
            will determine the time it takes to render your build. Please hold
            tight while your build is being created.
          </div>
        </div>
        <div style={{ display: "none" }} className="modal-buttons-save-room">
          <Button variant="secondary" onClick={this.props.closePopup}>
            CANCEL
          </Button>
        </div>
      </div>
    );
  }
}
export default Loading3DPopup;
