// @flow
import * as React from "react";
import { Component } from "react";
import { Button, Spinner } from "react-bootstrap";
import DropdownItem from "../../../components/Dropdown/Dropdown";
import * as roomFunctions from "../KitRoom/KitRoomFunctions";

import "./SaveRoomBuildPopup.scss";

import { parseHtmlAndOther } from "../../../utils/utilFunctions";

import { ReactComponent as WarningIcon } from "./../../../../assets/svg/warning.svg";
import { ReactComponent as CloseIcon } from "./../../../../assets/svg/close-var-3.svg";

class SaveRoomForm extends Component {
  constructor(props) {
    super(props);

    this.fromOtherOwner = this.props.selectedRoom?.needsSaving;
    if (this.fromOtherOwner) {
      this.originalOwner = `${
        this.props.selectedRoom.original_owner_firstname || ""
      } ${this.props.selectedRoom.original_owner_lastname || ""}`;
    }

    this.state = {
      roomDescription:
        this.props.selectedRoom?.id || this.fromOtherOwner
          ? this.computeOption(this.props.selectedRoom)?.room_description
          : "",
      roomName: this.props.defaultRoomName,
      dropdownSelected: undefined,
      dropDownItems: [],
      dropdownKey: "",
      dataLoading: true,
    };

    /**
     * Joe Doe's ${room name} - if the selected room is a duplicate from another user
     */
    if (this.props.selectedRoom?.id) {
      this.intialValueInput = `${this.props.selectedRoom.room_name}` + "";
    } else {
      this.intialValueInput = `${
        this.fromOtherOwner ? this.props.selectedRoom.room_name + " - " : this.props.defaultRoomName
      }`;
    }
    this.originalRoomId = this.props.selectedRoom?.id;
    this.roomNameChanged = false;
    this.originalRoomName = this.intialValueInput;

    this.isExitBuildWarning = this.props.isExitBuildWarning;
  }
  computeOption = (item) => {
    if (item) {
      let room_data;
      if (typeof item.room_data === "string") {
        try {
          room_data = JSON.parse(item.room_data);
        } catch (err) {
          room_data = {};
        }
      } else {
        room_data = item.room_data || {};
      }
      return {
        ...item,
        ...room_data,
        value: item.id,
        label: item.room_name,
        room_id: item.id,
      };
    }
    return undefined;
  };

  componentDidMount = () => {
    const promise = Array.isArray(window.memoizedRoomList)
      ? new Promise((resolve) => resolve(window.memoizedRoomList))
      : roomFunctions.getRooms(this.props.clientId);

    this.setState(
      {
        fetchingRooms: true,
      },
      () => {
        promise
          .then((data) => {
            const dropDownItems = data.map((item) => this.computeOption(item));
            const dropdownSelected = this.props.selectedRoom.id
              ? this.computeOption(
                  dropDownItems.find(
                    (item) => item.room_id === this.props.selectedRoom.id
                  )
                )
              : undefined;
            this.setState(
              {
                dropDownItems,
                dropdownKey: `${Date.now()}-dropdown`,
                dataLoading: false,
                fetchingRooms: false,
              },
              () => {
                if (dropdownSelected) {
                  this.onSelectDropdown(dropdownSelected);
                }
              }
            );
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              dataLoading: false,
              fetchingRooms: false,
            });
          })
          .finally(() => {});
      }
    );
  };

  saveRoom = () => {
    this.setState(
      {
        dataLoading: true,
        dataLoadingSave: true,
      },
      () => {
        this.props
          .saveRoom(
            this.state.roomDescription,
            this.state.roomName,
            this.state.dropdownSelected
          )
          .catch((response) => {

            if (response?.save_status?.error === "ROOM_ALREADY_USED") {
              this.setState({
                roomAlreadyUsedAlert: true,
              });
            } else {
              this.setState({
                roomAlreadyUsedAlert: false,
              });
            }
          })
          .finally(() => {

            /** is triggered from exiting the build flow */
            if (this.isExitBuildWarning) {
              this.exited = true;
              this.props.goNext(this.state.roomName);

            }
          });

        /** is triggered from 'BUILD SYSTEM' */
        if(!this.isExitBuildWarning) {

          this.timeout = setTimeout(() => {
            this.exited = true;
            this.props.goNext(this.state.roomName);
          }, 1000);
        }
      }
    );
  };

  openWarningOverWrite = (dropdownItemOverwrite) => {
    this.setState({
      warningPopupOpen: true,
      dropdownItemOverwrite,
    });
  };

  onOverWrite = () => {
    this.setState(
      {
        dropdownSelected: this.state.dropdownItemOverwrite,
      },
      () => {
        this.saveRoom();
      }
    );
  };

  onClickSave = () => {
    // this.saveRoom();
    // return;
    let existingRoom;
    /**
     * if other room was selected from dropdown
     */
    if (this.state.dropdownSelected?.room_id !== this.originalRoomId) {
      existingRoom = this.state.dropDownItems.find(
        (item) => item.label === this.state.roomName
      );
    } else {
      /**
       * if room was changed in input and the name is different
       */
      if (
        // this.roomNameChanged &&
        // this.state.roomName !== this.originalRoomName
        1
      ) {
        existingRoom = this.state.dropDownItems.find(
          (item) => item.label === this.state.roomName
          //item.room_id !== this.props.selectedRoom?.id
        );

        if (!existingRoom) {
        }
      }
    }

    if (existingRoom) {
      this.openWarningOverWrite(existingRoom);
    } else {
      this.saveRoom();
    }
  };

  onSelectDropdown = (item, index) => {
    this.setState({
      roomDescription: item.room_description || "",
      dropdownSelected: item,
    });
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  back = () => {
    this.setState({
      warningPopupOpen: false,
    });
  };

  adjustWidth = () => {
    const node = document.querySelector(".modal-cstm-content");
    if (node) {
      node.setAttribute(
        "save-room-attr",
        String(Boolean(this.state.warningPopupOpen))
      );
    }
  };

  componentWillUnmount = () => {
    const node = document.querySelector(".modal-cstm-content");
    if (node) {
      node.removeAttribute(
        "save-room-attr",
        String(Boolean(this.state.warningPopupOpen))
      );
    }
  };

  computeUpperSideJsx = () => {
    const style = this.state.warningPopupOpen
      ? { height: "81px", visibility: "hidden" }
      : {};

    if (this.props.isExitBuildWarning) {
      return (
        <div style={style} className="modal-cstm-data">
          <div className="modal-cstm-title">SAVE YOUR BUILD</div>
          <div className="modal-cstm-text">
            Would you like to save your {this.state.roomName} build before
            exiting? Some of your changes may be lost if you do not save them.
          </div>
        </div>
      );
    }

    return (
      <div style={style} className="modal-cstm-data">
        <div className="modal-cstm-title">SAVE YOUR BUILD</div>
        <>
          {!this.fromOtherOwner && (
            <div className="modal-cstm-text">
              Name and save your build to your account to allow you to access
              your build details in the future in “My Builds”.
            </div>
          )}

          {this.fromOtherOwner && (
            <div className="original-owner-section">
              <div className="modal-cstm-text">
                Before we continue, rename this shared build.
              </div>
              <div className="latto original-build-owner">
                ORIGINAL DESIGNER: {parseHtmlAndOther(this.originalOwner)}
              </div>
              <div className="latto modal-save-note">
                NOTE: The original designer information will remain attached to
                the build. We are a community of growers that recognize the good
                work of out members.
              </div>
            </div>
          )}
        </>
      </div>
    );
  };

  /**
   * get the buttons based on the purpose of the popup (is a warning on exiting the build flow)
   * @returns {React.Element}
   */
  computeButtonsJsx = () => {
    if (this.props.isExitBuildWarning) {
      return (
        <div className="modal-buttons-save-room modal-buttons-save-room-warning">
          {" "}
          <Button
            variant="secondary"
            class
            onClick={() => this.props.onSkipSave()}
          >
            DON'T SAVE
          </Button>
          <Button
            variant="secondary"
            className="m-short-button"
            onClick={this.props.onCancel}
          >
            CANCEL
          </Button>
          <Button
            variant="primary"
            onClick={this.onClickSave}
            disabled={Boolean(
              this.state.dataLoading ||
                this.state.fetchingRooms ||
                !this.state.roomName
            )}
            className="m-short-button"
          >
            {this.state.dataLoading && this.state.dataLoadingSave && (
              <Spinner as="span" animation="border" variant="light" />
            )}
            {!this.state.dataLoadingSave && "SAVE"}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="modal-buttons-save-room">
          <Button variant="secondary" onClick={this.props.onCancel}>
            CANCEL
          </Button>

          <Button
            variant="primary"
            onClick={this.onClickSave}
            disabled={Boolean(
              this.state.dataLoading ||
                this.state.fetchingRooms ||
                !this.state.roomName
            )}
          >
            {this.state.dataLoading && this.state.dataLoadingSave && (
              <Spinner as="span" animation="border" variant="light" />
            )}
            {!this.state.dataLoadingSave && "SAVE"}
          </Button>
        </div>
      );
    }
  };

  render() {
    this.adjustWidth();

    return (
      <>
        {/* MODAL TITLE AND DESCRIPTION SECTION */}
        {this.computeUpperSideJsx()}

        {/* DROPDOWN AND DESCRIPTION INPUTS */}
        <div
          style={{
            width: "100%",
          }}
        >
          <div className="dropdown-inherit-size save-room-rm-dropdown-container">
            <DropdownItem
              items={this.state.dropDownItems}
              key={this.state.dropdownKey}
              onChange={this.onSelectDropdown}
              selected={this.state.dropdownSelected}
              intialValueInput={this.intialValueInput}
              prevent={this.prevent}
              onChangeText={() => {
                this.roomNameChanged = true;
              }}
              onChangeInput={(roomName, fromSelection) => {
                const stateLoad = {};
                // if (!fromSelection) {
                //   stateLoad.dropdownSelected = undefined;
                // }
                this.setState({
                  roomName,
                  ...stateLoad,
                });
              }}
              loading={this.state.fetchingRooms}
              noFilter={true}
            />
          </div>

          {/* DESCRIPTION TEXTAREA */}
          <div className="popup-room-description">
            <textarea
              value={this.state.roomDescription}
              onChange={(event) => {
                this.setState({
                  roomDescription: event.target.value,
                });
              }}
              type="text"
              placeholder="Add a build description..."
            />
          </div>

          {/* SAVE BUTTONS */}
          {this.computeButtonsJsx()}
        </div>

        {/* OVERWRITE OVERLAY */}
        {this.state.warningPopupOpen && (
          <div className="save-popup-warning-content">
            <div className="save-popup-warning-inner">
              <div
                className="flex-all close-btn-warning cursor-pointer"
                onClick={this.back}
              >
                <CloseIcon />
              </div>
              <div className="save-popup-warning-title">
                ARE YOU SURE YOU<br></br>WANT TO OVERWRITE
                <br></br>THIS BUILD?
              </div>
              <div className="save-popup-warning-icon">
                <WarningIcon />
              </div>
              <div className="save-popup-warning-note">
                You will overwrite your previous build.
              </div>
              <div className="modal-buttons-save-room">
                <Button variant="secondary" onClick={this.back}>
                  CANCEL
                </Button>

                <Button
                  variant="primary"
                  onClick={this.onOverWrite}
                  disabled={Boolean(
                    this.state.dataLoading || this.state.fetchingRooms
                  )}
                >
                  {this.state.dataLoading && this.state.dataLoadingSave && (
                    <Spinner as="span" animation="border" variant="light" />
                  )}
                  {!this.state.dataLoadingSave && "OVERWRITE"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default SaveRoomForm;
