// @flow
// import * as React from "react";
import Slider from "../Slider";

import "./SliderCounter.scss";

/**
 * @param {object} props Component props
 * @param {string} props.label
 * @param {string} props.className
 * @param {number} props.value
 * @param {number} props.min
 * @param {number} props.max
 * @param {string} props.step
 * @param {function} props.onChange
 */
export default function SliderCounter(props) {
  return (
    <div
      className={
        "counter-slider" + (props.className ? " " + props.className : "")
      }
    >
      <div className="slider-wrapper">
        <div className="flex-all">
          <h5 className="slider-label">{props.label}</h5>
        </div>
        <Slider
          min={props.min}
          max={props.max}
          value={props.value}
          onValueChange={(value) => {
            props.onChange(value);
          }}
          {...(props.step ? { step: props.step } : {})}
        />
      </div>
    </div>
  );
}
