import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export const actionTypes = {
  LOGIN: "LOGIN",
  LOGIN_NO_DELAY: "LOGIN_NO_DELAY",
  LOGOUT: "LOGOUT",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  SET_CLIENT_CART: "SET_CLIENT_CART",
  SET_CLIENT_DETAILS: "SET_CLIENT_DETAILS",
};
const initialAuthState = {
  user: undefined,
  delayLogin: false,
  delayLogout: false,
};
export const reducer = persistReducer(
  {
    storage: storage,
    key: "room-build-v1",
    blacklist: ["delayLogin", "delayLogout"],
  },
  (state = initialAuthState, { type, payload }) => {
    switch (type) {
      case actionTypes.LOGIN: {
        return {
          ...state,
          user: payload.user,

          /**
           * this property prevents redirecting to the logged layout components (in <Routes/>) when the user gets authenticated in redux
           * 1. user gets redirect to magento server where is logged as well with the new token
           * 2. when user gets back delayLogin get the default value of "false", because is blacklisted from localStorage
           */
          delayLogin: true,
          delayLogout: false,
        };
      }
      case actionTypes.LOGIN_NO_DELAY: {
        return {
          ...state,
          user: payload.user,
          delayLogin: false,
          delayLogout: false,
        };
      }
      case actionTypes.LOGOUT: {
        return {
          ...initialAuthState,
          user: {
            ...(state.user || {}),
            token: null,
          },
          delayLogout: true,
          delayLogin: false,
        };
      }
      case actionTypes.UPDATE_USER_INFO: {
        if (!state.user) {
          return state;
        }

        return {
          ...state,
          user: {
            ...state.user,
            ...payload,
          },
        };
      }
      case actionTypes.SET_CLIENT_CART: {
        if (!state.user) {
          return state;
        }

        return {
          ...state,
          user: {
            ...state.user,
            clientCartId: payload.clientCartId,
            cartItems: payload.cartItems,
          },
        };
      }
      case actionTypes.SET_CLIENT_DETAILS: {
        if (!state.user) {
          return state;
        }

        return {
          ...state,
          user: {
            ...state.user,
            ...payload.clientDetails,
          },
        };
      }
      default:
        return state;
    }
  }
);
export const actions = {
  loginNoDelay: (payload) => {
    return { type: actionTypes.LOGIN_NO_DELAY, payload };
  },
  login: (payload) => {
    return { type: actionTypes.LOGIN, payload };
  },
  setClientDetails: (payload) => {
    return { type: actionTypes.SET_CLIENT_DETAILS, payload };
  },
  logout: (payload) => {
    return { type: actionTypes.LOGOUT, payload: payload || {} };
  },
  set_token_firebase: (payload) => {
    return { type: actionTypes.SET_TOKEN_FIREBASE, payload };
  },
  closeAutoLogoutPopup: () => ({ type: actionTypes.CLOSE_AUTO_LOGOUT_POPUP }),
  updateUserInfo: (payload) => {
    return { type: actionTypes.UPDATE_USER_INFO, payload };
  },
  setClientCart: (payload) => {
    return { type: actionTypes.SET_CLIENT_CART, payload };
  },
};
