import React from "react";
import "./Slider.scss";

export default class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideWidth: "0%",
    };
    this.containerRef = React.createRef();
  }
  componentDidMount = () => {
    this.handleSliderChange(this.props.value)
  };

  handleOnChange = (e) => {
    let maxValInt = Math.floor(Number(this.props.max) || 0);
    maxValInt = String(maxValInt).length > 2 ? maxValInt : 22; 

    const valueNumber = Number(e.target.value) || 0;
    if (valueNumber > this.props.max) {
      return;
    }
    if(this.props.step === "1" && Math.floor(valueNumber) - valueNumber !== 0) {
      return;
    }
    this.props.onValueChange(e.target.value);
  };

  handleSliderChange = (value) => {
    value = Number(value) || 0;
    var slideWidth =
      Math.floor(
        (((Number(value) || 0) * 100) / this.props.max) * 100000000
      ) / 100000000;
    if (slideWidth < 1) {
      slideWidth = 1;
    }
    if (slideWidth > 100) {
      slideWidth = 100;
    }
    this.setState(
      {
        slideWidth: slideWidth + 1.1 + "%",
      },
      () => {}
    );
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.value !== this.props.value) {
      this.handleSliderChange(this.props.value);
    }
  };

  onBlur = () => {
    let value = Number(this.props.value) || 0;
    if(this.props.step == "1") {
      value = Math.floor(value);
    } else {
      value = Number(value.toFixed(2));
    }

    if(Number(value) !== NaN && this.props.min <= value && this.props.max >= value) {
      this.props.onValueChange(Number(value));
    } else {
      if(Number(value) > this.props.max) {
        this.props.onValueChange(this.props.max);
      } else {
        this.props.onValueChange(this.props.min);
      }
    }
  }

  render() {
    return (
      <div className="test-wrapper">
        <div className="slidecontainer flex-all">
          <div
            className="flex-start flex-1"
            style={{ paddingRight: "20px" }}
            ref={this.containerRef}
          >
            <div className="position-relative -slider-container-">
              <span
                className="slidecontainer-slide"
                style={{ width: this.state.slideWidth }}
              ></span>
              <span
                className="slidecontainer-slide-bg"
                style={{ width: "100%" }}
              ></span>
              <input
                type="range"
                step={this.props.step}
                min={this.props.min}
                max={this.props.max}
                value={this.props.value || this.props.min}
                className="position-relative slider-input-type-range"
                onChange={this.handleOnChange}
                onBlur={() => {
                  // this.onBlur();
                }}
                data-tip={this.props.value}
              />
            </div>
          </div>

          <div className="flex-end">
            <input
              type="number"
              value={this.props.value}
              onChange={this.handleOnChange}
              id="input-box"
              name="number"
              min={this.props.min}
              max={this.props.max}
              onBlur={() => {
                this.onBlur();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
