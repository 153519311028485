import React from "react";

export const authErrors = {
  INVALID_EMAIL: {
    message: {
      title: (
        <>
          INVALID
          <br />
          EMAIL
        </>
      ),
      text: "Please enter a valid email address to complete registration.",
    },
    error_code: "INVALID_EMAIL",
  },
  LOGIN_FAIL: {
    message: {
      title: (
        <>
          INCORRECT <br />
          EMAIL OR PASSWORD
        </>
      ),
      text: (
        <>
          <div className="missing-req-fields">
            We cannot find an account with the entered email or password. Please
            try again or register a new account.
          </div>
        </>
      ),
    },
    error_code: "LOGIN_FAIL",
  },
  EMPTY_PASSWORD: {
    message: {
      title: "",
      text: "Please enter a valid password.",
    },
    error_code: "EMPTY_PASSWORD",
  },
  INVALID_PHONE: {
    message: {
      title: "INVALID PHONE NUMBER",
      text:
        "Please enter a valid phone number using the format 999-999-9999 to complete registration.",
    },
    error_code: "INVALID_PHONE",
  },
  INVALID_PASSWORD: {
    message: {
      title: (
        <>
          INSUFFICIENT
          <br />
          PASSWORD
        </>
      ),
      text: (
        <>
          For the security of your account your password must contain:
          <br />
          <div className="center">
            8 characters
            <br />1 lowercase letter
            <br />1 uppercase letter
            <br />1 special character(s)
            <br />
            OR 1 number(s)
          </div>
        </>
      ),
    },
    error_code: "INVALID_PASSWORD",
  },
  PASSWORD_MATCH: {
    message: {
      title: (
        <>
          PASSWORD
          <br />
          MISMATCH
        </>
      ),
      text: (
        <>
          <div className="missing-req-fields">
            Please make sure your passwords match to complete registration.
          </div>
        </>
      ),
    },
    error_code: "PASSWORD_MATCH",
  },
  REQUIRED_FIELDS: {
    message: {
      title: "MISSING REQUIRED FIELDS",
      text: (
        <>
          <div className="missing-req-fields">
            At least one required field is empty. Please fill in all required
            fields to complete registration.
          </div>
        </>
      ),
    },
    error_code: "REQUIRED_FIELDS",
  },
};

export function phoneNumberIsValid(number) {
  return true;
  if (typeof number === "string") {
  }
  return false;
}
