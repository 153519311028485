import React, { useEffect } from "react";
import _ from "lodash";
import { Popup } from "../../../layout/components/Popup/Popup";
// reusable components import
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import arrowRight from "../../../../assets/image/arrow-right.png";
import arrowLeft from "../../../../assets/image/arrow-left.png";
import arrowUp from "../../../../assets/image/arrow-up.png";
import arrowDown from "../../../../assets/image/arrow-down.png";
import arrowRightActive from "../../../../assets/image/arrow-right-active.png";
import arrowLeftActive from "../../../../assets/image/arrow-left-active.png";
import arrowUpActive from "../../../../assets/image/arrow-up-active.png";
import arrowDownActive from "../../../../assets/image/arrow-down-active.png";
import questionCircle from "../../../../assets/image/question-mark-circle.png";
import { trayErrors } from "./KitRoomFunctions";
import { getPlatformOffset } from "../Canvas/Helpers";

import { layoutActions } from "../../../redux/actionFunctions/layoutActions";
import { useDispatch } from "react-redux";
import controls2d from "../../../../assets/image/tooltips/tooltip2D-simple.png";
// Was causing a build error
//import {math} from "../../../../../public/xeogl.module";

/**
 * @typedef {Object} IZone
 * @property {Number} bodyHeight
 * @property {Number} bodyWidth
 * @property {Boolean} boxStatus
 * @property {Number} id
 * @property {Boolean} isRotated
 * @property {String} label
 * @property {Number} lengthVal
 * @property {Number} lengthVal_RBased
 * @property {Number} offsetX
 * @property {Number} offsetY
 * @property {Boolean} physical
 * @property {String} subLabel
 * @property {Number} widthVal
 * @property {Number} widthVal_RBased
 * @property {Boolean} xPosition
 */

/**
 * @typedef {Object} IPState
 * @property {String} drainage
 * @property {Number} linkLength
 * @property {Number} platformsPerRow
 * @property {Number} rotationAngle
 * @property {Number} rows
 * @property {String} scrollable
 * @property {Number} updateFlipKey
 * @property {Number} walkwayWidth
 * @property {Number} zoneWidth
 */

const ROTATION_ANGLES = {
  right: 90,
  left: -90,
  up: 180,
  down: 0,
  none: 0,
};

let wasCentered = false;

export default function KitPlatformLayout(props) {
  const [_state, _setState] = React.useState({
    drainage: props.platformDrainageDirection,
    rows: props.platformNumRows,
    platformsPerRow: props.platformPlatformsPerRow,
    linkLength: props.platformLinkLength,
    walkwayWidth: props.platformWalkwayWidth,
    zoneWidth: props.platformSubzoneWidth,
    scrollable: null,
  });

  const [inputDisabled, setInputDisabled] = React.useState(
    !window.rbPlatformNotDisabled
  );

  const stateRef = React.createRef(_state);
  const setState = (newState) => {
    stateRef.current = { ...newState };
    _setState({
      ...stateRef.current,
    });
  };

  /**
   * @type {IPState}
   */
  const state =
    stateRef.current ||
    //add a fallback for the first render
    _state;

  const [showControls, setShowControls] = React.useState();
  const dispatch = useDispatch();
  const [popupState, setPopupState] = React.useState({});

  function submitRoomSize() {
    if (
      props.roomSize.roomWidth > 1000 &&
      props.roomSize.roomLength > 1000 &&
      props.roomSize.roomHeight > 1000
    ) {
      props.history.push({
        pathname: "/roombuilder/room/retry",
        search: props.history.location.search,
      });
    } else {
      props.history.push({
        pathname: "/roombuilder/content",
        search: props.history.location.search,
      });
    }
  }

  function changeDirectionLeft() {
    //TODO add enabling inputs on click
    setInputDisabled(false);
    if (isPossibleToRotate("left")) {
      const rotationAngle = getRotationAngle(state.drainage, "left");
      setState({
        ...state,
        drainage: "left",
        rotationAngle,
        updateFlipKey: Date.now(),
      });
    }
  }

  function changeDirectionRight() {
    //TODO add enabling inputs on click
    setInputDisabled(false);
    if (isPossibleToRotate("right")) {
      const rotationAngle = getRotationAngle(state.drainage, "right");
      setState({
        ...state,
        drainage: "right",
        rotationAngle,
        updateFlipKey: Date.now(),
      });
    }
  }

  function changeDirectionUp() {
    //TODO add enabling inputs on click
    setInputDisabled(false);
    if (isPossibleToRotate("up")) {
      const rotationAngle = getRotationAngle(state.drainage, "up");
      setState({
        ...state,
        drainage: "up",
        rotationAngle,
        updateFlipKey: Date.now(),
      });
    }
  }

  function changeDirectionDown() {
    //TODO add enabling inputs on click
    setInputDisabled(false);
    if (isPossibleToRotate("down")) {
      const rotationAngle = getRotationAngle(state.drainage, "down");
      setState({
        ...state,
        drainage: "down",
        rotationAngle,
        updateFlipKey: Date.now(),
      });
    }
  }

  function decreaseRows() {
    if (state.rows > 2 && inputDisabled === false && isPossibleToRemoveRow()) {
      setState({ ...state, rows: state.rows - 2 });
      removeRows();
    }
  }

  function increaseRows() {
    if (
      window.maxRows !== true &&
      inputDisabled === false &&
      isPossibleToAddRow()
    ) {
      setState({ ...state, rows: state.rows + 2 });
      addRows();
    }
  }

  function decreasePlatforms() {
    if (
      state.platformsPerRow > 1 &&
      inputDisabled === false &&
      isPossibleToDecreasePlatforms()
    ) {
      setState({ ...state, platformsPerRow: state.platformsPerRow - 1 });
    }
  }

  function increasePlatforms() {
    if (
      window.maxPlatforms !== true &&
      inputDisabled === false &&
      isPossibleToIncreasePlatforms()
    ) {
      setState({ ...state, platformsPerRow: state.platformsPerRow + 1 });
    }
  }

  function getPrevLinkLength() {
    return state.linkLength === 6 ? 3 : state.linkLength - 1;
  }

  function decreaseLinkLength() {
    if (isPossibleToDecreaseLinkLength()) {
      setState({ ...state, linkLength: getPrevLinkLength() });
    }
  }

  function getNextLinkLength() {
    return state.linkLength === 4 ? 4 : state.linkLength + 1;
  }

  function increaseLinkLength() {
    if (
      window.maxLinkLength !== true &&
      inputDisabled === false &&
      isPossibleToIncreaseLinkLength()
    ) {
      setState({
        ...state,
        linkLength: getNextLinkLength(),
      });
    }
  }

  function decreaseWalkwayWidth() {
    if (isPossibleToReduceWalkwayWidth()) {
      setState({
        ...state,
        walkwayWidth: state.walkwayWidth > 2 ? state.walkwayWidth - 1 : 2,
      });
    }
  }

  function increaseWalkwayWidth() {
    if (
      state.walkwayWidth !== true &&
      inputDisabled === false &&
      isPossibleToIncreaseWalkwayWidth()
    ) {
      setState({
        ...state,
        walkwayWidth: state.walkwayWidth < 4 ? state.walkwayWidth + 1 : 4,
      });
    }
  }

  function decreaseZoneWidth() {
    if (isPossibleToReduceZoneWidth()) {
      setState({
        ...state,
        zoneWidth: state.zoneWidth > 1 ? state.zoneWidth - 1 : 1,
      });
    }
  }

  function increaseZoneWidth() {
    if (
      window.zoneMaxWidth !== true &&
      inputDisabled === false &&
      isPossibleToIncreaseZoneWidth()
    ) {
      setState({
        ...state,
        zoneWidth: state.zoneWidth < 2 ? state.zoneWidth + 1 : 2,
      });
    }
  }
  React.useEffect(() => {
    if (!inputDisabled) {
      window.rbPlatformNotDisabled = true;
    }
  }, [inputDisabled]);

  React.useEffect(() => {
    props.setPlatForm({
      platformDrainageDirection: state.drainage,
      platformNumRows: state.rows,
      platformPlatformsPerRow: state.platformsPerRow,
      platformLinkLength: state.linkLength,
      platformWalkwayWidth: state.walkwayWidth,
      platformSubzoneWidth: state.zoneWidth,
    });
  }, [
    _state.drainage,
    _state.rows,
    _state.platformsPerRow,
    _state.linkLength,
    _state.walkwayWidth,
    _state.zoneWidth,
  ]);

  React.useEffect(() => {
    if (state.updateFlipKey) {
      flipPlatform(state.rotationAngle);
      recalculateZones();
    }
  }, [_state && _state.updateFlipKey]);

  React.useEffect(() => {
    recalculateZones(true);
  }, [
    _state && _state.zoneWidth,
    _state && _state.platformsPerRow,
    _state && _state.platFormSize,
    _state && _state.walkwayWidth,
    _state && _state.linkLength,
  ]);

  function isPossibleToAddRow() {
    let platform = getPlatform();

    if (!platform) {
      return true;
    }

    let newRowWidth =
      props.platformSize * 2 + state.zoneWidth + state.walkwayWidth;

    if (state.drainage === "down" || state.drainage === "up") {
      if (platform.widthVal + newRowWidth > props.roomSize.roomWidth) {
        return false;
      }
    }

    if (state.drainage === "left" || state.drainage === "right") {
      if (platform.lengthVal + newRowWidth > props.roomSize.roomLength) {
        return false;
      }
    }

    return true;
  }

  function isPossibleToRemoveRow() {
    return props.zones.filter((item) => item.label === "ROW").length > 2;
  }

  function isPossibleToIncreaseLinkLength() {
    let newLength =
      getNextLinkLength() * state.platformsPerRow + props.platformSize;

    if (state.drainage === "down" || state.drainage === "up") {
      if (newLength > props.roomSize.roomLength) {
        return false;
      }
    }

    if (state.drainage === "left" || state.drainage === "right") {
      if (newLength > props.roomSize.roomWidth) {
        return false;
      }
    }

    return state.linkLength < 4;
  }

  function getMinimLinkedValue() {
    return props.chosenPotValue === 10 ? 2 : 1;
  }

  function isPossibleToDecreaseLinkLength() {
    return state.linkLength > getMinimLinkedValue();
  }

  function isPossibleToIncreasePlatforms() {
    let newLength =
      state.linkLength * (state.platformsPerRow + 1) + props.platformSize;

    if (state.drainage === "down" || state.drainage === "up") {
      if (newLength > props.roomSize.roomLength) {
        return false;
      }
    }

    if (state.drainage === "left" || state.drainage === "right") {
      if (newLength > props.roomSize.roomWidth) {
        return false;
      }
    }

    return true;
  }

  function isPossibleToDecreasePlatforms() {
    return state.platformsPerRow > 1;
  }

  function isPossibleToRotate(direction) {
    let platform = getPlatform();
    if (!platform) {
      return true;
    }
    let angle = getRotationAngle(state.drainage, direction);

    if (angle === 0 || angle === 180 || angle === -180) {
      return true;
    }

    return (
      getPlatform().widthVal <= props.roomSize.roomLength &&
      getPlatform().lengthVal <= props.roomSize.roomWidth
    );
  }

  function isPossibleToReduceWalkwayWidth() {
    return state.walkwayWidth > 2;
  }

  /**
   * when a new row is added
   */
  function verifyPlatformOutOfRoom_numRows() {
    const pZoneIndex = props.zones.findIndex(
      (item) => item.label === "PLATFORM"
    );

    if (pZoneIndex > -1) {
      const platformXcoord = props.zones[pZoneIndex].offsetX;
      const platformYcoord = props.zones[pZoneIndex].offsetY;

      const roomWidth = props.roomSize.roomWidth;
      const roomLength = props.roomSize.roomLength;

      let platformXCoordsNew = platformXcoord;
      let platformYCoordsNew = platformYcoord;


      const rowDirIsHorizontal = ["left", "right"].indexOf(state.drainage) > -1;

      /**
       * find (depending on platform direction) the furthest down or furthest right placed row to be checked if it goes outside
       */
      const newRow2 = props.zones.reduce(
        (prev, current) => {
          if (!rowDirIsHorizontal) {
            if (prev.offsetX < current.offsetX) {
              return current;
            }
          } else {
            if (prev.offsetY < current.offsetY) {
              return current;
            }
          }

          return prev;
        },
        { offsetX: 0, offsetY: 0 }
      );

      /**
       * verify if 'platform num of rows' increase makes platform go out of the room
       * */
      const valueX = platformXcoord + newRow2.offsetX + newRow2.widthVal + 1;
      if (!rowDirIsHorizontal && valueX > roomWidth) {
        const offsetExceeded = valueX - roomWidth;
        platformXCoordsNew = platformXcoord - offsetExceeded;
      }

      const valueY = platformYcoord + newRow2.offsetY + newRow2.roomLength + 1;
      if (rowDirIsHorizontal && valueY > roomLength) {
        const offsetExceeded = valueY - roomLength;
        platformYCoordsNew = platformYcoord - offsetExceeded;
      }

      if (platformXCoordsNew < 0) {
        platformXCoordsNew = 0;
      }
      if (platformYCoordsNew < 0) {
        platformYCoordsNew = 0;
      }

      /**
       * this propagates to the platformData in 'calculateZones'
       */
      props.zones[pZoneIndex].offsetX = platformXCoordsNew;
      props.zones[pZoneIndex].offsetY = platformYCoordsNew;
    }
  }

  function verifyPlatformOutOfRoom_itemsPerRow() {
    /**
     * @type {IZone}
     */
    const aRow = props.zones.find((item) => item.label === "ROW");
    const pZoneIndex = props.zones.findIndex(
      (item) => item.label === "PLATFORM"
    );

    if (aRow && pZoneIndex > -1) {
      const platformXcoord = props.zones[pZoneIndex].offsetX;
      const platformYcoord = props.zones[pZoneIndex].offsetY;

      const roomWidth = props.roomSize.roomWidth;
      const roomLength = props.roomSize.roomLength;

      let platformXCoordsNew = platformXcoord;
      let platformYCoordsNew = platformYcoord;

      /**
       * verify if 'platform per rows' was increased and platform goes out of the room
       * */
      if (platformXcoord + aRow.widthVal + 1 > roomWidth) {
        const offsetExceeded = platformXcoord + aRow.widthVal + 1 - roomWidth;
        platformXCoordsNew = platformXcoord - offsetExceeded;
      }

      if (platformYcoord + aRow.lengthVal + 1 > roomLength) {
        const offsetExceeded = platformYcoord + aRow.lengthVal + 1 - roomLength;
        platformYCoordsNew = platformYcoord - offsetExceeded;
      }

      if (platformXCoordsNew < 0) {
        platformXCoordsNew = 0;
      }
      if (platformYCoordsNew < 0) {
        platformYCoordsNew = 0;
      }

      /**
       * this propagates to the platformData in 'calculateZones'
       */
      props.zones[pZoneIndex].offsetX = platformXCoordsNew;
      props.zones[pZoneIndex].offsetY = platformYCoordsNew;
    }
  }

  function isPossibleToIncreaseWalkwayWidth() {
    let rowCount = state.rows / 2;

    let newPlatformWidth =
      (rowCount - 1) * (state.walkwayWidth + 1) +
      rowCount * state.zoneWidth +
      state.rows * props.platformSize;
    if (state.drainage === "down" || state.drainage === "up") {
      if (newPlatformWidth > props.roomSize.roomWidth) {
        return false;
      }
    }

    if (state.drainage === "left" || state.drainage === "right") {
      if (newPlatformWidth > props.roomSize.roomLength) {
        return false;
      }
    }

    return state.walkwayWidth < 4;
  }

  function isPossibleToReduceZoneWidth() {
    return state.zoneWidth > 1;
  }

  function isPossibleToIncreaseZoneWidth() {
    let rowCount = state.rows / 2;

    let newPlatformWidth =
      (rowCount - 1) * state.walkwayWidth +
      rowCount * (state.zoneWidth + 1) +
      state.rows * props.platformSize;
    if (state.drainage === "down" || state.drainage === "up") {
      if (newPlatformWidth > props.roomSize.roomWidth) {
        return false;
      }
    }

    if (state.drainage === "left" || state.drainage === "right") {
      if (newPlatformWidth > props.roomSize.roomLength) {
        return false;
      }
    }

    return state.zoneWidth < 2;
  }

  function getCurrentRotationAngle() {
    return ROTATION_ANGLES[state.drainage];
  }

  function recalculateZones(noCentering, recalculationKey) {
    if (!props.zones.length) return;

    /**
     * this is called after a prior calculation function call (in case any new row that needs repositioning after being pushed as a template)
     */
    if (recalculationKey === "REVIEW_PLACEMENT") {
      verifyPlatformOutOfRoom_itemsPerRow();
      verifyPlatformOutOfRoom_numRows();
    }

    let platform = getPlatform();
    let walkway = getWalkway();

    if (
      state.drainage === "down" ||
      state.drainage === "up" ||
      state.drainage === "none"
    ) {
      const ROW_WIDTHVAL = props.platformSize;
      const ROW_LENGTHVAL =
        state.linkLength * state.platformsPerRow + props.platformSize;

      const getRowWidth = () => {
        return ROW_WIDTHVAL;
      };

      const getRowLength = () => {
        return ROW_LENGTHVAL;
      };

      const getRowOffset = (index) => {
        return (
          platform.offsetX +
          (getRowWidth() * 2 + (state.zoneWidth + state.walkwayWidth)) *
            (index / 2)
        );
      };

      const getSubzoneOffset = (index) => {
        return (
          platform.offsetX +
          getRowWidth() +
          (state.zoneWidth + state.walkwayWidth + getRowWidth() * 2) * index
        );
      };

      props.zones
        .filter((item) => item.label === "ROW")
        .map((row, index) => {
          if (index % 2 === 0) {
            row.offsetX = getRowOffset(index);
          } else {
            row.offsetX =
              getRowOffset(index - 1) + state.zoneWidth + getRowWidth();
          }
          row.offsetY = platform.offsetY;

          row.widthVal = getRowWidth();
          row.lengthVal = getRowLength();
        });

      props.zones
        .filter((item) => item.label === "SUBZONE")
        .map((subzone, index) => {
          subzone.offsetX = getSubzoneOffset(index);
          subzone.offsetY = platform.offsetY;

          subzone.lengthVal = getRowLength();
          subzone.widthVal = state.zoneWidth;
        });

      const rowLengthVal = props.zones.filter((item) => item.label === "ROW")[0]
        .lengthVal;
      const platformParams = props.zones
        .filter((item) => item.physical)
        .reduce(
          (acc, val) => {
            return {
              widthVal: val.widthVal + acc.widthVal,
              lengthVal: rowLengthVal,
            };
          },
          { widthVal: 0, lengthVal: 0 }
        );

      platform.widthVal =
        platformParams.widthVal +
        state.walkwayWidth *
          (props.zones.filter((item) => item.label === "ROW").length / 2 - 1);
      platform.lengthVal = platformParams.lengthVal;

      props.zones
        .filter((item) => item.label === "DRAINAGE")
        .map((drainage, index) => {
          drainage.offsetX = platform.offsetX;
          if (state.drainage === "up") {
            drainage.offsetY = platform.offsetY - 1;
          } else {
            drainage.offsetY = platform.offsetY + platform.lengthVal;
          }

          drainage.widthVal = platform.widthVal + state.walkwayWidth;
          drainage.lengthVal = 1;
        });

      /* set platform valve */
      props.zones
        .filter((item) => item.label === "FEED END/VALVE")
        .map((valve, index) => {
          valve.offsetX = platform.offsetX;
          if (state.drainage === "up") {
            valve.offsetY = platform.offsetY + platform.lengthVal;
          } else {
            valve.offsetY = platform.offsetY - 1;
          }
          valve.widthVal = platform.widthVal + state.walkwayWidth;
          valve.lengthVal = 1;
        });

      walkway.widthVal = platform.widthVal + state.walkwayWidth * 2;
      walkway.lengthVal = platform.lengthVal + state.walkwayWidth * 2;

      walkway.offsetX = platform.offsetX - state.walkwayWidth;
      walkway.offsetY = platform.offsetY - state.walkwayWidth;
    }

    if (state.drainage === "right" || state.drainage === "left") {
      const ROW_WIDTHVAL = props.platformSize;
      const ROW_LENGTHVAL =
        state.linkLength * state.platformsPerRow + props.platformSize;

      const getRowWidth = () => {
        return ROW_WIDTHVAL;
      };

      const getRowLength = () => {
        return ROW_LENGTHVAL;
      };

      const getRowOffset = (index) => {
        return (
          platform.offsetY +
          (getRowWidth() * 2 + (state.zoneWidth + state.walkwayWidth)) *
            (index / 2)
        );
      };

      const getSubzoneOffset = (index) => {
        return (
          platform.offsetY +
          getRowWidth() +
          (state.zoneWidth + state.walkwayWidth + getRowWidth() * 2) * index
        );
      };

      props.zones
        .filter((item) => item.label === "ROW")
        .map((row, index) => {
          if (index % 2 == 0) {
            row.offsetY = getRowOffset(index);
          } else {
            row.offsetY =
              getRowOffset(index - 1) + state.zoneWidth + getRowWidth();
          }
          row.offsetX = platform.offsetX;
          row.widthVal = getRowLength();
          row.lengthVal = getRowWidth();
        });

      props.zones
        .filter((item) => item.label === "SUBZONE")
        .map((subzone, index) => {
          subzone.offsetY = getSubzoneOffset(index);
          subzone.offsetX = platform.offsetX;
          subzone.widthVal = getRowLength();
          subzone.lengthVal = state.zoneWidth;
        });

      const rowWidthVal = getRowLength();
      const platformParams = props.zones
        .filter((item) => item.physical)
        .reduce(
          (acc, val) => {
            return {
              widthVal: rowWidthVal,
              lengthVal: val.lengthVal + acc.lengthVal,
            };
          },
          { widthVal: 0, lengthVal: 0 }
        );

      platform.lengthVal =
        platformParams.lengthVal +
        state.walkwayWidth *
          (props.zones.filter((item) => item.label === "ROW").length / 2 - 1);
      platform.widthVal = platformParams.widthVal;

      props.zones
        .filter((item) => item.label === "DRAINAGE")
        .map((drainage, index) => {
          if (state.drainage === "left") {
            drainage.offsetX = platform.offsetX - 1;
          } else {
            drainage.offsetX = platform.offsetX + platform.widthVal;
          }
          drainage.offsetY = platform.offsetY - state.walkwayWidth;
          drainage.widthVal = 1;
          drainage.lengthVal = platform.lengthVal + state.walkwayWidth;
        });

      props.zones
        .filter((item) => item.label === "FEED END/VALVE")
        .map((drainage, index) => {
          if (state.drainage === "left") {
            drainage.offsetX = platform.offsetX + platform.widthVal;
          } else {
            drainage.offsetX = platform.offsetX - 1;
          }
          drainage.offsetY = platform.offsetY - state.walkwayWidth;
          drainage.widthVal = 1;
          drainage.lengthVal = platform.lengthVal + state.walkwayWidth;
        });

      walkway.widthVal = platform.widthVal + state.walkwayWidth * 2;
      walkway.lengthVal = platform.lengthVal + state.walkwayWidth * 2;

      walkway.offsetX = platform.offsetX - state.walkwayWidth;
      walkway.offsetY = platform.offsetY - state.walkwayWidth;
    }

    if (noCentering !== true && wasCentered === false) {
      wasCentered = true;
      centerPlatform(
        platform.offsetX < 0 ||
          platform.offsetX + platform.widthVal > props.roomSize.roomWidth,
        platform.offsetY < 0 ||
          platform.offsetY + platform.lengthVal > props.roomSize.roomLength
      );
      recalculateZones(false, recalculationKey);
      wasCentered = false;
      return;
    }

    wasCentered = false;

    /**
     * review placement before any state change
     */
    if (!recalculationKey) {
      recalculateZones(true, "REVIEW_PLACEMENT");
      return;
    }

    props.updateZones(props.zones.slice(), {
      platformDrainageDirection: state.drainage,
      platformNumRows: state.rows,
      platformPlatformsPerRow: state.platformsPerRow,
      platformLinkLength: state.linkLength,
      platformWalkwayWidth: state.walkwayWidth,
      platformSubzoneWidth: state.zoneWidth,
    });
  }

  function getRotationAngle(oldPosition, newPosition) {
    return ROTATION_ANGLES[newPosition] - ROTATION_ANGLES[oldPosition];
  }

  function flipPlatform(angle) {
    if (angle === 0) return;

    props.zones.map((zone, index) => {
      if (angle === -90 || angle === 90 || angle === 270 || angle === -270) {
        let newWidth = zone.lengthVal;
        let newLength = zone.widthVal;

        zone.widthVal = newWidth;
        zone.lengthVal = newLength;
      }
    });

    centerPlatform(true, true);
  }

  function centerPlatform(isCenterHorizontally, isCenterVertically) {
    let platform = getPlatform();

    if (isCenterHorizontally)
      platform.offsetX = (props.roomSize.roomWidth - platform.widthVal) / 2;
    if (isCenterVertically)
      platform.offsetY = (props.roomSize.roomLength - platform.lengthVal) / 2;
  }

  function getPlatform() {
    return props.zones.filter((item) => item.label === "PLATFORM")[0];
  }

  function getWalkway() {
    return props.zones.filter((item) => item.label === "WALKWAY")[0];
  }

  function addRows() {
    let zonesLength = props.zones.length;
    let rows = props.zones.filter((item) => item.label === "ROW");

    let rowPrototype = rows[0];
    let newRow1 = { ...rowPrototype };
    newRow1.id = zonesLength;
    let newRow2 = { ...rowPrototype };
    newRow2.id = zonesLength + 1;

    let subzones = props.zones.filter((item) => item.label === "SUBZONE");
    let zonePrototype = subzones[0];
    let newZone = { ...zonePrototype };
    newZone.id = zonesLength + 2;

    props.zones.push(newRow1, newRow2, newZone);

    recalculateZones();
  }

  function removeRows() {
    props.zones.splice(-3);
    recalculateZones();
  }

  function openPopup(payload) {
    let key = Date.now();
    layoutActions(dispatch).pushPopupStack({
      key,
      title: "",
      callbackNo: (closePopup) => {
        closePopup();
      },
      ...payload,
    });
  }

  function getTooltipMargin() {
    return -state.scrollable?.scrollTop * 2 + "px";
  }

  return !props.showNode ? (
    ""
  ) : (
    <div className="step-content kit-room-content kit-room-container-medium platform-controls">
      <h1 className="step-title">
        Design Your <br /> Platform Layout
      </h1>
      <div
        className="flex-all container-or-medium-wrapper"
        ref={(ref) => (state.scrollable = ref)}
      >
        <h2 className="step-subtitle">Drainage Direction</h2>
        <p className="text-center">
          Pick the drainage direction for all of your platform rows
        </p>
        <div className="arrow-row">
          <div
            className={`arrow-button ${
              isPossibleToRotate("left") ? "" : "disabled"
            }`}
          >
            <img
              onClick={changeDirectionLeft}
              src={state.drainage == "left" ? arrowLeftActive : arrowLeft}
              alt="arrow"
              height="80px"
            />
          </div>
          <div
            className={`arrow-button ${
              isPossibleToRotate("right") ? "" : "disabled"
            }`}
          >
            <img
              onClick={changeDirectionRight}
              src={state.drainage == "right" ? arrowRightActive : arrowRight}
              alt="arrow"
              height="80px"
            />
          </div>
        </div>
        <div className="arrow-row">
          <div
            className={`arrow-button ${
              isPossibleToRotate("up") ? "" : "disabled"
            }`}
          >
            <img
              onClick={changeDirectionUp}
              src={state.drainage == "up" ? arrowUpActive : arrowUp}
              alt="arrow"
              width="80px"
            />
          </div>
          <div
            className={`arrow-button ${
              isPossibleToRotate("down") ? "" : "disabled"
            }`}
          >
            <img
              onClick={changeDirectionDown}
              src={state.drainage == "down" ? arrowDownActive : arrowDown}
              alt="arrow"
              width="80px"
            />
          </div>
        </div>
        <p className="text-center">
          Changing drainage direction will reset any values selected below.
        </p>
        <div className="divider"></div>

        <div
          className={`step-row ${
            window.maxRows || inputDisabled ? "step-row__disabled" : ""
          }`}
        >
          <div className="description-tsi">
            Rows
            <span>Even Numbers</span>
          </div>
          <div className="input-dimension-tsi has-controls">
            <div
              className={`reduce-length ${
                isPossibleToRemoveRow() ? "" : "disabled"
              }`}
              onClick={decreaseRows}
            >
              -
            </div>
            <input
              readOnly={true}
              type="text"
              name="length-tray"
              className="length-tray"
              disabled={window.maxRows || inputDisabled ? true : false}
              placeholder="2"
              value={state.rows}
            />
            <div
              className={`raise-length ${
                isPossibleToAddRow() ? "" : "disabled"
              }`}
              onClick={increaseRows}
            >
              +
            </div>
          </div>

          <div className="tooltip-control-question-button">
            <Button
              text="?"
              variant="control question-button"
              onMouseOver={() => setShowControls("tooltip_rows")}
              onMouseOut={() => setShowControls(null)}
            />
          </div>
          {showControls === "tooltip_rows" && (
            <div
              className="abs-tooltip-text"
              id="tooltip_rows"
              style={{ marginTop: getTooltipMargin() }}
            >
              This is the number of connected rows of PotPro™ Platforms. These
              must be added in pairs.
            </div>
          )}
        </div>
        <div
          className={`step-row ${
            window.maxPlatforms || inputDisabled ? "step-row__disabled" : ""
          }`}
        >
          <div className="description-tsi">Platforms Per Row</div>
          <div className="input-dimension-tsi has-controls">
            <div
              className={`reduce-length ${
                isPossibleToDecreasePlatforms() ? "" : "disabled"
              }`}
              onClick={decreasePlatforms}
            >
              -
            </div>
            <input
              readOnly={true}
              type="text"
              name="length-tray"
              className="length-tray"
              placeholder="2"
              disabled={window.maxPlatforms || inputDisabled ? true : false}
              value={state.platformsPerRow}
            />
            <div
              className={`raise-length ${
                isPossibleToIncreasePlatforms() ? "" : "disabled"
              }`}
              onClick={increasePlatforms}
            >
              +
            </div>
          </div>
          <div className="tooltip-control-question-button">
            <Button
              text="?"
              variant="control question-button"
              onMouseOver={() => setShowControls("tooltip_platforms")}
              onMouseOut={() => setShowControls(null)}
            />
          </div>
          {showControls === "tooltip_platforms" && (
            <div
              className="abs-tooltip-text"
              id="tooltip_platforms"
              style={{ marginTop: getTooltipMargin() }}
            >
              This is the number of connected PotPro™ Platforms per row. This is
              also the number of plants in each row.
            </div>
          )}
        </div>
        <div className="divider"></div>

        <div
          className={`step-row ${
            window.maxLinkLength || inputDisabled ? "step-row__disabled" : ""
          }`}
        >
          <div className="description-tsi">
            Potpro Link Length
            <span>
              {getMinimLinkedValue() <= 1 ? `1,2,3 or 4FT` : `2,3 or 4FT`}
            </span>
          </div>
          <div className="input-dimension-tsi has-controls">
            <div
              className={`reduce-length ${
                isPossibleToDecreaseLinkLength() ? "" : "disabled"
              }`}
              onClick={decreaseLinkLength}
            >
              -
            </div>
            <input
              readOnly={true}
              type="text"
              name="length-tray"
              className="length-tray"
              placeholder="1ft"
              disabled={window.maxLinkLength || inputDisabled ? true : false}
              value={state.linkLength}
            />
            <div
              className={`reduce-length ${
                isPossibleToIncreaseLinkLength() ? "" : "disabled"
              }`}
              onClick={increaseLinkLength}
            >
              +
            </div>
          </div>
          <div className="tooltip-control-question-button">
            <Button
              text="?"
              variant="control question-button"
              onMouseOver={() => setShowControls("tooltip_link_length")}
              onMouseOut={() => setShowControls(null)}
            />
          </div>
          {showControls === "tooltip_link_length" && (
            <div
              className="abs-tooltip-text"
              id="tooltip_link_length"
              style={{ marginTop: getTooltipMargin() }}
            >
              PotPro™ Links are precut lengths of 16-17mm tubing. These Links
              connect your Platforms in each row. Your selected length
              determines the distance between the connected Platforms. This is
              also the distance between individual plants.
            </div>
          )}
        </div>
        <div
          className={`step-row ${inputDisabled ? "step-row__disabled" : ""}`}
        >
          <div className="description-tsi">
            Walkway Width
            <span>2, 3 OR 4FT</span>
          </div>
          <div className="input-dimension-tsi has-controls">
            <div
              className={`reduce-length ${
                isPossibleToReduceWalkwayWidth() ? "" : "disabled"
              }`}
              onClick={decreaseWalkwayWidth}
            >
              -
            </div>
            <input
              readOnly={true}
              type="text"
              name="length-tray"
              className="length-tray"
              placeholder="2ft"
              disabled={state.walkwayWidth || inputDisabled ? true : false}
              value={state.walkwayWidth}
            />
            <div
              className={`raise-length ${
                isPossibleToIncreaseWalkwayWidth() ? "" : "disabled"
              }`}
              onClick={increaseWalkwayWidth}
            >
              +
            </div>
          </div>
          <div className="tooltip-control-question-button">
            <Button
              text="?"
              variant="control question-button"
              onMouseOver={() => setShowControls("tooltip_walkway")}
              onMouseOut={() => setShowControls(null)}
            />
          </div>
          {showControls === "tooltip_walkway" && (
            <div
              className="abs-tooltip-text"
              id="tooltip_walkway"
              style={{ marginTop: getTooltipMargin() }}
            >
              Walkway Width is the spacing between pairs of rows. This space
              allows you to fully access and service your plants. Walkway Width
              is also the space around your whole Platform Area, giving you
              space between your plants and the walls of your room.
            </div>
          )}
        </div>

        <div
          className={`step-row ${
            window.maxZoneWidth || inputDisabled ? "step-row__disabled" : ""
          }`}
        >
          <div className="description-tsi">
            Sub-Zone Width
            <span>1 or 2FT</span>
          </div>
          <div className="input-dimension-tsi has-controls">
            <div
              className={`reduce-length ${
                isPossibleToReduceZoneWidth() ? "" : "disabled"
              }`}
              onClick={decreaseZoneWidth}
            >
              -
            </div>
            <input
              readOnly={true}
              type="text"
              name="length-tray"
              className="length-tray"
              placeholder="1ft"
              disabled={window.maxZoneWidth || inputDisabled ? true : false}
              value={state.zoneWidth}
            />
            <div
              className={`raise-length ${
                isPossibleToIncreaseZoneWidth() ? "" : "disabled"
              }`}
              onClick={increaseZoneWidth}
            >
              +
            </div>
          </div>
          <div className="tooltip-control-question-button">
            <Button
              text="?"
              variant="control question-button"
              onMouseOver={() => setShowControls("tooltip_subzone")}
              onMouseOut={() => setShowControls(null)}
            />
          </div>
          {showControls === "tooltip_subzone" && (
            <div
              className="abs-tooltip-text"
              id="tooltip_subzone"
              style={{ marginTop: getTooltipMargin() }}
            >
              Sub-Zone Width is spacing inside your Sub-Zone. This is the inner
              distance between two rows.
            </div>
          )}
        </div>
      </div>

      <div className="kit-room-control">
        <Button
          text="Back"
          variant="secondary"
          onClick={() => {
            props.history.replace({
              pathname: "/roombuilder/container-or-medium",
              search: props.history.location.search,
            });
          }}
        />
        <Button
          text="Continue"
          variant="primary-new"
          onClick={() => {
            let placementWarnings = props.getPlatformPlacementWarnings();

            if (placementWarnings.length > 0) {
              props.openSpacingWarningSystem5(placementWarnings, () => {
                props.history.push({
                  pathname: "/roombuilder/zoning-style",
                  search: props.history.location.search,
                });
              });
            } else {
              props.history.push({
                pathname: "/roombuilder/zoning-style",
                search: props.history.location.search,
              });
            }
          }}
        />
      </div>
    </div>
  );
}
