// @flow
import * as React from "react";
import { CANVAS_TRIGGER_TYPES } from "../../../utils/constants";

import "./CartBoard.scss";
import Room2D from "./Room2D";

import PairSelctor from "../../../components/PairSelctor/PairSelctor";
import Room3D from "./Room3D";

let timer = 0;
let controllerInstance = null;

/**
 * @param {object} props Component props
 * @param {string | undefined} props.triggers.key
 * @param {string | undefined} props.triggers.type - from constants
 */
export default function CartBoard(props) {
  const [_canvasState, _setCanvasState] = React.useState({
    progressValue: 0,
    percentage: 0,
  });

  const [canvas2D, setConvas2d] = React.useState({
    zoomKey: "",
    zoomDir: 1
  });
  const [canvasSize, setCanvasSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const pageContainerRef = React.useRef();

  const canvasStateRef = React.useRef(_canvasState);

  const [state, setState] = React.useState({
    active3D: false,
  });

  function setCanvasState(newCanvasState) {
    _setCanvasState(newCanvasState);
    canvasStateRef.current = newCanvasState;
  }

  function zoomIn(value) {
    if(state.active3D) {
    } else {
    }
  }


  function toggleCardBoard2D() {
    setState({
      ...state,
      active3D: !state.active3D,
    });
  }

  React.useEffect(() => {
    props.setCartBoardLoading && props.setCartBoardLoading(true);
    clearTimeout(timer);
    return () => {
      clearTimeout(timer);
      props.setCartBoardLoading && props.setCartBoardLoading(false);
    };
  }, []);

  React.useEffect(() => {
    if (props.trigger.key && Date.now() < props.trigger.key + 500) {
      switch (props.trigger.type) {
        case CANVAS_TRIGGER_TYPES.ZOOM_IN:
          zoomIn(100);
          break;
        case CANVAS_TRIGGER_TYPES.ZOOM_OUT:
          zoomIn(-100);
          break;
      }
    }
  }, [props.trigger]);

  React.useEffect(() => {
    if (_canvasState.percentage === 1) {
      props.setCartBoardLoading &&  props.setCartBoardLoading(false);
    } else {
      if (!props.isLoading) {
        props.setCartBoardLoading &&  props.setCartBoardLoading(true);
      }
    }
  }, [_canvasState.percentage]);

  function updateSize() {
    setCanvasSize({
      width: pageContainerRef.current ? pageContainerRef.current.clientWidth - 155 : 200,
      height: pageContainerRef.current ? pageContainerRef.current.clientHeight - 155  : 200,
    })
  }
  React.useEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    }
  }, [])

  return (
    <div className="cart-board is-cart-page" ref={pageContainerRef}>
      <div className="room-switcher">
        <PairSelctor
          onChange={toggleCardBoard2D}
          items={[{ label: "2D" }, { label: "3D" }]}
          selected={Number(state.active3D)}
        />
      </div>
      <div
        className="position-relative"
        style={state.active3D ? {} : { display: "none" }}
      >
        <Room3D />
      </div>
      <div
        className="cart-board-2d"
        style={!state.active3D ? {} : { display: "none" }}
      >
        <Room2D {...props.config2D} key={props.room2dKey}/>
      </div>
    </div>
  );
}
