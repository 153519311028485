import React, { useEffect } from "react";
import Button from "../../../components/Button/Button";
import RoomLayout from "./KitRoomLayout";
import axios from "axios";

import * as roomFunctions from "./KitRoomFunctions";
import DropdownItem from "../../../components/Dropdown/Dropdown";
import { Spinner } from "react-bootstrap";

// 6" PotPro Pot -  .5 Gallon
// 8" PotPro Pot - .9 Gallon
// 4" Rockwool - .21 Gallon
// 6" Rockwool - .89 Gallon
// 8" Rockwool - 2.11 Gallon

export default function KitContainer(props) {
  const [state, setState] = React.useState({
    listContainers: [],
    listGenericContainer: [],
  });

  const [dropdownKey, setDropdownKey] = React.useState("");
  /**
   * used for triggering an action after loader was rendered (to avoid setLoader off render trigger before setLoader on in cas request is faster than render )
   */
  const [loaderState, setLoaderState] = React.useState({
    key: undefined,
    action: undefined,
    callback: undefined,
  });

  function getData() {
    if (loaderState.open && loaderState.action === "getData") {
      return;
    }
    setLoaderState({
      key: Date.now(),
      open: true,
      action: "getData",
      callback: () => {
        let list_containers = [];
        let listGenericContainer = [];
        let promise;
        if (
          window.kitBuilderMatrix &&
          Array.isArray(window.kitBuilderMatrix.grow_areas)
        ) {
          promise = new Promise((resolve) => {
            resolve(
              JSON.parse(
                JSON.stringify({ data: window.kitBuilderMatrix, status: 200 })
              )
            );
          });
        } else {
          promise = axios(`${window.BASE_URL}rbapi/getMatrix.php`);
        }

        promise
          .then((response) => {
            if (
              response.status == 200 &&
              response.data.grow_areas &&
              props.growArea.id !== undefined &&
              props.growArea.id !== null &&
              props.growArea.id !== "" &&
              response.data.grow_areas?.[props.growArea.id]?.containers
            ) {
              (
                response.data.grow_areas[props.growArea.id].containers || []
              ).forEach(function (data, index) {
                //TODO: add back the 10 GAL POTPRO BUCKET when it is ready for purchase
                if (data.name === '10 GAL POTPRO BUCKET') return;
                list_containers.push({
                  id: index, //
                  label: data.name || "",
                  image: data.image,
                  valueNumber: Number(data.value) || 0,
                });
              });

              (
                response.data.grow_areas[props.growArea.id]
                  .generic_containers || []
              ).forEach((data, index) => {
                const name = typeof data.name === "string" ? data.name : "";
                listGenericContainer.push({
                  id: Date.now() + " " + index,
                  index,
                  label: name,
                  value: name,
                  image: "",
                  valueNumber: Number(data.value) || 0,
                  key: Date.now() + `dropdown-g-container---${index}`,
                });
              });
            }

            setState({
              ...state,
              listContainers: list_containers,
              listGenericContainer,
            });
          })
          .finally(() => {
            setLoaderState({ open: false });
          });
      },
    });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (
      loaderState.action &&
      loaderState.key &&
      typeof loaderState.callback === "function"
    ) {
      loaderState.callback();
    }
  }, [loaderState.key]);

  function selectContainer(id) {
    props.setPot({
      ...props.chosenPot,
      ...state.listContainers[id],
      id: state.listContainers[id].id,
      label: state.listContainers[id].label,
      value: state.listContainers[id].valueNumber,
      fromGenericContainer: false,
    });

  }
  function selectGenericContainer(index) {
    props.setPot({
      ...state.listGenericContainer[index],
      id: state.listGenericContainer[index].id,
      index: state.listGenericContainer[index].index,
      label: state.listGenericContainer[index].value,
      value: state.listGenericContainer[index].valueNumber,
      fromGenericContainer: true,
    });
  }

  const errorToShow = props.getStepError();
  const continueDisabled = errorToShow != null;

  const dropdownSelected = props.chosenPot.label
    ?
      /**
       * beca
       */
      state.listGenericContainer.find(
        (item) => roomFunctions.safeToUpperCase(item.value) === roomFunctions.safeToUpperCase(props.chosenPot.label)
      )
    : undefined;

  return (
    <div className="step-content kit-room-content kit-room-container-medium">
      <h1 className="step-title">
        WHAT POT OR MEDIUM
        <br />
        ARE YOU PLANNING TO
        <br />
        GROW IN?
      </h1>
      <div className="flex-all container-or-medium-wrapper">
        {state.listContainers.map((item, index) => {
          // TODO: Remove this id selector, the data should come in clean or it should be seletced by a isPlatform marker or something
          // This if statement is a requirement for the platform demo
          return (
            <div
              key={`${index}---PLANNING TO GROW IN?-----plants-per-item`}
              className={`flex-all container-element  ${
                item.id == props.chosenPot.id ? `container-selected` : ``
              }`}
              onClick={() => selectContainer(item.id)}
            >
              <img
                className="container-image blur-item-rm-left"
                src={item.image}
                alt={item.image}
              />
              <div className="container-label">{item.label}</div>
            </div>
          );
        })}
        {Boolean(state.listGenericContainer.length) && (
          <div className="dropdown-inherit-size dropdown-container-medium">
            <div className="container-label-custom-pot">
              Non-FloraFlex<sup>&reg;</sup> Pots
            </div>
            <DropdownItem
              items={state.listGenericContainer}
              noInput={true}
              key={dropdownKey}
              onChange={(item, index) => {
                selectGenericContainer(index);
                setDropdownKey(Date.now());
              }}
              selected={
                dropdownSelected
                  ? {
                      ...dropdownSelected,
                      label: dropdownSelected.label,
                    }
                  : dropdownSelected
              }
              displayValue="- Select -"
            />
          </div>
        )}
        {loaderState.open && loaderState.action === "getData" && (
          <div className="flex-all width-100 page-spinner" style={{ flex: 1 }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>

      <div className="kit-room-control">
        <Button
          text="Back"
          variant="secondary"
          onClick={() => {
            let pathname = "";
            if (props.growArea.id == 0) {
              props.history.replace({
                pathname: "/roombuilder/tray-size",
                search: props.history.location.search,
              });
            } else {
              props.history.push({
                pathname: "/roombuilder/grow-area",
                search: props.history.location.search,
              });
            }
          }}
        />
        <Button
          text="Continue"
          className={continueDisabled ? "disabled" : ""}
          variant="primary-new"
          onClick={() => {
            if (errorToShow) {
              props.openPopup({
                ...errorToShow,
                content: errorToShow.message,
              });
              return;
            }
            if (continueDisabled) {
              return;
            }
            const executeThis = () => {
              if (props.growArea.id == 0) {
                props.history.push({
                  pathname: "/roombuilder/irrigation-style",
                  search: props.history.location.search,
                });
              } else {
                props.history.push({
                  pathname: "/roombuilder/platform-layout",
                  search: props.history.location.search,
                });
              }
            };

            const trayWarning = props.getOneOfTheTrayWarnings();
            if (trayWarning) {
              props.openSpacingWarning(
                () => {
                  executeThis();
                }
              );
            } else {
              executeThis();
            }
          }}
        />
      </div>
    </div>
  );
}
