// @flow
import * as React from "react";

import PairSelctor from "./../../../components/PairSelctor/PairSelctor";
import Input from "./../../../components/Input/Input";
import IndicatorText from "../../../components/IndicatorText/IndicatorText";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Button from "./../../../components/Button/Button";

import { onChangeWithLimits } from "./../../../utils/utilFunctions";

const pairDimension = [
  { value: "metric", label: "METRIC" },
  { value: "imperial", label: "IMPERIAL" },
];
/**
 * @param {string | number} props.roomLength
 * @param {string | number} props.zoneNumber
 * @param {string | number} props.roomWidth
 * @param {string | number} props.plantsPerZone
 * @param {string | number} props.tierPerZoneNumber
 * @param {number} props.styleSelected
 * @param {Array} props.styles
 * @param {function} props.onChangeAsideItem
 */
export default function AsideRoomStyle(props) {
  const [selected, setSelected] = React.useState(0);

  return (
    <div className="room-style">
      <div className="aside-item-title main-title">
        <span className="label-aside">ROOM DIMENSIONS: </span>
        <PairSelctor
          items={pairDimension}
          selected={selected}
          onChange={(index) => setSelected(index)}
        />
      </div>
      <div className="room-style-form">
        <div className="room-form-inner input-group">
          <div className="flex-col">
            <div className="input-group-label">
              <div className="main-label">
                <span>Room length</span>
              </div>
              <div className="sec-label">
                <span>(In feet)</span>
              </div>
            </div>
            <div className="input-group-label">
              <div className="main-label">
                <span>Room Width</span>
              </div>
              <div className="sec-label">
                <span>(In feet)</span>
              </div>
            </div>
          </div>
          <div className="flex-col padding-right">
            <Input
              type="number"
              value={props.roomLength}
              variant="outline"
              onChange={(event) => {
                onChangeWithLimits(event, (value) =>
                  props.onChangeAsideItem("data.roomLength", value)
                );
              }}
            />
            <Input
              max={5000}
              type="number"
              value={props.roomWidth}
              variant="outline"
              onChange={(event) => {
                onChangeWithLimits(event, (value) =>
                  props.onChangeAsideItem("data.roomWidth", value)
                );
              }}
            />
          </div>
          <div className="flex-col">
            <div className="input-group-label">
              <div className="main-label">
                <span>Number of zones</span>
              </div>
              <div className="sec-label">
                <span>(1-5000)</span>
              </div>
            </div>
            <div className="input-group-label">
              <div className="main-label">
                <span>Plants per zone</span>
              </div>
              <div className="sec-label">
                <span>(1-5000)</span>
              </div>
            </div>
          </div>
          <div className="flex-col padding-right">
          <Input
              min={1}
              max={5000}
              type="number"
              value={props.zoneNumber}
              variant="outline"
              onChange={(event) => {
                onChangeWithLimits(event, (value) =>
                  props.onChangeAsideItem("data.zoneNumber", value)
                );
              }}
            />
            <Input
              max={5000}
              type="number"
              value={props.plantsPerZone}
              variant="outline"
              onChange={(event) => {
                onChangeWithLimits(event, (value) =>
                  props.onChangeAsideItem("data.plantsPerZone", value)
                );
              }}
            />

          </div>
          <div className="flex-end flex-1">
            <div className="tiers-per-zone">
              <div className="main-label">Number of tiers per zone </div>
              <div className="flex-between align-start">
                <div className="flex-start flex-column">
                  <Input
                    max={5}
                    min={1}
                    type="number"
                    className="one-sized"
                    value={props.tierPerZoneNumber}
                    variant="outline"
                    onChange={(event) => {
                      onChangeWithLimits(event, (value) =>
                        props.onChangeAsideItem("data.tierPerZoneNumber", value)
                      );
                    }}
                  />
                  <div className="sec-label">(1-5)</div>
                </div>
                <div className="counter-lines">
                  {(() => {
                    const item_ = [];
                    for (let i = 0; i < props.tierPerZoneNumber; i++) {
                      item_.push("-tier-" + i);
                    }
                    return item_;
                  })().map((key, index) => {
                    return (
                      <div key={key} className="tier-line">
                        {" "}
                        <div></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-all">
        <IndicatorText label="Units default to feet for your room." />
      </div>
      <div className="aside-item-title">
        <span className="label-aside">SYSTEM STYLE: </span>
      </div>
      <div className="flex-all dropdown-room">
        <div className="single-flex-inner">
          <Dropdown
            className="variant-2"
            items={props.styles}
            selected={props.styles[props.styleSelected]}
            onChange={(item, index) => {
              props.onChangeAsideItem("data.styleSelected", index);
              props.onSetRoom2dKey()
            }}
            
          />
          <Dropdown className="variant-2" displayValue="Pot Diameter" />
        </div>
      </div>
      <div className="flex-all room-act-btns">
        <div className="single-flex-inner">
          <Button variant="outline" text="CANCEL" className="variant-1 pale" />
          <Button
            variant="primary"
            text="APPLY CHANGES"
            className="variant-1 forward success"
          />
        </div>
      </div>
    </div>
  );
}
