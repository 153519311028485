export const AUTH_POPUPS = {
  INCORRECT_EMAIL_OR_PASSWORD: {
    content: {
      title: (
        <>
          {" "}
          INVALID <br />
          EMAIL OR PASSWORD{" "}
        </>
      ),
      text: (
        <>
          <div className="missing-req-fields">
            <p>
              We cannot find an account with the entered email or password. Please
              try again or register a new account.
            </p>
            <p>
              If you recently registered a new account, please check your email to verify your account before logging in.
            </p>
          </div>
        </>
      ),
    },
  },
  NETWORK_ERROR: {
    content: {
      title: `Network Error`,
      text: (
        <>
          <div className="missing-req-fields">Failed to fetch</div>
        </>
      ),
    },
  },
  PASSWORD_ERROR: {
    content: {
      title: (
        <>
          {" "}
          INSUFFICIENT
          <br />
          PASSWORD
        </>
      ),
      text: (
        <>
          For the security of your account your password must contain:
          <br />
          <div className="center">
            8 characters
            <br />1 lowercase letter
            <br />1 uppercase letter
            <br />1 special character(s)
            <br />
            OR 1 number(s)
          </div>
        </>
      ),
    },
  },
  VERIFY_EMAIL: {
    content: {
      title: (
        <>Verify Email</>
      ),
      text: (
        <>
          <div className="missing-req-fields">
            A confirmation email was sent to your email. Please check your email to verify your registered account
            before signing in.
          </div>
        </>
      ),
    }
  },
};
