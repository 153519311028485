import React, { useEffect } from "react";
import Button from "../../../components/Button/Button";
import axios from "axios";

import * as roomFunctions from "./KitRoomFunctions";
import objectPath from "object-path";

export default function KitIrrigation(props) {
  const [state, setState] = React.useState({
    irrigationStyle: [],
  });

  useEffect(() => {
    let irrigation_style = [];

    let promise;
    if (
      window.kitBuilderMatrix &&
      Array.isArray(window.kitBuilderMatrix.grow_areas)
    ) {
      promise = new Promise((resolve) => {
        resolve(
          JSON.parse(
            JSON.stringify({ data: window.kitBuilderMatrix, status: 200 })
          )
        );
      });
    } else {
      promise = axios(`${window.BASE_URL}rbapi/getMatrix.php`);
    }

    promise
      .then((response) => {
        if (response.status == 200) {
          // if (responseIrrigation && typeof responseIrrigation === "object") {

          const data = response.data;

          if (
            props.chosenPot?.fromGenericContainer &&
            Array.isArray(
              data?.grow_areas?.[props.growArea.id]?.generic_containers
            )
          ) {
            /**
             * safely get the array of object
             */
            let pathToIrrigation = "";
            const indexGenContainer = roomFunctions.getGenContainerIndex(props, response);

            pathToIrrigation = `data.grow_areas.${props.growArea.id}.generic_containers.${indexGenContainer}`;

            let generic_containers_irrigation = objectPath.get(
              response,
              pathToIrrigation
            );

            // if(!generic_containers_irrigation) {
            //   let genericContainers = objectPath.get(data, `data.grow_areas.${props.growArea.id}.generic_containers`);
            //   if(Array.isArray(genericContainers)) {
            //     generic_containers_irrigation = genericContainers.find(item => item.)
            //   }
            // }

            if (generic_containers_irrigation) {
              for (
                let i = 0;
                i < generic_containers_irrigation.irrigation.length;
                i++
              ) {
                const item = generic_containers_irrigation.irrigation[i];
                irrigation_style.push({
                  type: "generic",
                  id: `generic-${Date.now()}`,
                  index: i,
                  label: item.name,
                  image: item.image,
                  type: "generic-containers",
                });
              }
            }
          } else {
            if (
              props.growArea.id !== undefined &&
              props.growArea.id !== null &&
              props.growArea.id !== "" &&
              props.chosenPot.id !== undefined &&
              props.chosenPot.id !== null &&
              props.chosenPot.id !== "" &&
              data.grow_areas &&
              data.grow_areas[props.growArea.id].containers &&
              data.grow_areas[props.growArea.id]?.containers?.[
                props.chosenPot.id
              ]?.irrigation
            ) {
              data.grow_areas[props.growArea.id].containers[
                props.chosenPot.id
              ].irrigation.forEach(function (data, index) {
                irrigation_style.push({
                  id: index,
                  label: data.name,
                  image: data.image,
                });
              });
            }
          }
        }

        setState({
          ...state,
          irrigationStyle: irrigation_style,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function selectItem(item) {
    props.setStyle({
      ...props.style,
      id: item.id,
      index: item.index, //for chosen pot generic containers
      image: item.image,
      label: item.label,
      type: item.type,
    });
  }

  const errorToShow = props.getStepError();
  const continueDisabled = errorToShow != null;

  return (
    <div className="step-content kit-room-content">
      <h1 className="step-title">
        What Irrigation <br />
        Style would you <br />
        like to use?
      </h1>
      <div className="inside-left-content">
        <div className="listing-elements irrigation-elements">
          {state.irrigationStyle.map((item, index) => {
            // Replace reg symbol in label text with superscript reg symbol
            const content = item.label.replace("®", "<sup>®</sup>");

            return (
              <div
                key={`listing-elements----irrigation-elements---${index}`}
                className={`list-element cursor-pointer ${
                  (
                    item.type === "generic-containers"
                      ? item.index === props.style.index
                      : item.id === props.style.id
                  )
                    ? `list-element-selected`
                    : ``
                }`}
                onClick={() => selectItem(item)}
              >
                <img
                  className="list-element-image"
                  src={item.image}
                  alt={item.image}
                />
                <div
                  className="list-element-label"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="kit-room-control">
        <Button
          text="Back"
          variant="secondary"
          onClick={() => {
            props.history.replace({
              pathname: "/roombuilder/container-or-medium",
              search: props.history.location.search,
            });
          }}
        />
        <Button
          text="Continue"
          variant="primary-new"
          className={continueDisabled ? "disabled" : ""}
          onClick={() => {
            if (errorToShow) {
              props.openPopup({
                content: errorToShow.message,
              });
              return;
            }
            if (continueDisabled) {
              return;
            }

            const executeThis = () => {
              props.history.push({
                pathname: "/roombuilder/recommended-builds",
                search: props.history.location.search,
              });
            };

            const trayWarning = props.getOneOfTheTrayWarnings();
            if (trayWarning) {
              props.openSpacingWarning(
                () => {
                  executeThis();
                }
              ); 
            } else {
              executeThis();
            }
          }}
        />
      </div>
    </div>
  );
}
