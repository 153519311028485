// @flow
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { NavigationMenu } from "../../layout/NavigationMenu/NavigationMenu";
import KitRoom from "./KitRoom/KitRoomForm";
import WithNavigation from "../../components/WithNavigation/WithNavigation";
import GrowArea from "./KitRoom/KitGrowArea";
import TraySize from "./KitRoom/KitTraySize";
import ContainerOrMedium from "./KitRoom/KitContainer";
import Irrigation from "./KitRoom/KitIrrigation";
import Zones from "./KitRoom/KitRoomZones";
import PlatformLayout from "./KitRoom/KitPlatformLayout";
import RecommendedBuilds from "./KitRoom/KitRecommended";
import CartSummary from "./KitRoom/KitCart";
import "./KitBuilder.scss";
import _ from "lodash";

// import steps icons
import { ReactComponent as IconRoomDimensions } from "./../../../assets/svg/room-dimensions-new.svg";
import { ReactComponent as IconGrowAreaStyle } from "./../../../assets/svg/grow-area-style-new.svg";
import { ReactComponent as IconTraySize } from "./../../../assets/svg/tray-size-new.svg";
import { ReactComponent as IconPlatformLayout } from "./../../../assets/svg/platform-layout.svg";
import { ReactComponent as IconContainerMedium } from "./../../../assets/svg/container-medium-new.svg";
import { ReactComponent as IconIrrigationStyle } from "./../../../assets/svg/irrigation-style-new.svg";
import { ReactComponent as IconZoningStyle } from "./../../../assets/svg/zoning-style.svg";
import { ReactComponent as IconRecommendedBuilds } from "./../../../assets/svg/recommended-builds-new.svg";

import KitRoomlayout from "./KitRoom/KitRoomLayout";
import * as roomFunctions from "./KitRoom/KitRoomFunctions";
import { layoutActions } from "../../redux/actionFunctions/layoutActions";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../layout/components/Header/Header";
import * as utilFunctions from "./../../utils/utilFunctions";

import SaveRoomBuildPopup from "./SaveRoomBuildPopup/SaveRoomBuildPopup";

import { authenticaionActions } from "../../redux/actionFunctions/authenticationActions";

import { useApolloClient } from "@apollo/client";
import cartFunctions from "./KitRoom/KitCartFunctions";
import Kit3DCanvas from "./KitRoom/KitCart/Kit3DCanvas";
import Loading3DPopup from "./Loading3DPopup/Loading3DPopup";

import imgDefaultPerArea from "./../../../assets/image/default-per-area.png";
import imgDefaultPot from "./../../../assets/image/default-pot.png";
import imgDefaultIrrigation from "./../../../assets/image/default-irrigation.jpg";
import Axios from "axios";

import * as constants from "./../../utils/constants";
import objectPath from "object-path";
import { CustomPopupLogo } from "./SaveRoomBuildPopup/CustomPopupLogo";

let roomIdInitial = "";

let initialRoomProps;

let defaultRoomNameMax = 1;
let defaultRoomName = `Untitled${defaultRoomNameMax}`;

let centerCanvasTimeout = 0;

const STEPS = [
  {
    value: constants.STEP_VALUES.ROOM_DIMENSIONS,
    label: "ROOM DIMENSIONS",
    key: "KitBuilder Room Dimensions",
    path: "/roombuilder/room-dimensions",
    regex: /^\/roombuilder\/room-dimensions/,
    icon: <IconRoomDimensions />,
    info: "",
    belongsTo: [0, 1],
  },
  {
    value: constants.STEP_VALUES.GROW_AREA_STYLE,
    label: "GROW AREA STYLE",
    key: "KitBuilder Grow Area Style",
    path: "/roombuilder/grow-area",
    regex: /^\/roombuilder\/grow-area/,
    icon: <IconGrowAreaStyle />,
    info: "",
    belongsTo: [0, 1],
  },
  {
    value: constants.STEP_VALUES.TRAY_SIZE,
    label: "TRAY SIZE",
    key: "KitBuilder Tray Size",
    path: "/roombuilder/tray-size",
    className: " tray-size-header",
    regex: /^\/roombuilder\/tray-size/,
    icon: <IconTraySize />,
    info: "",
    belongsTo: [0],
  },
  {
    value: constants.STEP_VALUES.CONTAINER_OR_MEDIUM,
    label: "POT OR MEDIUM",
    key: "KitBuilder Container or Medium",
    path: "/roombuilder/container-or-medium",
    regex: /^\/roombuilder\/container-or-medium/,
    className: " pot-or-medium-header",
    icon: <IconContainerMedium />,
    info: "",
    belongsTo: [0, 1],
  },
  {
    value: constants.STEP_VALUES.PLATFORM_LAYOUT,
    label: "PLATFORM LAYOUT",
    key: "KitBuilder Platform Layout",
    path: "/roombuilder/platform-layout",
    regex: /^\/roombuilder\/platform-layout/,
    icon: <IconPlatformLayout />,
    info: "",
    belongsTo: [1],
  },
  {
    value: constants.STEP_VALUES.ZONING_STYLE,
    className: " zonning-style-header",
    label: "ZONING STYLE",
    key: "KitBuilder Zoning Style",
    path: "/roombuilder/zoning-style",
    regex: /^\/roombuilder\/zoning-style/,
    icon: <IconZoningStyle />,
    info: "",
    belongsTo: [1],
  },
  {
    value: constants.STEP_VALUES.IRRIGATION_STYLE,
    label: "IRRIGATION STYLE",
    className: " irigigation-nav-item-cl",
    key: "KitBuilder Irrigation Style",
    path: "/roombuilder/irrigation-style",
    regex: /^\/roombuilder\/irrigation-style/,
    icon: <IconIrrigationStyle />,

    info: "",
    belongsTo: [0],
  },
  {
    value: constants.STEP_VALUES.RECOMMENDED_BUILDS,
    className: " recommended-builds-header",
    label: (
      <div className="recommended-build-label">
        <span>RECOMMENDED</span>
        <span>BUILDS</span>
      </div>
    ),
    key: "KitBuilder Recommended Builds",
    path: "/roombuilder/recommended-builds",
    regex: /^\/roombuilder\/recommended-build/,
    icon: <IconRecommendedBuilds />,
    info: "",
    belongsTo: [0, 1],
  },
  {
    value: constants.STEP_VALUES.CART_SUMMARY,
    label: "Cart Summary",
    key: "KitBuilder Cart Summary",
    path: "/roombuilder/cart-summary",
    belongsTo: [0, 1],
  },
  {
    value: constants.STEP_VALUES.NUTRIENT_CALCULATOR,
    label: "Nutrient Calculator",
    key: "KitBuilder Nutrient Calculator",
    path: "/roombuilder/nutrient-calculator",
    belongsTo: [0, 1],
  },
];

const wCodeTrayDist = roomFunctions.trayWarnings.TRAY_DISTANCEING.error_code;
const wCodeTrayPlumbingDist =
  roomFunctions.trayWarnings.TRAY_PLUMBING_DISTANCING.error_code;

const emptyBuildComponentsState = {
  containers: [],
  irrigation_style: [],
  automation: [],
};
let buildComponentsOriginal = JSON.parse(
  JSON.stringify(emptyBuildComponentsState)
);

const emptyThirdPartyPartsState = {
  pump: [],
  controller: [],
  alternative_controller: [],
  expansion: [],
  regulator: [],
};
let thirdPartyPartsOriginal = JSON.parse(
  JSON.stringify(emptyThirdPartyPartsState)
);

const getQuery = () => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const getQueryStringVal = (key) => {
  return getQuery().get(key);
};

const useQueryParam = (key, defaultVal) => {
  const [query, setQuery] = React.useState(
    getQueryStringVal(key) || defaultVal
  );

  const updateUrl = (newVal) => {
    setQuery(newVal);

    const query = getQuery();

    if (newVal.trim() !== "") {
      query.set(key, newVal);
    } else {
      query.delete(key);
    }

    // This check is necessary if using the hook with Gatsby
    if (typeof window !== "undefined") {
      const { protocol, pathname, host } = window.location;
      const newUrl = `${protocol}//${host}${pathname}?${query.toString()}${
        window.location.hash
      }`;
      window.history.pushState({}, "", newUrl);
    }
  };

  return [query, updateUrl];
};

export function KitBuilder(props) {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { clientCartId, clientId, kitBuilderTrigger } = useSelector(
    ({ auth, layout }) => ({
      clientCartId: auth?.user?.clientCartId,
      clientId: auth?.user?.clientId,
      kitBuilderTrigger: layout.kitBuilderTrigger,
    })
  );

  const [state, _setState] = React.useState({
    ...roomFunctions.generateInitialState(),
    ...(props.location.state?.detail?.selectedRoom?.id ||
    props.location.state?.detail?.selectedRoom?.needsSaving
      ? props.location.state.detail.state
      : roomFunctions.defaultStateKitBuilder_localStorage()),
  });

  /**
   * keep a state ref for event listeners handlers
   */
  const stateRef = React.useRef(state);
  const setState = (newState, source) => {
    // console.log(newState, source);
    stateRef.current = newState;
    _setState(newState);
  };

  /**
   * @returns - returns default grow area id if is empty
   */
  const getGrowAreIdWithFallback = () => {
    if (
      Number(state.growArea.id) !== constants.GROW_ARE_IDS.STANDARD_TRAY &&
      Number(state.growArea.id) !== constants.GROW_ARE_IDS.PLATFORM_PROTO
    ) {
      return constants.DEFAULT_GROW_AREA_ID;
    }
    return state.growArea.id;
  };

  /**
   *
   * @returns - a grow area with the default grow area id
   */
  const getGrowAreaWithFallback = () => {
    return {
      ...state.growArea,
      id: getGrowAreIdWithFallback(),
    };
  };

  const [steps, setSteps] = React.useState(
    _.filter(STEPS, (step) => {
      return step.belongsTo.includes(getGrowAreIdWithFallback());
    })
  );

  const [selectedRoom, setSelectedRoom] = React.useState({
    roomData: {},
    ...roomFunctions.defaultStateSelectedRoom_DEV(),
    ...(props.location.state?.detail?.selectedRoom?.id ||
    props.location.state?.detail?.selectedRoom?.needsSaving
      ? props.location.state.detail.selectedRoom
      : {}),
  });

  const [_routingState, _setRoutingState] = React.useState({
    selectedIndex: 0,
    hideCanvas: false,
    hideNavBar: false,
  });

  const routingRef = React.useRef(_routingState);
  const setRoutingState = (_new) => {
    routingRef.current = _new;
    _setRoutingState({
      ...routingRef.current,
      ..._new,
    });
  };
  const routingState = routingRef.current;

  const [buildComponents, setBuildComponents] = React.useState({
    containers: [],
    irrigation_style: [],
    automation: [],
  });
  const [thirdPartyParts, setThirdPartyParts] = React.useState({
    pump: [],
    controller: [],
    alternative_controller: [],
    expansion: [],
    regulator: [],
  });
  const [config, setConfig] = React.useState({
    configData: null,
    configDataLoadedKey: null,
  });

  function setCurrentStep(stepValue) {
    const selectedIndex = steps.findIndex((item) => item.value === stepValue);
    const hideNavBar = selectedIndex > 5;
    const hideCanvas = selectedIndex > 5;

    setRoutingState({
      ...routingState,
      hideNavBar,
      hideCanvas,
      load3DHidden: false,
      selectedIndex,
    });

    /**
     * when showing 3D canvas that was hidden display a popup if loading wasn't finished in background
     */
    if (hideCanvas && window.KitBuilder3D_loaded !== true) {
      openPopupLoading3D();
    } else {
      layoutActions(dispatch).popPopupStack({
        key: "LOADING_3D_POPUP",
      });
    }
  }

  function setInfoStep(stepValue, infoValue, infoExtra = "") {
    const selectedIndex = steps.findIndex((item) => item.value === stepValue);
    if (selectedIndex != "" || selectedIndex != null) {
      steps[selectedIndex].info = infoValue;
      if (infoExtra != "") {
        steps[selectedIndex].extra = infoExtra;
      }
    }
  }

  function openPopup(payload) {
    let key;
    if (payload.error_code) {
      key = payload.error_code;
    } else {
      key = Date.now();
    }
    layoutActions(dispatch).pushPopupStack({
      key,
      title: "",
      buttonOkText: "Ok",
      callbackNo: (closePopup) => {
        closePopup();
      },
      ...payload,
    });
  }

  /**
   * open a group of popups depended on one another - TRAY SYSTEM (plumbing-tray-wall distances)
   * @param {function} callbackYes
   */
  function openSpacingWarning(callbackYes) {
    /**
     * avoid any undefined path error
     */
    if (
      !window.disabledWarnings ||
      typeof window.disabledWarnings !== "object"
    ) {
      window.disabledWarnings = {};
    }

    const popups = [];

    const generateDefaultPayload = (warning) => {
      return {
        ...warning,
        key: warning.error_code,
        content: warning.message,
        callbackNo: (closePopup) => {
          closePopup();
        },
        callbackYes: (closePopup) => {
          ignoreTraySpacing(warning.error_code);
          closePopup();
        },
        priority: popups.length - 1,
        buttonOkText: null,
      };
    };

    if (!window.disabledWarnings[wCodeTrayPlumbingDist]) {
      const warning = roomFunctions.getWarningPlumbingTray(
        state.trays,
        state.roomSize.roomWidth,
        state.roomSize.roomLength
      );
      warning && popups.push(generateDefaultPayload(warning));
    }

    if (!window.disabledWarnings[wCodeTrayDist]) {
      const warning = roomFunctions.getWarningTrayDistancing(
        state.trays,
        state.roomSize.roomWidth,
        state.roomSize.roomLength
      );
      warning && popups.push(generateDefaultPayload(warning));
    }

    /**
     * THIS MAKES SURE:
     * - the last popup is the only one executing the callbackYes (which is generally a router location change)
     * - close all popups from this queue when "No" is pressed on every popup displayed
     */
    if (popups.length > 0) {
      let priority = Number.MIN_SAFE_INTEGER;
      let indexPriority = 0;

      const keys = popups.map((item) => item.key);
      popups.forEach((item, index) => {
        if (item.priority >= priority) {
          priority = item.priority;
          indexPriority = index;
        }
        popups[index].callbackNo = () => {
          layoutActions(dispatch).popPopupStackMultiple(keys);
        };
      });

      const error_code = popups[indexPriority].key;
      popups[indexPriority].callbackYes = (closePopup) => {
        callbackYes && callbackYes();
        ignoreTraySpacing(error_code);
        closePopup();
      };

      layoutActions(dispatch).pushMultiplePopups(popups);
    }
  }

  /**
   * open a group of popups depended on one another
   * @param {function} callbackYes
   */
  function openSpacingWarningSystem5(popups, callbackYes) {
    /**
     * avoid any undefined path error
     */
    if (
      !window.disabledWarnings ||
      typeof window.disabledWarnings !== "object"
    ) {
      window.disabledWarnings = {};
    }

    /**
     * THIS MAKES SURE:
     * - the last popup is the only one executing the callbackYes (which is generally a router location change)
     * - close all popups from this queue when "No" is pressed on every popup displayed
     */

    /**
     * filter by ignore state
     */
    popups = popups.filter(
      (item) => !window.disabledWarnings || !window.disabledWarnings[item.key]
    );

    if (popups.length > 0) {
      let priority = Number.MAX_SAFE_INTEGER;
      let indexPriority = 0;

      const keys = popups.map((item) => item.key);
      for (let i = 0; i < popups.length; i++) {
        const item = popups[i];
        let index = i;

        if (item.priority < priority) {
          priority = item.priority;
          indexPriority = index;
        }

        popups[index].callbackYes = (close) => {
          window.disabledWarnings[item.key] = true;
          close();
        };
        popups[index].callbackNo = () => {
          layoutActions(dispatch).popPopupStackMultiple(keys);
        };
      }

      const error_code = popups[indexPriority].key;
      popups[indexPriority].callbackYes = (closePopup) => {
        callbackYes();
        window.disabledWarnings[error_code] = true;
        closePopup();
      };

      layoutActions(dispatch).pushMultiplePopups(popups);
    } else {
      callbackYes();
    }
  }

  function goToPage(path) {
    const roomSizeError = roomFunctions.roomSizeError(state.roomSize);
    if (roomSizeError) {
      if (
        props.history.location.pathname.indexOf(
          "/roombuilder/room-dimensions"
        ) === -1
      ) {
        props.history.push({
          pathname: "/roombuilder/room-dimensions",
          search: props.history.location.search,
        });
      } else {
        openPopup({
          content: roomSizeError.message,
        });
      }
    } else {
      props.history.push({
        pathname: path,
        search: props.history.location.search,
      });
    }
  }

  /**
   * validate all previous steps before redirecting via navigation bar
   * is not valid -> display equivalent error message as you would press a disabled continue
   * @param {pathname} path
   */
  function goToPageFromNavBar(path) {
    //console.log(path);
    const checkedSteps = [];

    /**
     * gather together the steps (in order!) needed for validation
     */
    if (getGrowAreIdWithFallback() === constants.GROW_ARE_IDS.PLATFORM_PROTO) {
      [
        constants.STEP_VALUES.ROOM_DIMENSIONS,
        constants.STEP_VALUES.GROW_AREA_STYLE,
        constants.STEP_VALUES.CONTAINER_OR_MEDIUM,
        constants.STEP_VALUES.PLATFORM_LAYOUT,
        constants.STEP_VALUES.ZONING_STYLE,
        constants.STEP_VALUES.RECOMMENDED_BUILDS,
      ].forEach((value) => {
        const step = STEPS.find((item) => item.value === value);
        if (step) {
          checkedSteps.push(step);
        }
      });
    } else {
      [
        constants.STEP_VALUES.ROOM_DIMENSIONS,
        constants.STEP_VALUES.GROW_AREA_STYLE,
        constants.STEP_VALUES.TRAY_SIZE,
        constants.STEP_VALUES.CONTAINER_OR_MEDIUM,
        constants.STEP_VALUES.IRRIGATION_STYLE,
        constants.STEP_VALUES.RECOMMENDED_BUILDS,
      ].forEach((value) => {
        const step = STEPS.find((item) => item.value === value);
        if (step) {
          checkedSteps.push(step);
        }
      });
    }

    /** match with correct page using REGEX*/
    const indexOfPath = checkedSteps.findIndex(
      (item) => item.regex && item.regex.test(path)
    );

    let valid = indexOfPath > -1;

    /**
     * verify all previous steps -> open popup and stop from redirecting
     */
    for (let i = 0; i < indexOfPath && valid; i++) {
      const errorToShow = roomFunctions.createStepValidationFunction(
        state,
        checkedSteps[i].value
      )();
      //console.log(errorToShow);

      if (errorToShow) {
        openPopup({
          content: errorToShow.message,
        });

        valid = false;
      }
    }
    //console.log(indexOfPath, checkedSteps, path);

    /**
     * show SPACING WARNINGS - if they weren't ignored or are some
     */
    if (valid) {
      const trayPlacementCheck = getTrayPlacementWarnings();
      const system5Warnings = getPlatformPlacementWarnings();

      const trayPlacementWarning =
        trayPlacementCheck.warningTrayPlumbing ||
        trayPlacementCheck.warningTrayDist;

      if (trayPlacementWarning) {
        valid = false;

        openSpacingWarning(() => {
          props.history.push({
            pathname: path,
            search: props.history.location.search,
          });
        });
      } else {
        if (system5Warnings.length) {
          valid = false;

          openSpacingWarningSystem5(system5Warnings, () => {
            props.history.push({
              pathname: path,
              search: props.history.location.search,
            });
          });
        }
      }
    }

    valid &&
      props.history.push({
        pathname: path,
        search: props.history.location.search,
      });
  }

  function ignoreTraySpacing(errorCode) {
    if (
      !window.disabledWarnings ||
      typeof window.disabledWarnings !== "object"
    ) {
      window.disabledWarnings = {};
    }
    window.disabledWarnings[errorCode] = true;
  }

  function setRoomSize(roomSize) {
    if (roomSize.measureSystem === "FT") {
      roomSize.roomWidth = roomSize.roomWidthVal;
      roomSize.roomLength = roomSize.roomLengthVal;
    } else {
      roomSize.roomWidth = roomFunctions.mToFt(roomSize.roomWidthVal);
      roomSize.roomLength = roomFunctions.mToFt(roomSize.roomLengthVal);
    }
    roomSize.roomWidth = utilFunctions.number2Digits(roomSize.roomWidth);
    roomSize.roomLength = utilFunctions.number2Digits(roomSize.roomLength);
    setState({
      ...state,
      roomSize: roomSize,
      roomSizeKey: Date.now(),
    });
  }

  function changeMeasureSystem(type) {
    if (type == state.roomSize.measureSystem) {
      return false;
    }

    let newRoomWidthVal = state.roomSize.roomWidthVal;
    let newRoomLengthVal = state.roomSize.roomLengthVal;

    if (type == "M") {
      if (state.roomSize.roomWidthVal != "") {
        newRoomWidthVal = roomFunctions.ftToM(state.roomSize.roomWidthVal);
      }

      if (state.roomSize.roomLengthVal != "") {
        newRoomLengthVal = roomFunctions.ftToM(state.roomSize.roomLengthVal);
      }
    } else if (type == "FT") {
      if (state.roomSize.roomWidthVal != "") {
        newRoomWidthVal = state.roomSize.roomWidth;
      }

      if (state.roomSize.roomLengthVal != "") {
        newRoomLengthVal = state.roomSize.roomLength;
      }
    }
    newRoomWidthVal = utilFunctions.number2Digits(newRoomWidthVal);
    newRoomLengthVal = utilFunctions.number2Digits(newRoomLengthVal);

    setState({
      ...state,
      roomSize: {
        ...state.roomSize,
        measureSystem: type,
        roomWidthVal: newRoomWidthVal,
        roomLengthVal: newRoomLengthVal,
      },
    });
  }

  function getBuildComponents() {
    fetch(
      `${window.BASE_URL}rbapi/getBuildComponents.php?customer_id=${clientId}&room_id=${selectedRoom?.id}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        const value = {
          ...buildComponents,
          ...data,
        };
        buildComponentsOriginal = JSON.parse(JSON.stringify(value));
        setBuildComponents(value);
      })
      .catch((err) => {
        console.log("Error while fetching build components", err);
      });
  }

  function getThirdPartyComponents() {
    fetch(`${window.BASE_URL}rbapi/get3rdParty.php?room_id=${selectedRoom?.id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        const value = {
          ...thirdPartyParts,
          ...data,
        };
        thirdPartyPartsOriginal = JSON.parse(JSON.stringify(value));
        setThirdPartyParts(value);
      })
      .catch((err) => {
        console.log("Error while fetching third party parts");
        console.log(err);
      });
  }

  function getBuildComponents2(id_room) {
    return fetch(
      `${window.BASE_URL}rbapi/getBuildComponents.php?customer_id=${clientId}&room_id=${id_room}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return new Promise((resolve, reject) => reject({}));
      })
      .then((data) => {
        const value = {
          ...data,
        };
        return new Promise((resolve) => resolve(value));
      })
      .catch((err) => {
        console.log("Error while fetching build components", err);
        return new Promise((resolve, reject) => reject({}));
      });
  }

  function getThirdPartyComponents2(id_room) {
    return fetch(`${window.BASE_URL}rbapi/get3rdParty.php?room_id=${id_room}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return new Promise((resolve, reject) => reject({}));
      })
      .then((data) => {
        return new Promise((resolve) => resolve(data));
      })
      .catch((err) => {
        console.log("Error while fetching third party parts");
        console.log(err);
        return new Promise((resolve, reject) => reject({}));
      });
  }

  function resetBuildComponents() {
    setBuildComponents(JSON.parse(JSON.stringify(buildComponentsOriginal)));
  }

  function resetThirdPartyParts() {
    setThirdPartyParts(JSON.parse(JSON.stringify(thirdPartyPartsOriginal)));
  }

  function setBuildLine(property, index, value) {
    if (!Array.isArray(buildComponents[property])) {
      return;
    }
    const bComponent = buildComponents[property];
    if (bComponent.length <= index) {
      return;
    }
    bComponent[index].qty = value;
    setBuildComponents({
      ...buildComponents,
      changed: true,
      [property]: bComponent,
    });
  }

  function calculateNumOfPlants() {
    /**
     * num of plants for SYSTEM 5
     */
    if (Number(state.growArea.id) === constants.GROW_ARE_IDS.PLATFORM_PROTO) {
      return roomFunctions.calculateNumOfPlantsFlow2(state.platformData);
    } else {
      let fourAreas = 0;
      state.trays.forEach((item) => {
        const val1 = Number(item.widthVal) || 0;
        const val2 = Number(item.lengthVal) || 0;
        const maxDimension = val1 > val2 ? val1 : val2;
        if (maxDimension >= 4) {
          fourAreas += Math.floor(maxDimension / 4);
        }
      });

      let plant = Number(state.calculatorState.plant) || 0;
      /**
       * retrieve number from label (fix cases where value column was omited from db fsr and room was wrongly saved)
       */
      if (plant <= 0) {
        if (typeof state.calculatorState.plantLabel === "string") {
          const labelMatch = state.calculatorState.plantLabel.match(/([\d]+)/);
          if (
            Array.isArray(labelMatch) &&
            labelMatch.length > 0 &&
            labelMatch[0] &&
            Number(labelMatch[0])
          ) {
            plant = Number(labelMatch[0]);
          } else {
            plant = 1;
          }
        } else {
          plant = 1;
        }
      }

      return fourAreas * (Number(plant) || 1);
    }
  }

  function saveRoomAs() {
    saveYourBuild();
  }

  function createSaveRoomProps() {
    const state = stateRef.current;
    const functions = {
      getGrowAreaId: () => {
        return getGrowAreIdWithFallback();
      },
      getPlatformData: () => {
        /**
         * @typedef {Object} IPlatform
         * @property {String} platformDrainageDirection
         * @property {Number} platformLinkLength
         * @property {Number} platformNumRows
         * @property {Number} platformPlatformsPerRow
         * @property {Number} platformSubzoneWidth
         * @property {Number} platformWalkwayWidth
         * @property {Number} platformXcoord
         * @property {Number} platformYcoord
         */
        const platformDataAux = state.platformData;
        return {
          platformLinkLength: `${
            Number(platformDataAux.platformLinkLength) || 1
          } ${state.roomSize.measureSystem || "FT"}`,
          platformNumRows: Number(platformDataAux.platformNumRows) || 1,
          platformPlatformsPerRow:
            Number(platformDataAux.platformPlatformsPerRow) || 1,
          platformSubzoneWidth: `${
            Number(platformDataAux.platformSubzoneWidth) || 1
          } ${state.roomSize.measureSystem || "FT"}`,
          platformWalkwayWidth:
            Number(platformDataAux.platformWalkwayWidth) || 1,
          platformXcoord: Number(platformDataAux.platformXcoord) || 1,
          platformYcoord: Number(platformDataAux.platformYcoord) || 1,
        };
      },
    };

    return {
      getGrowAreaId: functions.getGrowAreaId,
      getPlatformData: functions.getPlatformData,

      getRoomDetails: () => {
        const platformData = functions.getPlatformData();
        const totalPlantFlow2 =
          platformData.platformPlatformsPerRow * platformData.platformNumRows;
        const growAreaId = functions.getGrowAreaId();

        return {
          roomDimensionLabel: `${state.roomSize.roomWidthVal || 1} X ${
            state.roomSize.roomLengthVal || 1
          } ${state.roomSize.measureSystem || "FT"}`,
          totalPlants: calculateNumOfPlants(),
          trayListLabel: `${state.trays.reduce((final, current) => {
            if (typeof current.label === "string") {
              const label = current.label.replace(/^(tray\s)/i, "");
              return `${final}${final ? "," : ""} ${label}: ${
                current.widthVal
              } X ${current.lengthVal}`;
            }
            return final;
          }, "")}`,
          trayListCount: state.trays.length || 1,
          plantPerAreaLabel: roomFunctions.generateDisplayDensity({
            density: state.calculatorState.plantLabel || "",
          }),
          plantPerAreaImage:
            state.calculatorState.plantItemImage || imgDefaultPerArea,
          irrigationLabel: state.irrigationStyle.label,
          irrigationImage: state.irrigationStyle.image || imgDefaultIrrigation,
          potLabel: state.chosenPot.label || "",
          potImage: state.chosenPot.image || imgDefaultPot,
          growAreaId,
          platformData,
          totalPlantFlow2,
        };
      },
    };
  }

  function saveYourBuild(multiStep) {
    window.lastSavedRoom = undefined;

    if (multiStep) {
      setRoutingState({ ...routingState, load3DHidden: false });
    }

    const roomDetails = createSaveRoomProps().getRoomDetails();

    layoutActions(dispatch).pushPopupStack({
      key: "SAVE_ROOM_BUILD_POPUP",
      title: "",
      callbackNo: (closePopup) => {
        closePopup();
      },
      customLogoNode: <CustomPopupLogo />,
      content: {
        title: "",
        text: "",

        node: (
          <SaveRoomBuildPopup
            closePopup={() => {
              layoutActions(dispatch).popPopupStack({
                key: "SAVE_ROOM_BUILD_POPUP",
              });
            }}
            editPopup={(payload) => {
              layoutActions(dispatch).editPopupStack({
                key: "SAVE_ROOM_BUILD_POPUP",
                ...payload,
              });
            }}
            stop3DHidden={() => {
              window.KitBuilder3D_loaded = true;
              utilFunctions.triggerCustomEvent("kit-builder-c-e-3d-loaded", {});
              setRoutingState({
                ...routingState,
                load3DHidden: false,
              });
            }}
            goToNextPage={() => {
              props.history.push({
                pathname: "/roombuilder/cart-summary",
                search: props.history.location.search,
              });
            }}
            selectedRoom={selectedRoom}
            rooms={state.rooms}
            clientId={clientId}
            saveRoom={saveRoom}
            roomDetails={roomDetails}
            multiStep={multiStep}
            defaultRoomName={defaultRoomName}
          />
        ),
      },
      className: "wrapper-save-room-popup-rm-your-build",
    });
  }

  /**
   * compares relevant properties difference and with that determines if the room needs to be saved before exit
   * @param {object} exitProps - payload sent to saveRoom endpoint
   */
  function redirectAfterRoomWarning(exitProps) {
    switch (exitProps.action) {
      case constants.REDIRECT_FROM_BUILD_ACTIONS.GO_TO_PATHNAME:
        props.history.replace({
          pathname: exitProps.pathname,
          search: exitProps.search || props.history.location.search,
        });
        break;
      case constants.REDIRECT_FROM_BUILD_ACTIONS.GO_TO_REMOTE:
        if (exitProps.url) {
          window.location.href = exitProps.url;
        }
        break;
    }
  }

  const roomNeedsSaving = (dev) => {
    return true;
  };

  const roomWasChanged = (dev) => {
    const currentPayload = JSON.parse(
      JSON.stringify(
        createSaveRoomPayload({
          room_name: "",
          room_description: "",
        })
      )
    );

    if (!selectedRoom.id) {
      if (dev) {
        console.log("DEV: unsaved room - no id");
      }
      return true;
    }

    if (initialRoomProps && typeof initialRoomProps === "object") {
      /**
       * DON'T COMPARE THESE VALUES (their values are not important in determining if the room had changed):
       * - these are fetched from api - the user may press the button before 2 async action are done so is best to not take this into account
       * - OR some of these inner values are generates from frontend and are not relevant
       */
      [
        //
        "isNew",
        "build_components",
        "thirdPartyParts",
        "nutrients",

        //chosen pot - only the label is relevant here..
        "chosenPot.id",
        "chosenPot.index",
        "chosenPot.key",

        //irrigation style
        "irrigationStyle.id",
        "irrigationStyle.id",
        "_reactObjs.chosenPot",
        "_reactObjs.irrigationStyle",
        "_reactObjs.wateredZone.id",
        "_reactObjs.wateredZone.description",
        "_reactObjs.wateredZone.image",

        //plant state (this is autocalculated based on other relevant properties)
        "_reactObjs.calculatorState.plant", //by label..
        "_reactObjs.calculatorState.plantItemId",
        "_reactObjs.calculatorState.volume", //by chosen pot
        "_reactObjs.calculatorState.plantItemImage",
      ].forEach((path) => {
        objectPath.set(initialRoomProps, path, 0);
        objectPath.set(currentPayload, path, 0);
      });

      if (dev === true) {
        console.log(
          `let c = ${JSON.stringify(
            currentPayload
          )}; let ini = ${JSON.stringify(initialRoomProps)};`
        );
      }

      if (!_.isEqual(initialRoomProps, currentPayload)) {
        return true;
      }
      return false;
    }
    return true;
  };

  /**
   * DEV - indicates if the popup will be duplicate the room
   */
  window.roomWasChanged = () => roomWasChanged(true);

  /**
   *
   * @param {object} exitProps - to proceed after save/no-save
   */
  function openWarningOnBuildFlowExit(exitProps) {
    if (!roomNeedsSaving()) {
      redirectAfterRoomWarning(exitProps);
      return;
    }

    const state = stateRef.current;
    const roomDetails = createSaveRoomProps().getRoomDetails();

    layoutActions(dispatch).pushPopupStack({
      key: "SAVE_ROOM_BUILD_POPUP",
      title: "",
      callbackNo: (closePopup) => {
        closePopup();
      },
      content: {
        title: "",
        text: "",
        node: (
          <SaveRoomBuildPopup
            closePopup={() => {
              layoutActions(dispatch).popPopupStack({
                key: "SAVE_ROOM_BUILD_POPUP",
              });
            }}
            redirectAfterRoomWarning={() => {
              redirectAfterRoomWarning(exitProps);
            }}
            selectedRoom={selectedRoom}
            rooms={state.rooms}
            clientId={clientId}
            roomWasChanged={roomWasChanged}
            /** save room without any */
            saveRoom={(
              room_description = "",
              room_name = "",
              newRoomSelected
            ) => {
              const newRoomData = createSaveRoomPayload({
                room_description,
                room_name,
                newRoomSelected,
              });

              /**
               * When leaving a perm via clicking MY ACCOUNT,
               * a user is presented the SAVE YOUR BUILD screen.
               * If SAVE is selected, the room is saved as a duplicate room in My Build.
               *
               * This makes sense if the room has been changed, but should not happen if the room has not been saved.
               *
               * duplicate room
               */
              let paramGET = "";
              if (selectedRoom.id && selectedRoom.room_name !== room_name) {
                //makes sure to duplicate
                newRoomData.room_id = "";
                newRoomData.id = "";

                paramGET = `?duplicated_from_id=${selectedRoom.id}`;

                /** injects verbose in the first record fetched back */
                paramGET += "&debug=true"; //return a debug object to see verbose, (inject in the first record)
              }

              return cartFunctions(client).saveRoom(newRoomData, paramGET);
            }}
            roomDetails={roomDetails}
            //
            isExitBuildWarning={true}
            defaultRoomName={defaultRoomName}
          />
        ),
      },
      className: "wrapper-save-room-popup-rm-your-build",
    });
  }

  function openPopupLoading3D() {
    if (window.KitBuilder3D_loaded !== true) {
      layoutActions(dispatch).pushPopupStack({
        key: "LOADING_3D_POPUP",
        title: "",
        priority: -2,
        callbackNo: (closePopup) => {
          closePopup();
        },
        useLogoLoader: true,
        content: {
          title: "",
          text: "",
          node: (
            <Loading3DPopup
              closePopup={() => {
                layoutActions(dispatch).popPopupStack({
                  key: "LOADING_3D_POPUP",
                });
              }}
            />
          ),
        },
        className: "wrapper-loading-3d-popup-rm",
      });
    }
  }

  function createSaveRoomPayload({
    room_description,
    room_name,
    newRoomSelected,
  }) {
    let room_id = "";
    let isNew = true;

    if (newRoomSelected) {
      room_id = newRoomSelected.id;
      isNew = false;
    }

    const numOfPlants = calculateNumOfPlants();
    const nutrientResults = config.configData
      ? roomFunctions.culculateNutrientResults({
          flush: 1,
          plant: numOfPlants,
          volume: state.calculatorState.volume,
          week: 0,
          addCycles: 1,
          products: config.configData?.products,
          customQuantities: {},
          ignoreQuantity: false,
        })
      : {
          allTotal: 0,
          allProductForCart: [],
        };

    const nutrients = Object.entries(nutrientResults.allProductForCart).reduce(
      (previous, [type, cItems]) => {
        cItems.forEach((line) => {
          if (line.quantity) {
            previous.push({
              sku: line.sku,
              quantity: line.quantity,
              bag: line.type,
              productType: type,
            });
          }
        });
        return previous;
      },
      []
    );

    const stateCopy = JSON.parse(JSON.stringify(state));

    const looseFillItem = {
      qty: 0,
      unit_price: 25.99,
      name: 'LOOSEFILL™ COCO BAG - 50L',
      sku: '1562',
    };
    let loosefillIndex = -1;

    buildComponentsOriginal.containers.forEach((container, index) => {
      if (container.name === 'LOOSEFILL™ COCO BAG - 50L') {
        loosefillIndex = index;
      } else {
        looseFillItem.qty += roomFunctions.calculateQuickFill(container);
      }
    });
    if (looseFillItem.qty > 0) {
      if (loosefillIndex === -1) {
        buildComponentsOriginal.containers.push(looseFillItem);
      } else {
        buildComponentsOriginal.containers[loosefillIndex] = looseFillItem;
      }
    } else if (loosefillIndex !== -1) {
      buildComponentsOriginal.containers.splice(loosefillIndex, 1);
    }

    const bCmpResults = roomFunctions.calculateComponentsResults({
      containers: buildComponentsOriginal.containers || [],
      irrigation_style: buildComponentsOriginal.irrigation_style || [],
      automation: buildComponentsOriginal.automation || [],
      plant: numOfPlants,
    });

    return {
      trays: state.trays,
      chosenPot: state.chosenPot,
      irrigationStyle: state.irrigationStyle,
      density: state.calculatorState.plantLabel,
      irrigationImage: state.irrigationStyle.image,
      densityImage: state.calculatorState.plantItemImage,
      numOfPlants,
      plumbing: state.plumbing,
      mainLinePipe: state.mainLinePipe,
      dripperStyle: state.dripperStyle,
      roomSize: state.roomSize,
      cart_id: clientCartId,
      room_description: room_description.replace(/\r\n/g, ""),
      room_name,
      nutrients,
      room_id,
      build_components: bCmpResults.allProducts,
      thirdPartyParts: thirdPartyParts,
      /**
       * platform - start
       */
      platformDrainageDirection:
        stateCopy.platformData.platformDrainageDirection,
      platformXcoord: stateCopy.platformData.platformXcoord,
      platformYcoord: stateCopy.platformData.platformYcoord,
      platformNumRows: stateCopy.platformData.platformNumRows,
      platformPlatformsPerRow: stateCopy.platformData.platformPlatformsPerRow,
      platformLinkLength: stateCopy.platformData.platformLinkLength,
      platformWalkwayWidth: stateCopy.platformData.platformWalkwayWidth,
      platformSubzoneWidth: stateCopy.platformData.platformSubzoneWidth,
      original_owner_id: selectedRoom?.original_owner_id,
      /**
       * platform - start
       */
      _reactObjs: {
        roomSize: stateCopy.roomSize,
        trays: stateCopy.trays,
        calculatorState: { ...stateCopy.calculatorState },
        plumbing: stateCopy.plumbing,
        mainLinePipe: stateCopy.mainLinePipe,
        dripperStyle: stateCopy.dripperStyle,
        dripperPerPlant: stateCopy.dripperPerPlant,
        growArea: stateCopy.growArea,
        chosenPot: stateCopy.chosenPot,
        irrigationStyle: stateCopy.irrigationStyle,
        platformData: stateCopy.platformData,
        wateredZone: stateCopy.wateredZone,
        zones: stateCopy.zones,
        disabledWarnings: window.disabledWarnings,
      },
      clientId,
      isNew,
    };
  }

  /**
   * re-update the default room name going forward
   * @param {Room[]} data
   */
  function updateDefaultRoomName(data) {
    data.forEach((element) => {
      if (typeof element.room_name === "string") {
        const numberMatch = ["", Number(element.id)];

        if (Array.isArray(numberMatch) && numberMatch.length) {
          let num = Number(numberMatch[numberMatch.length - 1]) || 0;
          if (num >= defaultRoomNameMax) {
            defaultRoomNameMax = num + 1;
          }
        }
      }
    });
    defaultRoomName = `Untitled${defaultRoomNameMax}`;
    defaultRoomNameMax = defaultRoomNameMax;
  }

  function saveRoom(room_description = "", room_name = "", newRoomSelected) {
    const saveRoomParams = createSaveRoomPayload({
      room_description,
      room_name,
      newRoomSelected,
    });

    const isNew = saveRoomParams.isNew;

    return cartFunctions(client)
      .saveRoom(saveRoomParams)
      .then(({ data }) => {
        const id_room = data?.[0]?.id;
        if (id_room) {
          setSelectedRoom({ ...data[0], saveKey: Date.now() });
          updateDefaultRoomName(data);

          /** update the list for the source that is used by the save room dropdown */
          if (
            Array.isArray(window.memoizedRoomList) &&
            !window.memoizedRoomList.some((item) => item.id === data[0].id)
          ) {
            window.memoizedRoomList.push(data[0]);
          }

          if (isNew) {
            let roomcomm = null;
            let thirdPartyData = null;
            getThirdPartyComponents2(id_room)
              .then((data) => {
                thirdPartyData = data;
                getBuildComponents2(id_room)
                  .then((data) => {
                    roomcomm = data;
                    const bCmpResults = roomFunctions.calculateComponentsResults(
                      {
                        containers: roomcomm.containers || [],
                        irrigation_style: roomcomm.irrigation_style || [],
                        automation: roomcomm.automation || [],
                        plant: calculateNumOfPlants(),
                      }
                    );

                    cartFunctions(client).saveRoom({
                      ...saveRoomParams,
                      room_id: id_room,
                      build_components: bCmpResults.allProducts,
                      thirdPartyParts: thirdPartyData,
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          }
          return new Promise((resolve) => resolve(data[0]));
        }

        return new Promise((resolve, reject) => reject(data));
      })
      .catch((error) => {
        console.log(error);
        if (
          Array.isArray(error?.graphQLErrors) &&
          error.graphQLErrors.length &&
          error.graphQLErrors.some(
            (item) => item?.extensions?.category === "graphql-authorization"
          ) &&
          window.isLoggedWithUserNameAndPasswordRb !== true //avoid a unauthenticated call done too early after login
        ) {
          authenticaionActions(dispatch).logout(client);
        }
        return new Promise((resolve, reject) => reject(error));
      });
  }

  function getRooms() {
    window.memoizedRoomList = [];

    roomFunctions
      .getRooms(clientId)
      .then((data) => {
        updateDefaultRoomName(data);

        window.memoizedRoomList = data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getMatrix() {
    Axios(`${window.BASE_URL}rbapi/getMatrix.php`)
      .then((response) => {
        if (response.status == 200 && response.data.grow_areas) {
          window.kitBuilderMatrix = response.data;
        } else {
          window.kitBuilderMatrix = null;
        }
      })
      .catch(() => {
        window.kitBuilderMatrix = null;
      });
  }

  function updateInitialRoomProps() {
    /** use this to compare if room needs to be saved */
    initialRoomProps = JSON.parse(
      JSON.stringify(
        createSaveRoomPayload({
          room_description: "",
          room_name: "",
        })
      )
    );
    console.log("update initial-room-props", initialRoomProps);
  }

  function getPlatformSize() {
    return roomFunctions.getPlatformSize(state);
  }

  function onChangeGrowArea(growArea) {
    let otherProperties = {};

    if (state.growArea.id !== growArea.id) {
      const initialState = roomFunctions.generateInitialState();
      otherProperties = {
        plumbing: initialState.plumbing,
        mainLinePipe: initialState.mainLinePipe,
        dripperStyle: initialState.dripperStyle,
        dripperPerPlant: initialState.dripperPerPlant,
        growArea: initialState.growArea,
        trays: initialState.trays,
        zones: initialState.zones,
        chosenPot: initialState.chosenPot,
        platformData: initialState.platformData,
      };
      reinitWindowVars();
    }

    setState({
      ...state,
      ...otherProperties,
      growArea: growArea,
    });
  }

  function shareRoom() {
    layoutActions(dispatch).openShareRoomPopup(JSON.stringify(selectedRoom));
  }

  /**
   * TRAY SYSTEM: plumbing and tray distance (wall or between each other + plumbing overlapping(!plumbing can overlap each other, but not the trays!) )
   * @returns {object}
   */
  const getTrayPlacementWarnings = () => {
    if (getGrowAreIdWithFallback() === constants.GROW_ARE_IDS.PLATFORM_PROTO) {
      return {};
    }
    /**
     * compute warnings
     */
    let warningTrayDist = undefined;
    let warningTrayPlumbing = undefined;

    if (!window.disabledWarnings?.[wCodeTrayDist]) {
      warningTrayDist = roomFunctions.getWarningTrayDistancing(
        state.trays,
        state.roomSize.roomWidth,
        state.roomSize.roomLength
      );
    }

    if (!window.disabledWarnings?.[wCodeTrayPlumbingDist]) {
      warningTrayPlumbing = roomFunctions.getWarningPlumbingTray(
        state.trays,
        state.roomSize.roomWidth,
        state.roomSize.roomLength
      );
    }

    return {
      warningTrayPlumbing,
      warningTrayDist,
    };
  };

  /**
   * SYSTEM 5: valve and walkaway distance (wall)
   * @returns {object}
   */
  const getPlatformPlacementWarnings = () => {
    if (getGrowAreIdWithFallback() !== constants.GROW_ARE_IDS.PLATFORM_PROTO) {
      return [];
    }

    let placementWarnings = [];
    const platform = state.zones.filter((item) => item.label === "PLATFORM")[0];
    const walkaway = state.zones.filter((item) => item.label === "WALKWAY")[0];

    /**
     * push walking area warning
     */
    if (
      !window.disabledWarnings?.["WALK_AREA_WARNING_WALL"] &&
      (platform.offsetX < state.platformData.platformWalkwayWidth ||
        platform.offsetY < state.platformData.platformWalkwayWidth ||
        walkaway.offsetX + walkaway.widthVal > state.roomSize.roomWidth ||
        walkaway.offsetY + walkaway.lengthVal > state.roomSize.roomLength)
    ) {
      placementWarnings.push({
        content: {
          title: "WALKWAY AREA INTERFERING WITH WALL",
          text: (
            <>
              <p>
                Your walkway area is currently interfering with the room wall.
                This reduces your walkway width.
              </p>
              <p>
                You can proceed but this may limit accessibility to all of your
                plants. This may result in some of your drainage lines
                conflicting with the walls of your Room Builder build.
              </p>
              <p>
                Please confirm that you understand the tubing lines may need to
                be manually trimmed to accommodate this layout and that you
                would like to continue <br></br>with this area placement?
              </p>
            </>
          ),
        },
        buttonYes: "Continue",
        buttonNo: "No",
        priority: 1,
        className: "save-popup-",
        error_code: "WALK_AREA_WARNING_WALL",
        nodeAttributes: {
          ["css-code"]: "WALK_AREA_WARNING_WALL", //for popup styling attribute
        },
        key: "WALK_AREA_WARNING_WALL",
      });
    }

    /**
     * push plumbing warning
     */
    const valve = state.zones.find((item) => item.label === "FEED END/VALVE");
    const drainage = state.platformData.platformDrainageDirection;

    if (
      !window.disabledWarnings?.["PLATFORM_PLUMBING_WALL"] &&
      valve &&
      ((drainage === "right" && valve.offsetX < 1) ||
        (drainage === "left" &&
          valve.offsetX + 1 > state.roomSize.roomWidth - 1) ||
        (drainage === "down" && valve.offsetY < 1) ||
        (drainage === "up" &&
          valve.offsetY + 1 > state.roomSize.roomLength - 1))
    ) {
      placementWarnings.push({
        content: {
          title: "CONFIRM PLUMBING PLACEMENT",
          text: (
            <>
              <p>
                You have placed the Feed End/Valve end of your platform area
                less than 1ft from the room wall.
              </p>
              <p>
                This layout is possible if you plan to mount your plumbing to
                the wall directly. However, for your Room Builder build to look
                visually accurate we recommend moving your platform area so the
                Feed End/Valve area does not conflict with the wall.
              </p>
            </>
          ),
        },
        buttonYes: "Continue",
        buttonNo: "No",
        error_code: "PLATFORM_PLUMBING_WALL",
        key: "PLATFORM_PLUMBING_WALL",
        priority: 2,
        nodeAttributes: {
          ["css-code"]: "PLATFORM_PLUMBING_WALL", //(-!-also used for tray warning): for popup styling attribute
        },
      });
    }

    return placementWarnings;
  };

  function verifyPlacementWarningsOnCanvasChange() {
    /** marks if it needs to show the placement warnings if the trays or platform are moved */
    if (window.checkForCanvasChanges) {
      if (
        roomFunctions.traysOrPlatformWereChanged({
          state,
          selectedRoom,
          initialRoomProps,
        })
      ) {
        window.disabledWarnings = {};
        window.checkForCanvasChanges = false;
      }
    }
  }

  React.useEffect(() => {
    clearTimeout(centerCanvasTimeout);
    if (state.roomSizeKey) {
      centerCanvasTimeout = setTimeout(() => {
        const centerBtn = document.getElementById("center-canvas-btn-id");
        if (centerBtn) {
          centerBtn.click();
        }
      }, 200);
    }
  }, [state.roomSizeKey]);

  React.useEffect(() => {
    const roomSizeError = roomFunctions.roomSizeError(state.roomSize);
    if (
      roomSizeError &&
      props.history.location.pathname.indexOf(
        "/roombuilder/room-dimensions"
      ) === -1
    ) {
      props.history.push({
        pathname: "/roombuilder/room-dimensions",
        search: props.history.location.search,
      });
    }

    window.lastSavedRoom = undefined;

    cartFunctions()
      .getConfig()
      .then((configData) => {
        setConfig({ configData, configDataLoadedKey: Date.now() });
      })
      .catch(() => {});

    return () => {
      /**
       * clean some of the popups when loging out unexpected
       */
      layoutActions(dispatch).popPopupStack({
        key: "LOADING_3D_POPUP",
      });
      layoutActions(dispatch).popPopupStack({
        key: "SAVE_ROOM_BUILD_POPUP",
      });

      window.screenShotAfter3DRender = false;
    };
  }, []);

  React.useLayoutEffect(() => {
    window.kitBuilderMatrix = null;

    if (props.location.state?.detail?.stepPath === "cart-summary") {
      props.history.replace({
        pathname: "/roombuilder/cart-summary",
        search: props.history.location.search,
      });
    } else {
      /**
       * client is not the owner of the room
       */
      if (props.location.state?.detail?.needs_saving) {
        saveYourBuild(true);
      }
    }

    getRooms();

    window.addEventListener(
      constants.EVENTS.LOADER_3D_CANVAS_OPEN,
      openPopupLoading3D
    );
    return () => {
      window.removeEventListener(
        constants.EVENTS.LOADER_3D_CANVAS_OPEN,
        openPopupLoading3D
      );
    };
  }, []);

  React.useEffect(() => {
    getMatrix();
  }, [props.location.pathname]);

  React.useEffect(() => {
    if (kitBuilderTrigger.key) {
      const exitProps = JSON.parse(JSON.stringify(kitBuilderTrigger));
      openWarningOnBuildFlowExit(exitProps);
    }

    return () => {
      if (kitBuilderTrigger.key) {
        layoutActions(dispatch).resetKitBuilderTrigger();
      }
    };
  }, [kitBuilderTrigger.key]);

  /**
   * update progress header for tray vs platform
   */
  React.useEffect(() => {
    setSteps(
      _.filter(STEPS, (step) => {
        return step.belongsTo.includes(getGrowAreIdWithFallback());
      })
    );
  }, [state.growArea]);

  const updateWalkwayWidth = (walkwayWidth) => {
    setState({ ...state, walkwayWidth });
  };

  /**
   * this makes sure when "state" is updated header is as well (for ex. if room size is changed trays goes empty and header as well)
   */
  const headerSteps = steps.slice(0, 6).map((item) => {
    switch (item.value) {
      case "Grow Area Style":
        return {
          ...item,
          info: state.growArea.label || "",
          completed: Boolean(state.growArea.label),
        };
      case "Container or Medium":
        return {
          ...item,
          info: state.chosenPot.label || "",
          completed: Boolean(state.chosenPot.label),
        };
      case "Irrigation Style":
        return {
          ...item,
          info: state.irrigationStyle.label || "",
          completed: Boolean(state.irrigationStyle.label),
        };
      case "Tray Size":
        return {
          ...item,
          info:
            state.trays.length > 0 ? (
              <div className="flex-all tray-text-header">
                {state.trays.length > 1 && (
                  <div className="flex-all rounder-div-green">
                    <div>{state.trays.length}</div>
                  </div>
                )}
                <div className="main-text-tray-header">
                  {`${state.trays.reduce((final, current) => {
                    if (typeof current.label === "string") {
                      const label = current.label.replace(/^(tray\s)/i, "");
                      return `${final}${final ? "," : ""} ${label}: ${
                        current.widthVal
                      } X ${current.lengthVal}`;
                    }
                    return final;
                  }, "")}`}
                </div>
              </div>
            ) : (
              ""
            ),
          completed: state.trays.length > 0,
        };
      case "Platform Layout":
        return (() => {
          let completed =
            state.platformData.platformDrainageDirection &&
            state.platformData.platformDrainageDirection !== "none";

          let info = "";
          if (completed) {
            const numberValue =
              state.platformData.platformNumRows *
              state.platformData.platformPlatformsPerRow;
            if (numberValue === 1) {
              info = `${numberValue} PLATFORM`;
            } else {
              info = `${numberValue} PLATFORMS`;
            }
          }

          return {
            ...item,
            completed,
            info,
          };
        })();
      case "Zoning Style":
        return (() => {
          let completed = Boolean(state.wateredZone.label);
          return {
            ...item,
            completed,
            info: completed
              ? roomFunctions.safeToUpperCase(state.wateredZone.label)
              : "",
          };
        })();
      case "Room Dimensions":
        return (() => {
          let completed = state.roomSize.roomWidth && state.roomSize.roomLength;
          return {
            ...item,
            completed,
            info: completed
              ? `${state.roomSize.roomWidthVal} X ${state.roomSize.roomLengthVal} ${state.roomSize.measureSystem}`
              : "",
          };
        })();
    }
    return item;
  });

  /**
   * go along with the implementation
   */
  function reinitWindowVars() {
    window.maxLinkLength = undefined;
    window.maxPlatforms = undefined;
    window.maxRows = undefined;
  }

  React.useLayoutEffect(() => {
    reinitWindowVars();
    return () => {
      reinitWindowVars();

      /** placement warnings */
      window.disabledWarnings = {};
    };
  }, []);

  React.useEffect(() => {
    let urlParams = new URL(
      `https://dummy-url.ro/${props.history.location.search}`
    ).searchParams;
    const id_room = urlParams.get("room_id");
    if (id_room != selectedRoom.id) {
      let newSearch;
      if (selectedRoom.id) {
        newSearch = `?room_id=${selectedRoom.id}`;
      } else {
        newSearch = "";
      }
      props.history.replace({
        pathname: props.history.location.pathname,
        search: newSearch,
      });
    }
  }, [selectedRoom.id]);

  React.useEffect(() => {
    verifyPlacementWarningsOnCanvasChange();
  }, [
    state.trays,
    state.growArea,
    state.platformData.platformYcoord,
    state.platformData.platformXcoord,
  ]);

  /**
   * every time the room gets save we should update the object by which we compare if the room needs to be save before exit flow
   */
  React.useEffect(() => {
    updateInitialRoomProps();

    /** fetch components after room has been saved in order to update the UI*/
    if (selectedRoom.id) {
      getBuildComponents();
      getThirdPartyComponents();
    }
  }, [selectedRoom.saveKey, selectedRoom.id]);

  localStorage.setItem("rb-last-room-loaded", JSON.stringify(state));

  const _stepIndexing = steps.reduce((final, current) => {
    return {
      ...final,
      [current.path]: true,
    };
  }, {});

  /**
   * -> pass this function to be checked on continue press into components
   */
  const getOneOfTheTrayWarnings = () => {
    const trayPlacementCheck = getTrayPlacementWarnings();
    return (
      trayPlacementCheck.warningTrayPlumbing ||
      trayPlacementCheck.warningTrayDist
    );
  };

  /**
   * this must be passed to all components except <GrowArea /> where the selection is made
   * helps avoid errors from passed implementation
   * This is part of the implementation to safely autoselect (in the background) the default grow area without affecting the navigation nav bar selection but display the correct steps
   */
  const growAreaWithFallback = getGrowAreaWithFallback();

  return (
    <>
      <Header saveRoom={saveRoomAs} />
      <div className="kitbuilder">
        <div className="kitbuilder-main-wrapper">
          {!routingState.hideNavBar && (
            <NavigationMenu
              steps={headerSteps}
              growId={getGrowAreIdWithFallback()}
              selectedIndex={steps.findIndex(
                (item) => item.path === props.history.location.pathname
              )}
              goToPage={goToPageFromNavBar}
            />
          )}

          <div
            className={`step-main-container ${
              routingState.hideNavBar ? `step-cart` : ``
            }`}
          >
            <Switch>
              <Route
                path="/roombuilder/room-dimensions"
                render={(routerProps) => (
                  <WithNavigation
                    history={routerProps.history}
                    onChangeCurrentPath={(value) => setCurrentStep(value)}
                    value="Room Dimensions"
                  >
                    <KitRoom
                      {...routerProps}
                      roomSize={state.roomSize}
                      getOneOfTheTrayWarnings={getOneOfTheTrayWarnings}
                      openSpacingWarning={openSpacingWarning}
                      openPopup={openPopup}
                      setRoomSize={setRoomSize}
                      changeMeasureSystem={changeMeasureSystem}
                      traysAreEmpty={state.trays.length === 0}
                      emptyTrays={() => {
                        setState({
                          ...state,
                          trays: [],
                        });
                      }}
                      getStepError={roomFunctions.createStepValidationFunction(
                        state,
                        constants.STEP_VALUES.ROOM_DIMENSIONS
                      )}
                    />
                  </WithNavigation>
                )}
              />
              <Route
                path="/roombuilder/grow-area"
                render={(routerProps) => (
                  <WithNavigation
                    history={routerProps.history}
                    onChangeCurrentPath={(value) => setCurrentStep(value)}
                    value="Grow Area Style"
                  >
                    {" "}
                    <GrowArea
                      {...routerProps}
                      roomSize={state.roomSize}
                      /** here we need the real grow area */
                      growArea={state.growArea}
                      setGrowArea={onChangeGrowArea}
                      openPopup={openPopup}
                      getOneOfTheTrayWarnings={getOneOfTheTrayWarnings}
                      openSpacingWarning={openSpacingWarning}
                      getStepError={roomFunctions.createStepValidationFunction(
                        state,
                        constants.STEP_VALUES.GROW_AREA_STYLE
                      )}
                    />
                  </WithNavigation>
                )}
              />
              {_stepIndexing["/roombuilder/tray-size"] && (
                <Route
                  path="/roombuilder/tray-size"
                  render={(routerProps) => (
                    <WithNavigation
                      history={routerProps.history}
                      onChangeCurrentPath={(value) => setCurrentStep(value)}
                      value="Tray Size"
                    >
                      {" "}
                      <TraySize
                        history={props.history}
                        roomSize={state.roomSize}
                        openPopup={openPopup}
                        getOneOfTheTrayWarnings={getOneOfTheTrayWarnings}
                        openSpacingWarning={openSpacingWarning}
                        trays={state.trays}
                        setTrays={(trays) => {
                          setState({ ...state, trays });
                        }}
                        getStepError={roomFunctions.createStepValidationFunction(
                          state,
                          constants.STEP_VALUES.TRAY_SIZE
                        )}
                      />
                    </WithNavigation>
                  )}
                />
              )}

              <Route
                path="/roombuilder/container-or-medium"
                render={(routerProps) => (
                  <WithNavigation
                    history={routerProps.history}
                    onChangeCurrentPath={(value) => setCurrentStep(value)}
                    value="Container or Medium"
                  >
                    {" "}
                    <ContainerOrMedium
                      {...routerProps}
                      roomSize={state.roomSize}
                      chosenPot={state.chosenPot}
                      growArea={growAreaWithFallback}
                      setPot={(pot) => {
                        const additional = {};

                        /** link 1 is not permitted for 10 gal */
                        if (
                          pot.value == 10 &&
                          state.platformData.platformLinkLength === 1
                        ) {
                          additional.platformData = {
                            ...state.platformData,
                            platformLinkLength: 2,
                            platformKey: Date.now(),
                          };
                        }

                        setState({
                          ...state,
                          chosenPot: pot,
                          irrigationStyle: roomFunctions.generateInitialState()[
                            "irrigationStyle"
                          ], // reset irrigation style
                          ...additional,
                        });
                      }}
                      openPopup={openPopup}
                      getOneOfTheTrayWarnings={getOneOfTheTrayWarnings}
                      openSpacingWarning={openSpacingWarning}
                      getStepError={roomFunctions.createStepValidationFunction(
                        state,
                        constants.STEP_VALUES.CONTAINER_OR_MEDIUM
                      )}
                    />
                  </WithNavigation>
                )}
              />

              {/* THIS HANDLES THE ACTIONS AS CONSEQUENCE OF ROUTING CHANGE  */}
              {_stepIndexing["/roombuilder/platform-layout"] && (
                <Route
                  path="/roombuilder/platform-layout"
                  render={(routerProps) => (
                    <WithNavigation
                      history={routerProps.history}
                      onChangeCurrentPath={(value) => setCurrentStep(value)}
                      value="Platform Layout"
                    >
                      <></>
                    </WithNavigation>
                  )}
                />
              )}

              {_stepIndexing["/roombuilder/zoning-style"] && (
                <Route
                  path="/roombuilder/zoning-style"
                  render={(routerProps) => (
                    <WithNavigation
                      roomSize={state.roomSize}
                      history={routerProps.history}
                      onChangeCurrentPath={(value) => setCurrentStep(value)}
                      value="Zoning Style"
                    >
                      {" "}
                      <Zones
                        {...routerProps}
                        wateredZone={state.wateredZone}
                        setWateredZone={(wateredZone) => {
                          setState({ ...state, wateredZone });
                        }}
                        openPopup={openPopup}
                        getStepError={roomFunctions.createStepValidationFunction(
                          state,
                          constants.STEP_VALUES.ZONING_STYLE
                        )}
                      />
                    </WithNavigation>
                  )}
                />
              )}

              {_stepIndexing["/roombuilder/irrigation-style"] && (
                <Route
                  path="/roombuilder/irrigation-style"
                  render={(routerProps) => (
                    <WithNavigation
                      roomSize={state.roomSize}
                      history={routerProps.history}
                      onChangeCurrentPath={(value) => setCurrentStep(value)}
                      value="Irrigation Style"
                    >
                      {" "}
                      <Irrigation
                        {...routerProps}
                        roomSize={state.roomSize}
                        style={state.irrigationStyle}
                        chosenPot={state.chosenPot}
                        growArea={growAreaWithFallback}
                        setStyle={(style) => {
                          setState({
                            ...state,
                            irrigationStyle: style,
                            calculatorState: roomFunctions.generateInitialState()[
                              "calculatorState"
                            ],
                            plumbing: roomFunctions.generateInitialState()[
                              "plumbing"
                            ],
                            mainLinePipe: roomFunctions.generateInitialState()[
                              "mainLinePipe"
                            ],
                            dripperStyle: roomFunctions.generateInitialState()[
                              "dripperStyle"
                            ],
                            dripperPerPlant: roomFunctions.generateInitialState()[
                              "dripperPerPlant"
                            ],
                          });
                        }}
                        openPopup={openPopup}
                        getOneOfTheTrayWarnings={getOneOfTheTrayWarnings}
                        openSpacingWarning={openSpacingWarning}
                        getStepError={roomFunctions.createStepValidationFunction(
                          state,
                          constants.STEP_VALUES.IRRIGATION_STYLE
                        )}
                      />
                    </WithNavigation>
                  )}
                />
              )}

              <Route
                path="/roombuilder/recommended-builds"
                render={(routerProps) => (
                  <WithNavigation
                    history={routerProps.history}
                    onChangeCurrentPath={(value) => setCurrentStep(value)}
                    value="Recommended Builds"
                  >
                    {" "}
                    <RecommendedBuilds
                      {...routerProps}
                      roomSize={state.roomSize}
                      calculatorState={state.calculatorState}
                      plumbing={state.plumbing}
                      mainLinePipe={state.mainLinePipe}
                      dripperStyle={state.dripperStyle}
                      dripperPerPlant={state.dripperPerPlant}
                      getOneOfTheTrayWarnings={getOneOfTheTrayWarnings}
                      openSpacingWarning={openSpacingWarning}
                      irrigationStyle={state.irrigationStyle}
                      chosenPot={state.chosenPot}
                      growArea={growAreaWithFallback}
                      growAreaId={growAreaWithFallback.id}
                      platformPerRows={
                        state.platformData.platformPlatformsPerRow
                      }
                      ignoreTraySpacing={ignoreTraySpacing}
                      trays={state.trays}
                      density={state.calculatorState.plantLabel}
                      setState={(payload) => {
                        setState(
                          {
                            ...state,
                            ...payload,
                          },
                          "salut"
                        );
                      }}
                      setCalculatorState={(calculatorState) => {
                        setState({
                          ...state,
                          calculatorState,
                        });
                      }}
                      setPlumbing={(plumbing) => {
                        setState({
                          ...state,
                          plumbing,
                        });
                      }}
                      setMainLinePipe={(mainLinePipe) => {
                        setState({
                          ...state,
                          mainLinePipe,
                        });
                      }}
                      setDripperStyle={(dripperStyle) => {
                        setState(
                          {
                            ...state,
                            dripperStyle,
                          },
                          "dripperStyle"
                        );
                      }}
                      setDripperPerPlant={(
                        dripperPerPlant,
                        newDripperStyle
                      ) => {
                        const stateLoad = {
                          ...state,
                          dripperPerPlant,
                        };

                        newDripperStyle &&
                          (() => {
                            stateLoad.dripperStyle = newDripperStyle;
                          })();

                        setState({ ...stateLoad });
                      }}
                      openPopup={openPopup}
                      saveRoom={() => saveYourBuild(true)}
                      getStepError={roomFunctions.createStepValidationFunction(
                        state,
                        constants.STEP_VALUES.RECOMMENDED_BUILDS
                      )}
                    />
                  </WithNavigation>
                )}
              />

              <Route
                path="/roombuilder/cart-summary"
                render={(routerProps) => (
                  <WithNavigation
                    history={routerProps.history}
                    onChangeCurrentPath={(value) => setCurrentStep(value)}
                    value="Cart Summary"
                  >
                    <CartSummary
                      {...routerProps}
                      trayType={state.chosenPot.label || ""}
                      grow={growAreaWithFallback.label || ""}
                      irrigation={
                        state.irrigationStyle != null
                          ? state.irrigationStyle.label
                          : ""
                      }
                      setlectedPlantLabel={
                        state.calculatorState.plantLabel || ""
                      }
                      calculatorState={{
                        flush: state.calculatorState.flush,
                        plant: state.calculatorState.plant,
                        volume: state.chosenPot.value || 2,
                        week: state.calculatorState.week,
                        addCycles: state.calculatorState.addCycles,
                      }}
                      setCalculatorState={(calculatorState) => {
                        setState({
                          ...state,
                          calculatorState,
                        });
                      }}
                      buildComponents={buildComponents}
                      plants={state.calculatorState.plant || 12}
                      openPopup={openPopup}
                      setBuildLine={setBuildLine}
                      calculateNumOfPlants={calculateNumOfPlants}
                      trays={state.trays}
                      zones={state.zones}
                      roomSize={state.roomSize}
                      growarea={growAreaWithFallback.label}
                      growAreaId={growAreaWithFallback.id}
                      container={state.chosenPot.label}
                      irrigation={state.irrigationStyle.label}
                      irrigationLabel={state.irrigationStyle.label}
                      density={state.calculatorState.plantLabel}
                      selectedRoom={selectedRoom}
                      resetBuildComponents={resetBuildComponents}
                      thirdPartyParts={thirdPartyParts}
                      resetThirdPartyParts={resetThirdPartyParts}
                    />
                  </WithNavigation>
                )}
              />

              <Route
                path="/roombuilder"
                render={() => {
                  props.history.replace({
                    pathname: "/roombuilder/room-dimensions",
                    search: props.history.location.search,
                  });
                  return "";
                }}
              />
            </Switch>

            {_stepIndexing["/roombuilder/platform-layout"] && (
              <>
                {/*
                  THIS TAKES CARE OF UPDATING THE ZONES WHEN A ROOM IS LOADED (based on platformData)
                  => so it's always running while the flow is open
                */}
                <PlatformLayout
                  showNode={/^\/roombuilder\/platform-layout/.test(
                    props.history.location.pathname
                  )}
                  key={state.platformData.platformKey}
                  getPlatformPlacementWarnings={getPlatformPlacementWarnings}
                  platformSize={getPlatformSize()}
                  history={props.history}
                  roomSize={state.roomSize}
                  zones={state.zones}
                  chosenPotValue={state.chosenPot.value}
                  setPlatForm={(platformData) => {
                    setState({
                      ...state,
                      platformData: {
                        ...state.platformData,
                        ...platformData,
                      },
                    });
                  }}
                  openSpacingWarningSystem5={openSpacingWarningSystem5}
                  updateZones={(zones, platformData) => {
                    /** sync position x, y with the platform item */
                    for (let i = 0; i < state.zones.length; i++) {
                      if (state.zones[i].label === "PLATFORM") {
                        state.platformData.platformXcoord = zones[i].offsetX;
                        state.platformData.platformYcoord = zones[i].offsetY;
                        break;
                      }
                    }

                    setState({
                      ...state,
                      zones,
                      platformData: {
                        ...state.platformData,
                        ...platformData,
                      },
                    });
                  }}
                  platformNumRows={state.platformData.platformNumRows}
                  platformLinkLength={state.platformData.platformLinkLength}
                  platformWalkwayWidth={state.platformData.platformWalkwayWidth}
                  platFormSize={state.platformData.platFormSize}
                  platformDrainageDirection={
                    state.platformData.platformDrainageDirection
                  }
                  platformNumRows={state.platformData.platformNumRows}
                  platformPlatformsPerRow={
                    state.platformData.platformPlatformsPerRow
                  }
                  platformSubzoneWidth={state.platformData.platformSubzoneWidth}
                  platformXcoord={state.platformData.platformXcoord}
                  platformYcoord={state.platformData.platformYcoord}
                />
              </>
            )}

            <KitRoomlayout
              roomWidth={state.roomSize.roomWidth}
              roomLength={state.roomSize.roomLength}
              measureSystem={state.roomSize.measureSystem}
              trays={state.trays}
              zones={state.zones}
              setTrays={(trays) => {
                setState({
                  ...state,
                  trays,
                });
              }}
              hideCanvas={routingState.hideCanvas}
              roomSize={{
                ...state.roomSize,
                roomWidth: state.roomSize.roomWidth,
                roomLength: state.roomSize.roomLength,
              }}
              roomSizeKey={state.roomSizeKey}
              openPopup={openPopup}
              platformData={state.platformData}
              pathName={props.history.location.pathname}
              growAreaId={growAreaWithFallback.id}
              updateZones={(zones, platformData) => {
                if (platformData && typeof platformData === "object") {
                  setState({
                    ...state,
                    zones,
                    platformData: {
                      ...state.platformData,
                      ...platformData,
                    },
                  });
                } else {
                  setState({
                    ...state,
                    zones,
                  });
                }
              }}
              clientId={clientId}
            />
            {(routingState.hideCanvas || routingState.load3DHidden) && (
              <div
                id="step-content-3d-rm"
                load3dhidden={routingState.load3DHidden ? "true" : "false"}
                className="step-content kit-room-layout kit-cart-layout"
              >
                <div className="kit-cart-layout-content">
                  {localStorage.getItem("no-3d---") !== "y" && (
                    <Kit3DCanvas
                      {...{
                        growarea: growAreaWithFallback.label,
                        growAreId: growAreaWithFallback.id,

                        container: state.chosenPot.label,
                        irrigation: state.irrigationStyle.label,
                        density: state.calculatorState.plantLabel,
                        dripperStyle: state.dripperStyle,
                        dripperPerPlant: state.dripperPerPlant,
                        platformData: state.platformData,
                      }}
                      plumbing={state.plumbing}
                      trays={state.trays}
                      roomSize={state.roomSize}
                      selectedRoom={selectedRoom}
                      calculateNumOfPlants={calculateNumOfPlants}
                      canvas2dProps={{
                        roomSize: {
                          ...state.roomSize,
                          roomWidth: state.roomSize.roomWidth,
                          roomLength: state.roomSize.roomLength,
                        },

                        trays: state.trays,
                        zones: state.zones,
                        measureSystem: state.roomSize.measureSystem,
                        roomSizeKey: state.roomSizeKey,
                        openPopup,
                      }}
                      shareRoom={shareRoom}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
