import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { gql, ApolloConsumer, useApolloClient } from "@apollo/client";
import "./PurchaseKits.scss";

import headerBanner from "./../../../assets/image/purchase-kits/heroKits.jpg";
import starterKit from "./../../../assets/image/purchase-kits/StarterKit_Main.png";
import sprayer from "./../../../assets/image/purchase-kits/1L_Sprayer_MainNew_new.png";
import Button from "../../components/Button/Button";
import { Spinner } from "react-bootstrap";

function ProductItem(props) {
  /**
   * used for triggering an action after loader was rendered (to avoid setLoader off render trigger before setLoader on in cas request is faster than render )
   */
  const [loaderState, setLoaderState] = React.useState({
    key: undefined,
    action: undefined,
    callback: undefined,
  });
  const [alreadyAdded, setAlreadyAdded] = React.useState();

  const { item } = props;
  function addItemToCart() {
    if (loaderState.open && loaderState.action === "add") {
      return;
    }
    setLoaderState({
      key: Date.now(),
      open: true,
      action: "add",
      callback: () => {
        props
          .addProductsToCart([item])
          .then((cartItemsClient) => {
            setAlreadyAdded(
              cartItemsClient &&
                cartItemsClient.find &&
                cartItemsClient.some((_item) => _item.sku === item.sku)
            );
          })
          .finally(() => {
            setLoaderState({
              open: false,
            });
          });
      },
    });
  }
  React.useEffect(() => {
    if (
      loaderState.action &&
      loaderState.key &&
      typeof loaderState.callback === "function"
    ) {
      loaderState.callback();
    }
  }, [loaderState.key]);

  const kitLabel = item.label.replace("®", "<sup>®</sup>");

  return (
    <div className="box-b-pk-item">
      <div className="content-box-b-pk">
        <div className="image-content-box-b-pk">
          <img src={item.image} alt="STARTER KIT" />
        </div>
        <div className="label-box-b-pk">
          <span dangerouslySetInnerHTML={{
            __html: kitLabel,
          }}></span>
        </div>
        <div className="button-box-b-pk">
          <button
            type="button"
            className={`add-button-box-b-pk${props.isAdded ? " product-added-box" : ""}`}
            onClick={!props.isAdded ? () => addItemToCart() : () => {}}
          >
            {loaderState.open && (
              <div
                className="flex-all width-100 page-spinner"
                style={{ flex: 1 }}
              >
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )}
            {!loaderState.open && <> {`${props.isAdded ? "ADDED TO CART" : "ADD TO CART"}`} </>}
          </button>
        </div>
      </div>
    </div>
  );
}

export default function ExtraKits(props) {
  /**
   * used for triggering an action after loader was rendered (to avoid setLoader off render trigger before setLoader on in cas request is faster than render )
   */
  const [loaderState, setLoaderState] = React.useState({
    key: undefined,
    action: undefined,
    callback: undefined,
  });
  const [multiLoader, setMultiloader] = React.useState({
    key: undefined,
    stack: {},
  });

  function checkout() {
    if (loaderState.open && loaderState.action === "addAll") {
      return;
    }
    setLoaderState({
      key: Date.now(),
      open: true,
      action: "addAll",
      callback: () => {
        props
          .addProductsToCart([props.growPot])
          .finally(() => {
            // setLoaderState({
            //   open: false,
            // });
            const cartAnchorNode = document.getElementById(
              "checkout-link-rm-kit-cart"
            );
            if (cartAnchorNode) {
              cartAnchorNode.click();
            }
          });
      },
    });
  }

  React.useEffect(() => {
    if (
      loaderState.action &&
      loaderState.key &&
      typeof loaderState.callback === "function"
    ) {
      loaderState.callback();
    }
  }, [loaderState.key]);

  return (
    <div className="purchase-kits-simple-page extra-kits">
      <div className="header-purchase-kits">
        <div className="header-button-group singular">
          <div className="header-button">
            <a
              className="header-base-url"
              href={`${window.BASE_URL}`}
              target="_blank"
            >
              FLORAFLEX.COM
            </a>
          </div>
        </div>
      </div>
      <div className="simple-page-content">
        <div className="top-purchase-kits">
          <div className="img-top-pk">
            <img src={headerBanner} alt="PurchaseKits" />
          </div>
          <div className="title-pk">
            <span>
              Would you like to add a nutrients Starter Kit <br></br> or a 1L
              FloraSprayer?
            </span>
          </div>
        </div>
        <div className="content-pk">
          <div className="container-pk-items">
            <div className="boxes-pk-items">
              {/* {(props.upsells.length === 0 || 1) && (
                  <div className="generic-text-list">No item.</div>
                )} */}
              {props.upsells.map((item, index) => (
                <ProductItem
                  item={item}
                  key={item.key}
                  addProductsToCart={props.addProductsToCart}
                  isAdded={props.cartItemsClient && props.cartItemsClient.some && props.cartItemsClient.some(_item => _item.product.sku == item.sku)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="pk-buttons">
          <Button
            text="Back"
            variant="secondary"
            className="pk-button back"
            onClick={() => props.history.replace("/purchase-kits/plant")}
          />
          <Button
            text="CHECKOUT"
            className={`pk-button checkout ${0 ? "disabled" : ""}`}
            variant="primary-new"
            onClick={checkout}
            loading={loaderState.open}
          />
        </div>
      </div>
      <a
        id="checkout-link-rm-kit-cart"
        href={`${window.BASE_URL}default/checkout/cart?rbRedirect=1`}

        style={{ display: "none" }}
      />
    </div>
  );
}
