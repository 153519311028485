import React, { useEffect } from "react";

// import components
import Button from "./../../../components/Button/Button";
import layout_logo_new from "./../../../../assets/image/RoomBuilderLogo_Small_Glow_NEW.png";
import Room2dCanvas from "../Canvas/Room2dCanvas.js";
import plusSvg from "./../../../../assets/svg/plus.svg";
import minusSvg from "./../../../../assets/svg/minus.svg";
import controls2d from "./../../../../assets/image/tooltips/tooltip2D-simple.png";

export default function KitRoomlayout(props) {
  const [state, setState] = React.useState({
    zoomStep: 0,
  });

  const [showControls, setShowControls] = React.useState();

  const [canvasConfig, _setCanvasConfig] = React.useState({
    width: window.innerWidth - 397, // 395px from left side bar
    height: window.innerHeight - 121, // 119px for header + navbar + 43px for the canvas header
    zoomDir: 1,
    zoomKey: "",
    showOverlay: window.showOverlay,
  });
  const stateRef = React.useRef(canvasConfig);

  function setCanvasConfig(_config) {
    stateRef.current = _config;
    _setCanvasConfig(_config);
  }

  const containerRef = React.useRef();
  function onResize(event) {
    if (containerRef.current) {
      const _newConfig = {
        ...stateRef.current,
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
      };
      setCanvasConfig(_newConfig);
    }
  }

  function roomToCanvas(room) {
    if (room == null) {
      return null;
    }
    let roomData = {};
    roomData.bodyWidth = room.roomWidth * 39.5;
    roomData.bodyHeight = room.roomLength * 43;
    roomData.x = 40;
    roomData.y = 50;

    return roomData;
  }

  function resetZoom() {
    setCanvasConfig({
      ...canvasConfig,
      zoomKey: Date.now(),
      zoomType: "CENTER",
    });
    setState({
      ...state,
      zoomStep: 0,
    });
  }

  function zoomIn() {
    let currentZoomStep = state.zoomStep;
    currentZoomStep++;
    if (currentZoomStep <= 14) {
      setCanvasConfig({
        ...canvasConfig,
        zoomKey: Date.now(),
        zoomType: "IN",
      });
      setState({
        ...state,
        zoomStep: currentZoomStep,
      });
    }
  }

  function zoomOut() {
    let currentZoomStep = state.zoomStep;
    currentZoomStep--;
    if (currentZoomStep >= -14) {
      setCanvasConfig({
        ...canvasConfig,
        zoomKey: Date.now(),
        zoomType: "OUT",
      });
      setState({
        ...state,
        zoomStep: currentZoomStep,
      });
    }
  }
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const [thisId, setThisId] = React.useState("");
  React.useEffect(() => {
    setThisId(
      `id-tool-tip-${Date.now() / Math.floor(Math.random() * 1000 + 1)}`
    );

  }, []);

  React.useEffect(() => {
    /**
     * when edit build is pressed is happening that the div container (that uses flex 1) is not rendered ready (or rather the css is behind) and it's giving 0 to width or height when the component is mounted
     * this code is intended to reuse the functionality that exists on resize event in order to set the correct size of the canvas
     */
    window.dispatchEvent(new Event("resize"));
  }, [props.hideCanvas]);

  return (
    <div
      className="step-content kit-room-layout"
      ref={containerRef}
      canvas-two-d-parent="true"
      style={props.hideCanvas ? { display: "none" } : {}}
    >
      <Room2dCanvas
        customClass={
          canvasConfig.showOverlay === true
            ? "position-static show-overlay"
            : "position-static"
        }
        canvasSize={{ width: canvasConfig.width, height: canvasConfig.height }}
        // canvasSize={{ width: 1525, height: 850 }}
        zoomDir={canvasConfig.zoomDir}
        zoomKey={canvasConfig.zoomKey}
        zoomType={canvasConfig.zoomType}
        canvasRoom={props.roomSize}
        trays={props.trays}
        setTrays={props.setTrays}
        measureSystem={props.measureSystem}
        roomSizeKey={props.roomSizeKey}
        openPopup={props.openPopup}
        readOnly={false}
        zones={props.zones}
        platformData={props.platformData}
        pathName={props.pathName}
        updateZones={props.updateZones}
        growAreaId={props.growAreaId}
      />
      <div className="kit-layout-control position-absolute --left-pos --bottom-pos">
        <Button
          text={<img src={plusSvg} />}
          variant="control control-sign"
          onClick={() => zoomIn()}
        />
        <Button
          text={<img src={minusSvg} />}
          variant="control control-sign"
          onClick={() => zoomOut()}
        />
        <Button
          text="CENTER"
          id="center-canvas-btn-id"
          variant="control control-text"
          onClick={() => resetZoom()}
        />
        <div id="tooltip-control-question-button">
          <Button
            text="?"
            variant="control control-question"
            onMouseOver={() => setShowControls(true)}
            onMouseOut={() => setShowControls(false)}
          />
          {showControls && (
            <div className="abs-tooltip-image">
              <img src={controls2d} />
            </div>
          )}
        </div>
      </div>
      <div className="kit-layout-logo position-fixed --right-pos --bottom-pos">
        <img src={layout_logo_new} alt="layout-logo"></img>
      </div>
    </div>
  );
}
