// @flow
import * as React from "react";
import { asideKeys, iconKeys } from "../cartConstants";

import { ReactComponent as RoomIcon } from "./../../../../assets/svg/room.svg";
import { ReactComponent as ComponentsIcon } from "./../../../../assets/svg/components.svg";
import { ReactComponent as NutrientsIcon } from "./../../../../assets/svg/nutrients.svg";
import { ReactComponent as HamburgerIcon } from "./../../../../assets/svg/hamburger.svg";
import { ReactComponent as CloseIcon } from "./../../../../assets/svg/remove.svg";

import AsideRoomStyle from "./AsideRoomStyle";
import AsideComponents from "./AsideComponents";
import AsideNutrients from "./AsideNutrients";

export default function AsideCart(props) {
  const selected = props.selected || {};
  return (
    <div className={`cart-aside${selected.key ? " -cart-aside-expanded" : ""}`}>
      <div className="aside-left-menu">
        <div className="aside-hamburger">
          <HamburgerIcon />
          <div className="aside-hamb-cover"></div>
        </div>
        {Object.entries(props.items).map(([key, item]) => (
          <div
            key={item.key}
            onClick={() => props.onSelectAsideMenu(item)}
            className={`aside-icon-item cursor-pointer${
              selected.key === item.key ? " aside-selected" : ""
            }`}
          >
            <div className="aside-icon">
              {(() => {
                switch (item.icon) {
                  case iconKeys.ROOM:
                    return <RoomIcon />;
                  case iconKeys.COMPONENTS:
                    return <ComponentsIcon />;
                  case iconKeys.NUTRIENTS:
                    return <NutrientsIcon />;
                  default:
                    break;
                }
              })()}
            </div>
            <div className="aside-icon-title">{item.title}</div>
          </div>
        ))}
      </div>
      <div className="aside-right-menu">
        <div className="aside-right-inner">
          <div className="aside-right-header">
            <span>YOUR BUILD DETAILS</span>
            <div
              className="close-icon cursor-pointer"
              onClick={() => props.onSelectAsideMenu(undefined)}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="aside-right-subtitle">{selected.title}</div>
          {selected.key && props.items[selected.key] && (
            <div className="aside-right-content">
              {(() => {
                switch (selected.key) {
                  case asideKeys.ROOM_AND_SYSTEM_STYLE:
                    return (
                      <AsideRoomStyle
                        {...props.items[selected.key].data}
                        onChangeAsideItem={(property, value) =>
                          props.onChangeAsideItem(property, value, selected.key)
                        }
                        onSetRoom2dKey={props.onSetRoom2dKey}
                      />
                    );
                  case asideKeys.COMPONENTS:
                    return (
                      <AsideComponents
                        {...props.items[selected.key].data}
                        onChangeAsideItem={(property, value) =>
                          props.onChangeAsideItem(property, value, selected.key)
                        }
                      />
                    );
                  case asideKeys.NUTRIENTS:
                    return (
                      <AsideNutrients
                        {...props.items[selected.key].data}
                        onChangeAsideItem={(property, value) =>
                          props.onChangeAsideItem(property, value, selected.key)
                        }
                      />
                    );
                  default:
                    break;
                }
              })()}
            </div>
          )}
          <div className="aside-content-footer">
            {props.footerItems.map((item) => (
              <div key={item.key} className="aside-total-item">
                <div className="total-label">{item.label}</div>
                <div className="total-amount">${item.amount.toFixed(2)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
