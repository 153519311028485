// @flow
import React, { useContext, useEffect } from "react";

import "./AuthPage.scss";
import {
  authenticaionActions,
  fetchUserAndLogIn,
  getCustomerDetails,
} from "../redux/actionFunctions/authenticationActions";
import { useDispatch } from "react-redux";
import { gql, ApolloConsumer } from "@apollo/client";
import { Popup } from "./../layout/components/Popup/Popup";
import { layoutActions } from "../redux/actionFunctions/layoutActions";
import Checkbox from "./../components/Checkbox/Checkbox";

import fullFloraLogo from "../../assets/image/fullDashboardLogo.png";
import eyePassword from "../../assets/image/eye-gray.png";
import { authErrors, phoneNumberIsValid } from "./authFunctions";
import AppContext from "../AppContext";
import ForgotPassword from "./ForgotPassword";
import imageA from "../../assets/image/auth/RB_5Gal.jpg";
import imageB from "../../assets/image/auth/RB_10Gal.jpg";
import imageC from "../../assets/image/auth/RB_Matrix.jpg";
import imageD from "../../assets/image/auth/RB_PotPro.jpg";
import imageE from "../../assets/image/auth/RB_QuickFill.jpg";
import imageF from "../../assets/image/auth/RB_Rockwool.jpg";
import {
  COUNTRY_OR_STATE_CODES as C_CODES,
  getCountries,
  WARNINGS_COUNTRY,
} from "./countryUtils";
import Dropdown from "./../components/Dropdown/Dropdown";
import { AUTH_POPUPS } from "./authUtils";
import { paramsRouter } from "../utils/utilFunctions";

const imagesArray = [imageA, imageB, imageC, imageD, imageE, imageF];

let timeout = 0;

export function AuthPage(props) {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const [state, setState] = React.useState({
    loginTab: true,
    registerTab: false,

    emailLogin: "",
    passwordLogin: "",
    loadingLogin: false,

    firstNameRegister: "",
    lastNameRegister: "",
    emailRegister: "",
    phoneRegister: "",
    passwordRegister: "",
    passwordConfirmRegister: "",
    /** country and state (state is selectable only for US country*/
    registerCountryCode: "",
    registerStateCode: "",

    termsRegister: false,
    disabledRegister: false,
    loadingRegister: false,
    inputLogin: true,
    inputRegister: true,
    inputRegisterConfirm: true,
    registerBtnActive: false,
    bgImage: imagesArray[Math.floor(Math.random() * imagesArray.length)],
  });

  const [countries, setCountries] = React.useState({
    set: [],
    isFetching: true,
  });

  /**
   * used for triggering an action after loader was rendered (to avoid setLoader off render trigger before setLoader on in cas request is faster than render )
   */
  const [loaderState, setLoaderState] = React.useState({
    key: undefined,
    action: undefined,
    callback: undefined,
  });

  function setActiveLogin() {
    setState({
      ...state,
      loginTab: true,
      registerTab: false,
    });
  }

  function setActiveRegister() {
    setState({
      ...state,
      loginTab: false,
      registerTab: true,
    });
  }

  function login(emailLogin = null, passwordLogin = null) {
    let email = document.querySelectorAll('input[name="email"]');
    let password = document.querySelectorAll('input[name="password"]');

    let email_login = emailLogin || state.emailLogin;
    let password_login = passwordLogin || state.passwordLogin;

    if(typeof(email_login) === 'string') {
      email_login = email_login.toLowerCase();
    }

    let errorToShow;
    errorToShow =
      !email_login ||
      !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email_login)
        ? authErrors.LOGIN_FAIL
        : undefined;

    if (!errorToShow && password_login == "") {
      errorToShow = authErrors.LOGIN_FAIL;
    }

    if (!errorToShow) {
      password[0].style.border = "none";
      email[0].style.border = "none";

      props.client
        .mutate({
          mutation: gql`
          mutation GenerateCustomerToken($email: String!, $password: String!) {
            generateCustomerToken(email: $email, password: $password) {
              token
            }
          }
        `,
          variables: {
            email: email_login,
            password: password_login,
          }
        })
        .then((result) => {
          const authToken = result?.data?.generateCustomerToken?.token;
          if (authToken !== null) {
            localStorage.setItem("clientToken", authToken);
            appContext.updateClientHeaders(authToken);

            return getCustomerDetails(props.client);
          }

          throw {};
        })
        .then((customer) => {
          authenticaionActions(dispatch).login({
            ...customer,
            clientId: customer.id,
            token: localStorage.getItem("clientToken"),
          });
        })
        .catch((error) => {
          console.log("ERROR WHILE TRYING TO AUTHENTICATE USER", { error });

          if (error?.message === "Failed to fetch") {
            openPopup({ ...AUTH_POPUPS.NETWORK_ERROR });
          } else {
            openPopup({ ...AUTH_POPUPS.INCORRECT_EMAIL_OR_PASSWORD });
          }

          setLoaderState({
            open: false,
          });
          setState({
            ...state,
            loadingLogin: false,
          });
        });
    } else {
      setLoaderState({
        open: false,
      });
      openPopup({ ...AUTH_POPUPS.INCORRECT_EMAIL_OR_PASSWORD });
      setState({
        ...state,
        loadingLogin: false,
      });

      if (
        email_login !== "" &&
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email_login)
      ) {
        email[0].style.border = "none";
      } else {
        email[0].style.border = "1px solid red";
      }
      if (password_login !== "") {
        password[0].style.border = "none";
      } else {
        password[0].style.border = "1px solid red";
      }
    }
  }

  function loginPrepare(emailLogin = null, passwordLogin = null) {
    if (loaderState.open && loaderState.action === "loginAction") {
      return;
    }
    setLoaderState({
      key: Date.now(),
      open: true,
      action: "loginAction",
      callback: () => {
        login(emailLogin, passwordLogin);
      },
    });
  }

  function register() {
    let firstName = document.querySelectorAll('input[name="firstName"]');
    let lastName = document.querySelectorAll('input[name="lastName"]');
    let emailRegister = document.querySelectorAll(
      'input[name="emailRegister"]'
    );
    let phoneRegister = document.querySelectorAll(
      'input[name="phoneRegister"]'
    );
    let passwordRegister = document.querySelectorAll(
      'input[name="passwordRegister"]'
    );
    let passwordConfirmRegister = document.querySelectorAll(
      'input[name="passwordConfirmRegister"]'
    );
    let termsAgree = document.querySelectorAll(
      ".register-from .checkbox-input span"
    );

    let errorToShow;

    /**
     * display an error based on the order they are on screen
     */
    /**
     * first name, last name
     */
    const stateNotSelected =
      !state.registerStateCode &&
      state.registerCountryCode === C_CODES.UNITED_STATES;
    if (
      !state.firstNameRegister ||
      !state.lastNameRegister ||
      !state.registerCountryCode ||
      stateNotSelected
    ) {
      errorToShow = authErrors.REQUIRED_FIELDS;

      if (!state.registerCountryCode || stateNotSelected) {
        setCountries({
          ...countries,
          error: !state.registerCountryCode,
          errorSubState: stateNotSelected,
        });
      }
    }
    /**
     * email
     */
    if (!errorToShow) {
      errorToShow =
        !state.emailRegister ||
        !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          state.emailRegister
        )
          ? authErrors.INVALID_EMAIL
          : undefined;
    }

    /**
     * phone number - validate optional phone number if there's one
     */
    if (
      !errorToShow &&
      state.phoneRegister &&
      state.phoneRegister.length > 0 &&
      !phoneNumberIsValid(state.phoneRegister)
    ) {
      errorToShow = authErrors.INVALID_PHONE;
    }
    /**
     * password then confirm password
     */
    if (
      !errorToShow &&
      state.passwordRegister !== state.passwordConfirmRegister
    ) {
      errorToShow = authErrors.PASSWORD_MATCH;
    }

    if (
      !errorToShow &&
      state.firstNameRegister !== "" &&
      state.lastNameRegister !== "" &&
      state.passwordRegister !== "" &&
      state.passwordConfirmRegister !== "" &&
      state.termsRegister === true
    ) {
      setState({
        ...state,
        registerBtnActive: true,
        loadingRegister: true,
        disabledRegister: true,
      });

      firstName[0].style.border = "none";
      lastName[0].style.border = "none";
      emailRegister[0].style.border = "none";
      passwordRegister[0].style.border = "none";
      passwordConfirmRegister[0].style.border = "none";
      termsAgree[0].style.border = "none";
      props.client
        .mutate({
          mutation: gql`
            mutation CreateCustomerV2($firstName: String!, $lastName: String!, $email: String!, $password: String!) {
              createCustomerV2(
                input: {
                  firstname: $firstName
                  lastname: $lastName
                  email: $email
                  password: $password
                  is_subscribed: true
                }
              ) {
                customer {
                  firstname
                  lastname
                  email
                  is_subscribed
                }
              }
            }
            `,
          variables: {
            firstName: state.firstNameRegister,
            lastName: state.lastNameRegister,
            email: state.emailRegister,
            password: state.passwordRegister,
          },
        })
        .then((result) => {
          /*  openPopup({
              content: {
                title: "",
                text: "The account was successfully created!",
              },
            }); */
          // Login the user upon registration success

          // loginPrepare(state.emailRegister, state.passwordRegister);

          openPopup(AUTH_POPUPS.VERIFY_EMAIL);
        })
        .catch((error) => {
          console.log("ERROR WHILE CREATING NEW USER", { error });
          let error_message = error?.message;

          if (error_message.toUpperCase().indexOf("PASSWORD") !== -1) {
            openPopup({ ...AUTH_POPUPS.PASSWORD_ERROR });
          } else {
            openPopup({
              content: {
                title: "",
                text: error_message
                  ? error_message
                  : "There was an error while trying to register this account, please try again later.",
              },
            });
          }
        })
        .finally(() => {
          setState({
            ...state,
            loadingRegister: false,
            disabledRegister: false,
            registerBtnActive: true,
          });
        });
    } else {
      setState({
        ...state,
        loadingRegister: false,
        disabledRegister: false,
        registerBtnActive: false,
      });
      openPopup({
        content: {
          title: errorToShow
            ? errorToShow.message.title
            : "MISSING REQUIRED FIELDS",
          text: errorToShow
            ? errorToShow.message.text
            : "At least one required field is empty. Please fill in all required fields to complete registration.",
        },
      });
      if (state.firstNameRegister !== "") {
        firstName[0].style.border = "none";
      } else {
        firstName[0].style.border = "1px solid red";
      }
      if (state.lastNameRegister !== "") {
        lastName[0].style.border = "none";
      } else {
        lastName[0].style.border = "1px solid red";
      }

      if (
        state.emailRegister !== "" &&
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          state.emailRegister
        )
      ) {
        emailRegister[0].style.border = "none";
      } else {
        emailRegister[0].style.border = "1px solid red";
      }
      if (state.passwordRegister !== "") {
        passwordRegister[0].style.border = "none";
      } else {
        passwordRegister[0].style.border = "1px solid red";
      }
      if (state.passwordConfirmRegister !== "") {
        passwordConfirmRegister[0].style.border = "none";
      } else {
        passwordConfirmRegister[0].style.border = "1px solid red";
      }
      if (
        state.passwordRegister === state.passwordConfirmRegister &&
        state.passwordConfirmRegister !== "" &&
        state.passwordConfirmRegister != ""
      ) {
        passwordRegister[0].style.border = "none";
        passwordConfirmRegister[0].style.border = "none";
      } else {
        passwordRegister[0].style.border = "1px solid red";
        passwordConfirmRegister[0].style.border = "1px solid red";
      }
      if (state.termsRegister) {
        termsAgree[0].style.border = "none";
      } else {
        termsAgree[0].style.border = "1px solid red";
      }
    }
  }

  function openPopup(payload) {
    let key = Date.now();
    layoutActions(dispatch).pushPopupStack({
      key,
      title: "",
      buttonOkText: "Okay",
      callbackNo: (closePopup) => {
        closePopup();
      },
      ...payload,
    });
  }

  function submitOnEnterForm(event, source) {
    if (event.charCode == 13) {
      if (source == "login") {
        loginPrepare();
      } else if (source == "register") {
        register();
      }
    }
  }

  function cancelRegistration() {
    let firstName = document.querySelectorAll('input[name="firstName"]');
    let lastName = document.querySelectorAll('input[name="lastName"]');
    let emailRegister = document.querySelectorAll(
      'input[name="emailRegister"]'
    );
    let passwordRegister = document.querySelectorAll(
      'input[name="passwordRegister"]'
    );
    let passwordConfirmRegister = document.querySelectorAll(
      'input[name="passwordConfirmRegister"]'
    );
    let termsAgree = document.querySelectorAll(
      ".register-from .checkbox-input span"
    );

    firstName[0].style.border = "none";
    lastName[0].style.border = "none";
    emailRegister[0].style.border = "none";
    passwordRegister[0].style.border = "none";
    passwordConfirmRegister[0].style.border = "none";
    termsAgree[0].style.border = "none";

    setState({
      ...state,
      loginTab: true,
      registerTab: false,
      termsRegister: false,
      loadingRegister: false,
      disabledRegister: false,
      firstNameRegister: "",
      lastNameRegister: "",
      emailRegister: "",
      phoneRegister: "",
      passwordRegister: "",
      passwordConfirmRegister: "",
    });
  }

  function showHidePasswordLogin() {
    setState({
      ...state,
      inputLogin: !state.inputLogin,
    });
  }

  function showHidePasswordRegister() {
    setState({
      ...state,
      inputRegister: !state.inputRegister,
    });
  }

  function showHidePasswordRegisterConfirm() {
    setState({
      ...state,
      inputRegisterConfirm: !state.inputRegisterConfirm,
    });
  }

  function getErrorToShowRegister() {
    let errorToShow;
    /**
     * display an error based on the order they are on screen
     */
    /**
     * first name, last name
     */
    if (!state.firstNameRegister || !state.lastNameRegister) {
      errorToShow = authErrors.REQUIRED_FIELDS;
    }
    /**
     * email
     */
    if (!errorToShow) {
      errorToShow =
        !state.emailRegister ||
        !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          state.emailRegister
        )
          ? authErrors.INVALID_EMAIL
          : undefined;
    }
    /**
     * password then confirm password
     */
    if (
      !errorToShow &&
      state.passwordRegister !== state.passwordConfirmRegister
    ) {
      errorToShow = authErrors.PASSWORD_MATCH;
    }
    /* all fields check */
    if (
      state.firstNameRegister == "" ||
      state.lastNameRegister == "" ||
      state.passwordRegister == "" ||
      state.passwordConfirmRegister == "" ||
      state.termsRegister === false
    ) {
      errorToShow = authErrors.REQUIRED_FIELDS;
    }
    return errorToShow;
  }

  function openForgotPasswordForm() {
    layoutActions(dispatch).pushPopupStack({
      key: "FORGOT_PASSWORD_POPUP",
      title: "",
      callbackNo: (closePopup) => {
        closePopup();
      },
      content: {
        title: "",
        text: "",
        node: (
          <ForgotPassword
            closePopup={() => {
              layoutActions(dispatch).popPopupStack({
                key: "FORGOT_PASSWORD_POPUP",
              });
            }}
            editPopup={(payload) => {
              layoutActions(dispatch).editPopupStack({
                key: "FORGOT_PASSWORD_POPUP",
                ...payload,
              });
            }}
            client={props.client}
          />
        ),
      },
      className: "wrapper-save-room-popup-rm-your-build",
    });
  }

  function initCountries() {
    getCountries()
      .then((countries) => {
        setCountries({
          set: countries,
          isFetching: false,
        });
      })
      .catch((err) => {
        console.error(`ERROR - while fetching countries`, err);
        setCountries({
          set: countries.set,
          isFetching: false,
        });
      });
  }

  function onChangeCountry(item) {
    setState({
      ...state,
      registerCountryCode: item.value,
      registerStateCode: undefined, //reset state
    });

    setCountries({
      ...countries,
      subStates:
        item.value === C_CODES.UNITED_STATES ? item.regions : undefined,
      error: false,
      errorSubState: false,
    });

    /**
     * User selects non-US country (not Other) from dropdown menu at registration, modal appears
     */
    if (item.value === "OTHER") {
      openPopup({
        ...WARNINGS_COUNTRY.OTHER_COUNTRY,
      });
    } else if (item.value !== C_CODES.UNITED_STATES) {
      openPopup({
        ...WARNINGS_COUNTRY.NON_US_COUNTRY,
      });
    }
  }

  function onChangeState(item) {
    setState({
      ...state,
      registerStateCode: item.value,
    });

    setCountries({
      ...countries,
      error: false,
    });

    /**
     * display popups based on US states
     */
    if (state.registerCountryCode === C_CODES.UNITED_STATES) {
      if (item.value === C_CODES.PUERTO_RICO) {
        openPopup({
          ...WARNINGS_COUNTRY.PUERTO_RICO,
        });
      } else if ([C_CODES.ALASKA, C_CODES.HAWAII].indexOf(item.value) > -1) {
        openPopup({
          ...WARNINGS_COUNTRY.ALASKA_AND_HAWAII,
        });
      }
    }
  }

  useEffect(() => {
    if (
      loaderState.action &&
      loaderState.key &&
      typeof loaderState.callback === "function"
    ) {
      loaderState.callback();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [loaderState.key]);

  useEffect(() => {
    initCountries();
  }, []);

  let errorToShowRegister;
  if (state.registerTab) {
    errorToShowRegister = getErrorToShowRegister();
  }

  return (
    <>
      <div className="full-page-content" id="auth">
        <div
          className="left-side-auth"
          style={{ backgroundImage: `url(${state.bgImage})` }}
        >
          <div className="logo-new">
            <img src={fullFloraLogo} alt="FloraFlex" />
          </div>
        </div>
        <div className="right">
          <div className="right-content">
            <div className="text">
              The FloraFlex<sup>®</sup> Room Builder is FloraFlex's<sup>®</sup>{" "}
              innovation to help the intermediate or professional grower build
              out their perfect grow system.
            </div>
            <div className="text">
              FloraFlex<sup>®</sup> Room Builder is currently optimized only for US
              customers. International Room Builder purchases will be available very soon!
            </div>
            <div className="line" />
            <div className="text">
              For the home grower, just starting their grow experience and
              looking to grow 4, 6, or 8 plants FloraFlex<sup>®</sup> has
              prepackaged FloraFlex<sup>®</sup> Kits. Our Kits include
              everything you need to get started.
            </div>
            <div className="button-section">
              <button
                type="button"
                className="simple-btn btn-url"
                onClick={() => props.history.replace("/purchase-kits")}
              >
                PURCHASE KITS
              </button>
            </div>
            <div className="separator">OR</div>
            <div className="forms-container">
              <div className="form-tabs">
                <div
                  className="form-item-tab"
                  active={state.loginTab == true ? "1" : "0"}
                  onClick={() => setActiveLogin()}
                >
                  LOGIN
                </div>
                <div
                  className="form-item-tab"
                  active={state.registerTab == true ? "1" : "0"}
                  onClick={() => setActiveRegister()}
                >
                  REGISTER
                </div>
              </div>
              <div className="form-content">
                <div
                  className={
                    state.loginTab == true ? "login-from active" : "login-form"
                  }
                >
                  <form>
                    <div className="form-container">
                      <input
                        name="email"
                        type="text"
                        placeholder="Email address"
                        value={state.emailLogin}
                        onChange={(e) =>
                          setState({ ...state, emailLogin: e.target.value })
                        }
                        onKeyPress={(e) => submitOnEnterForm(e, "login")}
                      />
                      <div className="input-password-group">
                        <input
                          name="password"
                          type={state.inputLogin ? "password" : "text"}
                          placeholder="Password"
                          value={state.passwordLogin}
                          onChange={(e) =>
                            setState({
                              ...state,
                              passwordLogin: e.target.value,
                            })
                          }
                          onKeyPress={(e) => submitOnEnterForm(e, "login")}
                        />
                        <span onClick={() => showHidePasswordLogin()}>
                          <img
                            src={eyePassword}
                            title="Show/Hide password"
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                    <div className="form-buttons">
                      {(() => {
                        const isLoading =
                          loaderState.open &&
                          loaderState.action === "loginAction";

                        return (
                          <button
                            type="button"
                            className="simple-btn"
                            id="login-btn"
                            style={isLoading ? { cursor: "default" } : {}}
                            onClick={() => loginPrepare()}
                            loading={isLoading ? "1" : "0"}
                          >
                            {isLoading && <span></span>}
                            {!isLoading && "LOGIN"}
                          </button>
                        );
                      })()}
                      <div
                        className="simple-url"
                        onClick={() => openForgotPasswordForm()}
                      >
                        Forgot Password?
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className={
                    state.registerTab == true
                      ? "register-from active"
                      : "register-form"
                  }
                >
                  <form>
                    <div className="form-container">
                      <div className="input-double">
                        <input
                          name="firstName"
                          type="text"
                          placeholder="First name"
                          onChange={(e) => {
                            setState({
                              ...state,
                              firstNameRegister: e.target.value,
                            });
                            e.target.style.border = "none";
                          }}
                          onKeyPress={(e) => submitOnEnterForm(e, "register")}
                          value={state.firstNameRegister}
                        />
                        <input
                          name="lastName"
                          type="text"
                          placeholder="Last name"
                          onChange={(e) => {
                            setState({
                              ...state,
                              lastNameRegister: e.target.value,
                            });
                            e.target.style.border = "none";
                          }}
                          onKeyPress={(e) => submitOnEnterForm(e, "register")}
                          value={state.lastNameRegister}
                        />
                      </div>
                      <input
                        name="emailRegister"
                        type="email"
                        placeholder="Email address"
                        onKeyPress={(e) => submitOnEnterForm(e, "register")}
                        onChange={(e) => {
                          setState({ ...state, emailRegister: e.target.value });
                          e.target.style.border = "none";
                        }}
                        value={state.emailRegister}
                      />

                      {/* COUNTRY AND STATE DROPDOWN-S */}
                      <Dropdown
                        items={countries.set}
                        variant={`form${countries.error ? " form-error" : ""}`}
                        selected={countries.set.find(
                          (item) => item.value === state.registerCountryCode
                        )}
                        noInput={true}
                        placeholder="Shipping country"
                        loading={countries.isFetching}
                        onChange={onChangeCountry}
                      />
                      {countries.subStates && (
                        <Dropdown
                          items={countries.subStates}
                          variant={`form${
                            countries.errorSubState ? " form-error" : ""
                          }`}
                          selected={countries.subStates.find(
                            (item) => item.value === state.registerStateCode
                          )}
                          noInput={true}
                          placeholder="Shipping state"
                          loading={countries.isFetching}
                          onChange={onChangeState}
                        />
                      )}
                      {/* <input
                        name="phoneRegister"
                        type="text"
                        placeholder="Phone (Optional)"
                        onKeyPress={(e) => submitOnEnterForm(e, "register")}
                        onChange={(e) =>
                          setState({
                            ...state,
                            phoneRegister: e.target.value,
                          })
                        }
                        value={state.phoneRegister}
                      /> */}
                      <div className="input-password-group">
                        <input
                          name="passwordRegister"
                          type={state.inputRegister ? "password" : "text"}
                          placeholder="Password"
                          onKeyPress={(e) => submitOnEnterForm(e, "register")}
                          onChange={(e) => {
                            setState({
                              ...state,
                              passwordRegister: e.target.value,
                            });
                            e.target.style.border = "none";
                          }}
                          value={state.passwordRegister}
                        />
                        <span onClick={() => showHidePasswordRegister()}>
                          <img
                            src={eyePassword}
                            title="Show/Hide password"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="input-password-group">
                        <input
                          name="passwordConfirmRegister"
                          type={
                            state.inputRegisterConfirm ? "password" : "text"
                          }
                          placeholder="Confirm Password"
                          onKeyPress={(e) => submitOnEnterForm(e, "register")}
                          onChange={(e) => {
                            setState({
                              ...state,
                              passwordConfirmRegister: e.target.value,
                            });
                            e.target.style.border = "none";
                          }}
                          value={state.passwordConfirmRegister}
                        />
                        <span onClick={() => showHidePasswordRegisterConfirm()}>
                          <img
                            src={eyePassword}
                            title="Show/Hide password"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="checkbox-input">
                        <Checkbox
                          customClass="input-checkbox"
                          checked={state.termsRegister}
                          onCheck={() => {
                            setState({
                              ...state,
                              termsRegister: !state.termsRegister,
                            });
                            let termsAgree = document.querySelectorAll(
                              ".register-from .checkbox-input span"
                            );
                            termsAgree[0].style.border = "none";
                          }}
                        />
                        <div className="label-checkbox">
                          By accepting you agree to our{" "}
                          <a
                            href={`${window.BASE_URL}terms-and-conditions`}
                            target="_blank"
                          >
                            Terms and Conditions
                          </a>{" "}
                          and our{" "}
                          <a
                            href={`${window.BASE_URL}privacy-policy`}
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="form-buttons">
                      <button
                        type="button"
                        className="simple-btn"
                        id="cancel-btn"
                        onClick={cancelRegistration}
                      >
                        CANCEL
                      </button>
                      <button
                        type="button"
                        className="simple-btn success-btn"
                        id="submit-account-btn"
                        onClick={() => register()}
                        disabled={state.disabledRegister}
                        status={!errorToShowRegister ? "active" : "inactive"}
                      >
                        {state.loadingRegister ? <span></span> : "SUBMIT"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="version-text">
              {`v${process.env.REACT_APP_VERSION}`}
            </div>                          
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}
const WithApolloClient = (props) => (
  <ApolloConsumer>
    {(client) => <AuthPage {...props} client={client} />}
  </ApolloConsumer>
);
export default WithApolloClient;
