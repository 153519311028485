import html2canvas from "html2canvas";

export function onChangeWithLimits(event, callback) {
  const value = Number(event.target.value);
  let min = event.target.getAttribute("min");
  const max = event.target.getAttribute("max");
  if ((!min || !value || value >= min) && (!max || value <= max)) {
    callback(value);
  }
}

export function isEnter(event) {
  return event.code === "Enter" || event.which === 17 || event.key === "Enter";
}

export const formatNumber = (input) => {
  return (Number(input) || 0).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
};

export const number2Digits = (number) => {
  number = Number(number) || 0;
  return Math.floor(number * 10) / 10;
};

export function parseHtmlAndOther(text) {
  const parse = require("html-react-parser");
  if (typeof text === "string") {
    text = text.replace(/([\n\t\r])|(\\n)/g, "<br>"); // this includes the \n
    text = parse(text);
    return text;
  }
  return "";
}

export function triggerCustomEvent(name, detail, object) {
  const event = new CustomEvent(name, {
    detail,
  });
  if (object) {
    object.dispatchEvent(event);
  } else {
    window.dispatchEvent(event);
  }
}

export function copyToClipBoard(text) {
  /* Get the text field */
  var inputClipboard = document.getElementById("clipboard-input-node");
  inputClipboard.value = text;

  /* Select the text field */
  inputClipboard.select();
  inputClipboard.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  document.execCommand("copy");
}

export async function onScreenShoot(event) {
  if (
    event.code === "PrintScreen" ||
    event.which === 44 ||
    event.keyCode === 44 ||
    event.key === "PrintScreen"
  ) {
    copyToClipBoard("room-builder");
    if (!window.userCanWrite) {
      return;
    }
    html2canvas(document.body).then(async (canvas) => {
      const nodeHidden = document.getElementById("left-content-hidden");
      if (!nodeHidden) {
        return;
      }
      nodeHidden.appendChild(canvas);
      const ctx = canvas.getContext("2d");
      const watermarkImg = document.getElementById("watermark-for-sc");
      if (watermarkImg) {
        const canvasWidth = canvas.getAttribute("width");
        const canvasHeight = canvas.getAttribute("height");

        // if(canvasWidth && canvasHeight) {
        watermarkImg.setAttribute("width", window.innerWidth + "px");
        watermarkImg.setAttribute("height", window.innerHeight + "px");
        // }
        let x = 0;
        let y = 0;

        ctx.drawImage(
          watermarkImg,
          x,
          y,
          window.innerWidth,
          window.innerHeight
        );

        const screenCapture = canvas.toDataURL();
        const inputClipboard = document.getElementById("clipboard-input-node");
        if (inputClipboard) {
          inputClipboard.focus();
        }
        try {
          const response = await fetch(screenCapture);
          const blob = await response.blob();
          await setToClipboard(blob);
        } catch (error) {
          console.error("Something wrong happened");
          console.error(error);
        }
      }
      try {
        nodeHidden.removeChild(canvas);
      } catch (err) {}
      // document.body.appendChild(canvas);
    });
  }
}
export const setToClipboard = (blob) => {
  if (window.ClipboardItem) {
    const data = [new window.ClipboardItem({ [blob.type]: blob })];
    return navigator.clipboard.write(data);
  }
  return null;
};
export async function onDOMContentLoaded(event) {
  async function askWritePermission() {
    try {
      const { state } = await navigator.permissions.query({
        name: "clipboard-write",
        allowWithoutGesture: false,
      });
      return state === "granted";
    } catch (error) {
      console.log(`Compatibility error (ONLY CHROME > V66): ${error.message}`);
      console.log(error);
      return false;
    }
  }

  const canWrite = await askWritePermission();
  window.userCanWrite = canWrite;
}
/**
 *
 * @returns {object}:
 * - the event name for tab switching based browser
 * -
 */
export function tabSwitchingEventPolyfills() {
  // Set the name of the "hidden" property and the change event for visibility
  var hidden, visibilityChange;
  if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.mozHidden !== "undefined") {
    // Firefox up to v17
    hidden = "mozHidden";
    visibilityChange = "mozvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    // Chrome up to v32, Android up to v4.4, Blackberry up to v10
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }

  return { visibilityChange, hidden };
}

export const paramsRouter = (props) => {
  let urlParams = new URL(
    `https://dummy-url.ro/${props.history.location.search}`
  ).searchParams;

  return urlParams;
};

export const isLocalhost = () => {
  return /^(http:\/\/localhost)/.test(window.location.href);
}

export function getOrigin() {
  let pathname = window.location.pathname;
  if (pathname === "/") {
    pathname = "";
  }
  
  if(pathname[pathname.length - 1] === "/") {
    pathname = pathname.slice(0, pathname.length - 1);
  }

  return `${window.location.origin}${pathname}`;
}

export function redirectToLogoutController(token) {
  let shareCodeGET = "";
  if (typeof window.getshared === "function" && window.getshared()) {
    shareCodeGET = `?share_code=${window.getshared()}`;
  }
  
  const redirect = encodeURIComponent(
    `${getOrigin()}/#/auth${shareCodeGET}`
  );

  window.location.href = `${window.BASE_URL}roombuilderconfig/logout/index?redirect=${redirect}&token=${token}`;
};