import React from "react";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./app/Routes";
import LayoutSplashScreen from "./app/components/LayoutSplashScreen/LayoutSplashScreen";
import { store, persistor } from "./app/redux/store.js";
import { FpsView } from "react-fps";
import ReactGA from 'react-ga';

export default function App() {
  ReactGA.initialize('UA-84275296-1');

  function renderFPSView() {
    if (process.env.REACT_APP_MONIT_FPS) {
      return <FpsView />;
    }
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <HashRouter>
          { renderFPSView() }
          <Routes />
        </HashRouter>
      </PersistGate>
    </Provider>
  );
}
