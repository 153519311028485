import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import axios from "axios";
import { BASE_URL } from "./app/utils/constants";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://e2d79be5ca124f34a05c9ec9c34b9a15@o363320.ingest.sentry.io/5899567",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: Number.parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE),
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_VERSION,
});

/** this instantiates window.__insp */
const inspectlet = require("inspectlet");
inspectlet(964952590); //wid -> use it from your account to record sessions

axios.defaults.baseURL = BASE_URL;
window.BASE_URL = BASE_URL;



window.__dev__ = /^(http:\/\/localhost)/.test(window.location.href) ? 1 : 0;

ReactDOM.render(
  
    <React.Fragment>
      <App />
    </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
